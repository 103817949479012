import React from 'react';

import { Tooltip } from 'bootstrap';
import $ from 'jquery';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

const AdminContentsSurveys = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		title: '', // surveyQuestionDTOList.title
		text: '',
		surveyType: '',
		surveyStatus: '',
		choiceList: '', // surveyQuestionDTOList.choiceList
		surveyQuestionDTOList: [],
		dateTimeBegin: null,
		dateTimeEnd: null,
		surveyResult: null,
	});

	const [editChoiceList, setEditChoiceList] = React.useState([]);
	const [currentChoice, setCurrentChoice] = React.useState('');

	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_SURVEY_URL}/admin/getAllSurveys/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data?.content) {
					setState({
						...state,
						showLoading: false,
						surveys: response.data.content,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	React.useEffect(() => {
		/* eslint-disable */
		const tooltipTriggerList = document.querySelectorAll(
			'[data-bs-toggle="tooltip"]'
		);
		const tooltipList = [...tooltipTriggerList].map(
			(tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
		);
		/* eslint-enable */
	}, [state]);

	const handleSearch = (event) => {
		setState({
			...state,
			search: event.target.value,
		});
	};

	const handleEditClick = (item) => {
		if (item) {
			// Edit
			const choiceList = [];
			let title = '';
			let questionId = '';

			item.surveyQuestionDTOList?.forEach((element) => {
				questionId = element.id;
				title = element.title;
				element?.surveyChoiceDTOList?.forEach((choice) => {
					choiceList.push(choice);
				});
			});

			const surveyQuestionDTOList = [];
			surveyQuestionDTOList.push({
				id: questionId,
				title,
				surveyChoiceDTOList: choiceList,
			});

			// Result
			fetch(
				`${constants.API_SURVEY_URL}/admin/getSurveyResults/${item.id}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${context.dataLogin.token}`,
					},
				}
			)
				.then((response) => response.json())
				.then((response) => {
					if (response?.data) {
						setState({
							...state,
							showLoading: false,
							showModal: true,
							header: `#${item.id} Düzenle`,
							id: item.id,
							text: item.text,
							surveyType: item.surveyType,
							surveyStatus: item.surveyStatus,
							dateTimeBegin: item.dateTimeBegin.substring(0, 16),
							dateTimeEnd: item.dateTimeEnd.substring(0, 16),
							title,
							choiceList,
							surveyQuestionDTOList,
							surveyResult: response.data,
						});
					} else {
						setState({
							...state,
							showLoading: false,
						});
					}
				})
				.catch((error) => {
					setState({
						...state,
						toast: {
							type: 'error',
							message:
								error.message === 'Failed to fetch'
									? 'Sunucu ile bağlantı kurulamadı.'
									: error.message,
						},
						showLoading: false,
					});
				});
		} else {
			setState({
				...state,
				showModal: true,
				title: '', // surveyQuestionDTOList.title
				text: '',
				surveyType: '',
				surveyStatus: '',
				choiceList: '', // surveyQuestionDTOList.choiceList
				surveyQuestionDTOList: [],
				dateTimeBegin: null,
				dateTimeEnd: null,
				surveyResult: null,
			});
		}
	};

	const handleSaveClick = async () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_SURVEY_URL}/admin/createSurvey`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...(state.id && { id: state.id }),
				text: state.text,
				surveyType: state.surveyType,
				surveyStatus: 'HOME',
				dateTimeBegin: `${state.dateTimeBegin}:00+03:00`,
				dateTimeEnd: `${state.dateTimeEnd}:00+03:00`,
				surveyQuestionDTOList: state.surveyQuestionDTOList,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'success',
							message: 'Anket kaydedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					showModal: false,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleRemoveClick = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_SURVEY_URL}/admin/deleteSurvey/${id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Anket silindi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleRemoveChoice = (e, id) => {
		e.preventDefault();
		const choiceList = [];
		state.choiceList.forEach((element) => {
			if (element.id !== id) {
				choiceList.push(element);
			}
		});

		const surveyChoiceDTOList = [];
		surveyChoiceDTOList.push(...choiceList);

		setState({
			...state,
			choiceList,
			surveyQuestionDTOList: [
				{
					surveyChoiceDTOList: choiceList,
					title: state.title,
				},
			],
			title: state.title,
		});
	};

	const handleChoice = (e) => {
		e.preventDefault();
		const val = e.target.value;
		setCurrentChoice(val);
	};

	const handleTitle = (e) => {
		e.preventDefault();

		const surveyQuestionDTOList = [];
		surveyQuestionDTOList.push({
			title: state.title,
		});

		setState({
			...state,
			title: e.target.value, // surveyQuestionDTOList.title
			surveyQuestionDTOList: [
				{
					...state.surveyQuestionDTOList[0],
					title: e.target.value,
				},
			],
		});
	};

	const handleAddChoice = () => {
		if (currentChoice !== '') {
			const surveyChoiceDTOList = [];
			const surveyQuestionDTOList = [];
			surveyChoiceDTOList.push(...state.choiceList);
			surveyChoiceDTOList.push({
				description: currentChoice,
			});

			surveyQuestionDTOList.push({
				title: state.title,
				surveyChoiceDTOList,
			});

			setState({
				...state,
				choiceList: surveyChoiceDTOList,
				surveyQuestionDTOList,
			});

			setCurrentChoice('');
		}
	};

	const handleEditChoice = (e, id) => {
		e.preventDefault();
		const arr = state.choiceList;
		arr.forEach((element, index) => {
			if (element.id === id) {
				arr[index].description = e.target.value;

				// Current Input
				setTimeout(() => {
					$('.choice-item input').eq(index).focus();
				}, 100);
			}
		});

		setEditChoiceList(arr);
	};

	React.useEffect(() => {
		if (editChoiceList && editChoiceList.length > 0) {
			setState({
				...state,
				choiceList: editChoiceList,
			});
		}
	}, [editChoiceList]);

	return (
		<>
			<div className="row g-md-4 g-2 align-items-center  mb-5">
				<div className="col-md-3">
					<div className="d-flex align-items-center">
						<label className="form-label fw-medium mb-0 me-3 fs-7">
							Arama
						</label>
						<input
							type="text"
							className="form-control border border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
							onChange={(event) => handleSearch(event)}
							value={state.search}
						/>
					</div>
				</div>
				<div className="col-auto ms-auto">
					<div className="d-flex align-items-center gap-3 justify-content-end">
						<button
							type="button"
							className="btn btn-orange text-white rounded-pill px-4 py-2 ms-md-3 ms-0 flex-md-grow-0 flex-fill"
							onClick={() => handleEditClick()}
						>
							Yeni
							<i className="fas fa-plus ms-2" />
						</button>
					</div>
				</div>
			</div>
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr className="fs-7">
							<th
								scope="col"
								className="align-middle text-gray text-center"
								width="60"
							>
								ID
							</th>
							<th scope="col" className="align-middle text-gray">
								Anket Adı
							</th>
							{/* <th scope="col" className="align-middle text-gray">
								Anket Tipi
							</th> */}
							<th scope="col" className="align-middle text-gray">
								Kullanıcı Tipi
							</th>
							<th
								scope="col"
								className="align-middle text-gray text-center"
								style={{ width: 170 }}
							>
								İşlem
							</th>
						</tr>
					</thead>
					<tbody className="fs-7">
						{state.surveys &&
							state.surveys.length > 0 &&
							state.surveys
								.filter(
									(item) =>
										item.text &&
										item.text
											.toLowerCase()
											.search(
												state?.search?.toLowerCase()
											) > -1
								)
								.map((item) => (
									<tr key={item.id}>
										<td className="align-middle text-gray-light text-center fw-medium fs-8">
											{item.id}
										</td>
										<td className="align-middle text-gray">
											{item.text}
										</td>
										{/* <td className="align-middle text-gray">
										{item.surveyStatus === 'HOME'
											? 'Anasayfa'
											: 'Eğitim'}
									</td> */}
										<td className="align-middle text-gray">
											{item.surveyType === 'ALL'
												? 'Herkes'
												: item.surveyType === 'MENTOR'
												? 'Mentor'
												: 'Öğrenci'}
										</td>
										<td className="align-middle text-gray text-center">
											<button
												type="button"
												className="btn border border-2 border-red text-red bg-white rounded-pill px-3 py-1 fs-7 mx-1"
												onClick={() =>
													handleRemoveClick(item.id)
												}
											>
												Sil
											</button>
											<button
												type="button"
												className="btn border border-2 border-orange text-orange bg-white rounded-pill px-3 py-1 fs-7 mx-1"
												onClick={() =>
													handleEditClick(item)
												}
											>
												Düzenle
											</button>
										</td>
									</tr>
								))}
					</tbody>
				</table>
			</div>

			<Modal
				showModal={state.showModal}
				onClose={() =>
					setState({
						...state,
						showModal: false,
					})
				}
				header={state.header}
			>
				<div className="modal-body p-4">
					<form>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Anket Adı
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 50 karakter uzunluğunda bir anket adı giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="text"
									value={state.text}
									onChange={(event) => {
										setState({
											...state,
											text: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={50}
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Kullanıcı Tipi
								</label>
							</div>
							<div className="col-7">
								<select
									name="surveyType"
									className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
									defaultValue={state.surveyType}
									onChange={(event) => {
										setState({
											...state,
											surveyType: event.target.value,
										});
									}}
									required
								>
									<option value="">
										Hedef Kitle Seçiniz
									</option>
									<option value="ALL">Tümü</option>
									<option value="MENTOR">
										Tüm Mentorlar
									</option>
									<option value="MENTEE">
										Tüm Kurumsal Üyeler
									</option>
								</select>
							</div>
						</div>
						{/* <div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Anket Tipi
								</label>
							</div>
							<div className="col-7">
								<select
									name="surveyStatus"
									className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
									defaultValue={state.surveyStatus}
									onChange={(event) => {
										setState({
											...state,
											surveyStatus: event.target.value,
										});
									}}
									required
								>
									<option value="">Anket Tipi Seçiniz</option>
									<option value="HOME">Anasayfa</option>
									<option value="ACTIVITY">Eğitim</option>
								</select>
							</div>
						</div> */}
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Başlangıç Tarihi
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="text"
									value={state.dateTimeBegin}
									onChange={(event) => {
										setState({
											...state,
											dateTimeBegin: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Bitiş Tarihi
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="text"
									value={state.dateTimeEnd}
									onChange={(event) => {
										setState({
											...state,
											dateTimeEnd: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Soru
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 80 karakter uzunluğunda bir soru giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<textarea
									rows="4"
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									placeholder="Lütfen soru giriniz"
									// defaultValue={state.title}
									value={state.title} // surveyQuestionDTOList.title
									onChange={(event) => handleTitle(event)}
									maxLength={80}
								/>
							</div>
						</div>

						{state?.choiceList && state?.choiceList?.length > 0 && (
							<div className="row g-4 align-items-center mb-3">
								<div className="col-5">
									<label className="col-form-label fw-medium">
										Seçenekler
									</label>
								</div>
								<div className="col-7">
									{state.choiceList.map((item) => (
										<div
											key={item.description}
											className="choice-item"
										>
											<input
												className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg mb-3"
												type="text"
												defaultValue={item.description}
												onChange={(event) => {
													handleEditChoice(
														event,
														item.id
													);
												}}
											/>

											<button
												className="text-red"
												type="button"
												onClick={(e) =>
													handleRemoveChoice(
														e,
														item.id
													)
												}
											>
												<span className="fa fa-times" />
											</button>
										</div>
									))}
								</div>
							</div>
						)}

						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Seçenek Ekle
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 80 karakter uzunluğunda bir seçenek giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="text"
									value={currentChoice}
									placeholder="Lütfen seçenek giriniz"
									onChange={(event) => {
										handleChoice(event);
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg mb-3"
									maxLength={80}
								/>

								<button
									type="button"
									className="btn border border-2 border-green text-green rounded-pill px-4 py-2"
									onClick={() => handleAddChoice()}
								>
									Seçenek Ekle
								</button>
							</div>
						</div>

						{state?.surveyResult &&
							state?.surveyResult?.surveyQuestionDTOList &&
							state?.surveyResult?.surveyQuestionDTOList?.length >
								0 && (
								<div className="row g-4 align-items-center mb-3">
									<div className="col-5">
										<label className="col-form-label fw-medium">
											Sonuçlar
										</label>
									</div>
									<div className="col-7">
										{state?.surveyResult?.surveyQuestionDTOList?.map(
											(item) => (
												<div key={item.id}>
													{item.surveyChoiceDTOList &&
														item.surveyChoiceDTOList.map(
															(choice) => (
																<div
																	key={
																		choice.id
																	}
																	className="d-flex flex-row"
																	style={{
																		justifyContent:
																			'space-between',
																	}}
																>
																	<span className="mr-2">
																		{
																			choice.description
																		}
																	</span>

																	<span>
																		{state
																			.surveyResult
																			.totalParticipant >
																		0
																			? `%${Math.round(
																					(choice.answererCount /
																						state
																							.surveyResult
																							.totalParticipant) *
																						100
																					// eslint-disable-next-line no-mixed-spaces-and-tabs
																			  )}`
																			: 0}
																	</span>
																</div>
															)
														)}
												</div>
											)
										)}
									</div>
								</div>
							)}
					</form>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() => handleSaveClick()}
					>
						Kaydet
					</button>
				</div>
			</Modal>
			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminContentsSurveys;
