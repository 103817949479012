import React from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';

const AdminMentorsStats = () => {
	const [context] = React.useContext(Context);
	const [stats, setStats] = React.useState();

	React.useEffect(() => {
		fetch(`${constants.API_BASE_URL}/admin/chartStatistics`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data?.chartMentorDTO) {
					setStats(response.data.chartMentorDTO);
				}
			});
	}, []);

	return (
		stats && (
			<div className="row text-center mb-6 g-3 row-cols-2 row-cols-md-3">
				<div className="col">
					<div className="rounded rounded-2 bg-green p-2">
						<h5 className="mb-1 text-white">{stats.activeCount}</h5>
						<h6 className="text-white text-truncate small mb-0 opacity-75">
							Aktif
						</h6>
					</div>
				</div>
				<div className="col">
					<div className="rounded rounded-2 bg-red p-2">
						<h5 className="mb-1 text-white">
							{stats.passiveCount}
						</h5>
						<h6 className="text-white text-truncate small mb-0 opacity-75">
							Pasif
						</h6>
					</div>
				</div>
				<div className="col">
					<div
						className="rounded rounded-2 p-2"
						style={{ backgroundColor: '#fbbf24' }}
					>
						<h5 className="mb-1 text-white">{stats.poolCount}</h5>
						<h6 className="text-white text-truncate small mb-0 opacity-75">
							Havuz
						</h6>
					</div>
				</div>
			</div>
		)
	);
};

const AdminMentorsFilters = () => {
	const [context, setContext] = React.useContext(Context);

	const handleViewClick = (event, type) => {
		event.preventDefault();

		setContext({
			...context,
			adminMentorsViewType: type,
		});
	};

	const handleSearch = (event) => {
		event.preventDefault();

		if (event.target.value.length > 2) {
			const filteredList = context.dataMentors.filter(
				(el) =>
					el.name
						.toLowerCase()
						.search(event.target.value.toLowerCase()) > -1 // eslint-disable-line no-console
			);

			setContext({
				...context,
				dataMentors: filteredList,
			});
		}

		if (event.target.value.length === 0) {
			setContext({
				...context,
				dataMentors: context.defaultMentors,
			});
		}
	};

	const handleCreateMentor = (event) => {
		event.preventDefault();

		setContext({
			...context,
			showModal: true,
		});
	};

	return (
		<div className="row g-md-4 g-2 align-items-center mb-5">
			{/* <div className="col-md-3">
				<div className="d-flex align-items-center">
					<label className="form-label fw-medium mb-0 me-3 fs-7">
						Filtrele
					</label>
					<select className="form-select border-2 border-gray-lighter py-1 px-2 lh-sm fs-7">
						<option value="0">Tümü</option>
					</select>
				</div>
			</div> */}
			<div className="col-md-3">
				<div className="d-flex align-items-center">
					<label className="form-label fw-medium mb-0 me-3 fs-7">
						Arama
					</label>
					<input
						type="text"
						className="form-control border border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
						onChange={(event) => handleSearch(event)}
					/>
				</div>
			</div>
			<div className="col-md-3" />
			<div className="col-md-6">
				<div className="d-flex align-items-center gap-3 justify-content-end">
					<a
						href="#"
						className={`d-md-block d-none ${
							context.adminMentorsViewType === 'grid'
								? 'text-orange'
								: 'text-gray-light'
						}`}
						onClick={(event) => handleViewClick(event, 'grid')}
					>
						<i className="fas fa-th-large" />
					</a>
					<a
						href="#"
						className={`d-md-block d-none ${
							context.adminMentorsViewType === 'list'
								? 'text-orange'
								: 'text-gray-light'
						}`}
						onClick={(event) => handleViewClick(event, 'list')}
					>
						<i className="fas fa-list-ul" />
					</a>

					<a
						href="#"
						onClick={(event) => handleCreateMentor(event)}
						className="btn btn-orange text-white rounded-pill px-4 py-2 ms-md-3 ms-0 flex-md-grow-0 flex-fill"
					>
						Yeni
						<i className="fas fa-plus ms-2" />
					</a>
				</div>
			</div>
		</div>
	);
};

const AdminMentorsPagination = () => (
	<div className="d-flex justify-content-center mt-8 d-none">
		<ul className="pagination">
			<li className="page-item">
				<a
					className="p-3 bg-gray-lightest text-gray text-decoration-none rounded rounded-2 mx-1"
					href="#"
				>
					&laquo;
				</a>
			</li>
			<li className="page-item">
				<a
					className="p-3 bg-orange text-white text-decoration-none rounded rounded-2 mx-1"
					href="#"
				>
					1
				</a>
			</li>
			<li className="page-item">
				<a
					className="p-3 bg-gray-lightest text-gray text-decoration-none rounded rounded-2 mx-1"
					href="#"
				>
					2
				</a>
			</li>
			<li className="page-item">
				<a
					className="p-3 bg-gray-lightest text-gray text-decoration-none rounded rounded-2 mx-1"
					href="#"
				>
					3
				</a>
			</li>
			<li className="page-item">
				<a
					className="p-3 bg-gray-lightest text-gray text-decoration-none rounded rounded-2 mx-1"
					href="#"
				>
					&raquo;
				</a>
			</li>
		</ul>
	</div>
);

const AdminMentorsViewGrid = () => {
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		showLoading: false,
	});

	const getPrograms = (listContext) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp && resp.content) {
					// Context
					setContext({
						...context,
						...listContext,
						dataProgramList: resp.content,
					});
				}

				// Loader Hide
				setState({
					...state,
					showLoading: false,
				});
			});
	};

	const getUserById = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/getUser/${id}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					setState({
						...state,
						showLoading: false,
					});

					getPrograms({
						...context,
						modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
						modalData: response.data,
						showModal: true,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	const handleShowProfile = (event, id) => {
		event.preventDefault();
		getUserById(id);

		setContext({
			...context,
			showModal: true,
		});
	};

	return (
		<div className="row g-4">
			{context?.dataMentors &&
				context.dataMentors.length > 0 &&
				context.dataMentors.map((item) => (
					<div className="col-lg-4 col-md-6" key={item.id}>
						<a
							href="#"
							onClick={(event) =>
								handleShowProfile(event, item.id)
							}
							className={
								item.mentorStatus &&
								item.mentorStatus === 'ACTIVE'
									? 'd-block bg-gray-lightest rounded-2 p-4 text-reset text-decoration-none h-100 position-relative'
									: 'd-block bg-red-lightest rounded-2 p-4 text-reset text-decoration-none h-100 position-relative'
							}
						>
							<div className="d-flex align-items-center">
								<div
									className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center h4 mb-0"
									style={{ width: 80, height: 80 }}
								>
									{item.name && item.name.substr(0, 1)}
									{item.surname && item.surname.substr(0, 1)}
								</div>
								<div className="flex-grow-1">
									<h5 className="mb-0 fs-6 text-blue text-truncate w-75">
										{item.name && item.name}{' '}
										{item.surname && item.surname}
									</h5>
									<span className="fs-8 text-gray-light">
										{item.birthday && item.birthday}
									</span>
								</div>
							</div>

							{item.mentorStatus &&
							item.mentorStatus === 'ACTIVE' ? (
								<span
									className="position-absolute bg-green d-block top-0 end-0 m-3 rounded-circle"
									style={{ width: 8, height: 8 }}
								/>
							) : (
								<span
									className="position-absolute bg-red d-block top-0 end-0 m-3 rounded-circle"
									style={{ width: 8, height: 8 }}
								/>
							)}
						</a>
					</div>
				))}
		</div>
	);
};

const AdminMentorsViewList = () => {
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		showLoading: false,
	});

	const getPrograms = (listContext) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp && resp.content) {
					// Context
					setContext({
						...context,
						...listContext,
						dataProgramList: resp.content,
					});
				}

				// Loader Hide
				setState({
					...state,
					showLoading: false,
				});
			});
	};

	const getUserById = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/getUser/${id}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					setState({
						...state,
						showLoading: false,
					});

					getPrograms({
						...context,
						modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
						modalData: response.data,
						showModal: true,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	const handleShowProfile = (event, id) => {
		event.preventDefault();
		getUserById(id);

		setContext({
			...context,
			showModal: true,
		});
	};

	return (
		<div className="table-responsive">
			<table className="table table-striped">
				<thead>
					<tr className="fs-7">
						<th
							scope="col"
							className="align-middle text-gray text-center"
							width="60"
						>
							ID
						</th>
						<th scope="col" className="align-middle text-gray">
							İsim
						</th>
						<th scope="col" className="align-middle text-gray">
							Soyisim
						</th>
						<th scope="col" className="align-middle text-gray">
							E-Posta
						</th>
						<th scope="col" className="align-middle text-gray">
							Telefon
						</th>
						<th
							scope="col"
							className="align-middle text-gray text-center"
							style={{ width: 170 }}
						>
							İşlem
						</th>
					</tr>
				</thead>
				<tbody className="fs-7">
					{context?.dataMentors &&
						context.dataMentors.length > 0 &&
						context?.dataMentors
							.filter(
								(item) =>
									(item.name + item.surname)
										.toLowerCase()
										.search(state?.search?.toLowerCase()) >
									-1
							)
							.map((item) => (
								<tr key={item.id}>
									<td className="align-middle text-gray-light text-center fw-medium fs-8">
										{item.id}
									</td>
									<td className="align-middle text-gray">
										<div className="d-flex align-items-center">
											{item.mentorStatus === 'ACTIVE' ? (
												<span
													className="bg-green d-block me-2 rounded-circle"
													style={{
														width: 8,
														height: 8,
													}}
												/>
											) : (
												<span
													className="bg-red d-block me-2 rounded-circle"
													style={{
														width: 8,
														height: 8,
													}}
												/>
											)}
											{item.name}
										</div>
									</td>
									<td className="align-middle text-gray">
										{item.surname}
									</td>
									<td className="align-middle text-gray">
										{item.email}
									</td>
									<td className="align-middle text-gray">
										{item.phoneNumber}
									</td>
									<td className="align-middle text-gray text-center">
										<button
											type="button"
											className="btn border border-2 border-orange text-orange bg-white rounded-pill px-3 py-1 fs-7 mx-1"
											onClick={(event) =>
												handleShowProfile(
													event,
													item.id
												)
											}
										>
											Düzenle
										</button>
									</td>
								</tr>
							))}
				</tbody>
			</table>
		</div>
	);
};

const AdminMentors = () => {
	// eslint-disable-next-line no-unused-vars
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		showLoading: false,
	});

	const navigate = useNavigate();

	const [universities, setUniversities] = React.useState(null);
	const [faculties, setFaculties] = React.useState(null);
	const [departments, setDepartments] = React.useState(null);

	const handleSaveClick = (event) => {
		event.preventDefault();

		let contextCurrent = { ...context };
		contextCurrent = {
			...context.modalData,
			userType: 'MENTOR',
		};

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/updateUser`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify(contextCurrent),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'success',
							message: 'Mentor Kaydedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: response.message,
						},
					});
				}
			});
	};

	const [dataProvinces, setDataProvinces] = React.useState(null);

	const getAllProvince = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/getAllProvince`, {
			method: 'GET',
			headers: constants.API_HEADERS_PUBLIC,
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data) {
					setDataProvinces(response.data);
				}

				// Loader Hide
				setState({
					...state,
					showLoading: false,
				});
			});
	};

	// Universities
	const getUniversities = () => {
		// Loader
		setState({ ...state, showLoading: true });

		// Get Universities
		fetch(`${constants.API_BASE_URL}/public/getUniversityNameAll`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((university) => university.json())
			.then((university) => {
				if (university.data) {
					setState({
						...state,
						showLoading: true,
					});

					setUniversities(university.data);
				} else {
					setUniversities('');
				}
			});
	};

	// Faculty
	const getUniversityFaculty = (universityId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getFacultyAll/${universityId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								universityDTO: {
									...context?.modalData?.educationInfoDTO
										?.universityDTO,
									id: universityId,
								},
							},
						},
						universityFacultyList: response.data,
						universityDepartmentList: undefined,
					});

					setFaculties(response.data);
					setDepartments('');
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setFaculties('');
					setDepartments('');
				}
			});
	};

	// Department
	const getUniversityDepartment = (facultyId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${facultyId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setDepartments(response.data);
				} else {
					setDepartments('');

					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	const handleGetAllUniversityFaculty = (e, universityId) => {
		e.preventDefault();

		if (universityId !== '') {
			getUniversityFaculty(universityId);
		} else {
			setFaculties('');
			setDepartments('');
		}
	};

	const handleGetAllUniversityDepartment = (e, facultyId) => {
		e.preventDefault();

		if (facultyId !== '') {
			getUniversityDepartment(facultyId);
		} else {
			setDepartments('');
		}
	};

	// useEffect
	React.useEffect(() => {
		// ALL PROVINCES
		getAllProvince();

		// ALL UNIVERSITIES
		getUniversities();
	}, []);

	// useEffect
	React.useEffect(() => {
		if (context?.modalData?.educationInfoDTO?.universityDTO?.id) {
			// ALL FACULTIES
			getUniversityFaculty(
				context.modalData.educationInfoDTO.universityDTO.id
			);
		}
	}, [context.dataProgramList]);

	// useEffect
	React.useEffect(() => {
		// ALL DEPARTMENTS
		if (context?.modalData?.educationInfoDTO?.faculty?.id) {
			getUniversityDepartment(
				context.modalData.educationInfoDTO.faculty.id
			);
		}
	}, [context.dataProgramList && faculties]);

	// useEffect
	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/getAllMentor?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data && response.data.content) {
					// Context Mentors
					if (context.adminMentorsViewType === 'list') {
						setContext({
							...context,
							adminMentorsViewType: 'list',
							dataMentors: response.data.content,
							defaultMentors: response.data.content,
						});
					} else {
						setContext({
							...context,
							adminMentorsViewType: 'grid',
							dataMentors: response.data.content,
							defaultMentors: response.data.content,
						});
					}

					// Loader Hide
					setState({
						...state,
						showLoading: false,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						adminMentorsViewType: 'grid',
					});
				}
			});
	}, []);

	const handleActivation = (e, type) => {
		e.preventDefault();

		if (type) {
			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/admin/welcomeMail`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					userId: context.modalData.id,
					welcomeType: type,
				}),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.data) {
						let message = '';
						if (type === 'mail') {
							message = 'Mail';
						} else {
							message = 'SMS';
						}

						setState({
							...state,
							showLoading: false,
							toast: {
								type: 'success',
								message: `${message} gönderildi`,
							},
						});

						setInterval(() => {
							window.location.reload();
						}, 1500);
					} else {
						setState({
							...state,
							showLoading: false,
							toast: {
								type: 'error',
								message: response.message,
							},
						});
					}
				});
		}
	};

	return (
		<>
			<WidgetAdminTitleBar title="Bireysel Üyeler" />
			<AdminMentorsStats />
			<AdminMentorsFilters />
			{context.adminMentorsViewType === 'list' ? (
				<AdminMentorsViewList />
			) : (
				<AdminMentorsViewGrid />
			)}
			<AdminMentorsPagination />
			<Modal
				showModal={context.showModal}
				onClose={() =>
					setContext({
						...context,
						showModal: false,
						modalDataHeader: undefined,
						modalData: null,
					})
				}
				header={
					context.modalDataHeader
						? context.modalDataHeader
						: 'Mentör Oluştur'
				}
				size="lg"
			>
				<div className="modal-body p-4">
					<div className="accordion" id="accordionProfile">
						{/* Kişisel Bilgiler  */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-1"
								>
									<h5 className="text-orange mb-0">
										Kişisel Bilgiler
									</h5>
								</button>
							</h2>
							<div
								id="group-1"
								className="accordion-collapse collapse show"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">İsim</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.name &&
														context.modalData.name
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																name: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Soyisim
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.surname &&
														context.modalData
															.surname
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																surname:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Doğum Tarihi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="date"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.birthday &&
														context.modalData
															.birthday
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																birthday:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Cinsiyet
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.gender &&
														context.modalData.gender
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																gender: event
																	.target
																	.value,
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="MAN">
														Erkek
													</option>
													<option value="WOMAN">
														Kadın
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İletişim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-2"
								>
									<h5 className="text-orange mb-0">
										İletişim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-2"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Telefon Numarası
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.phoneNumber &&
														context.modalData
															.phoneNumber
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																phoneNumber:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													E-Posta Adresi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.email &&
														context.modalData.email
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																email: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Bulunduğu İl
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.mentorCity?.id &&
														context.modalData
															.mentorCity.id
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																mentorCity: {
																	...context
																		?.modalData
																		?.mentorCity,
																	id: event
																		.target
																		.value,
																},
															},
														});
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{dataProvinces?.length >
														0 &&
														dataProvinces.map(
															(province) => (
																<option
																	key={
																		province.id &&
																		province.id
																	}
																	value={
																		province.id &&
																		province.id
																	}
																>
																	{province.name &&
																		province.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Eğitim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-3"
								>
									<h5 className="text-orange mb-0">
										Eğitim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-3"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Lise Adı
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.highSchoolName &&
														context.modalData
															.educationInfoDTO
															.highSchoolName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		highSchoolName:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Üniversite Adı
												</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityDTO
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityDTO.id
													}
													onChange={(event) => {
														handleGetAllUniversityFaculty(
															event,
															event.target.value
														);
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{universities &&
														universities.length >
															0 &&
														universities.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Fakülte
												</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.faculty?.id &&
														context.modalData
															.educationInfoDTO
															.faculty.id
													}
													onChange={(event) => {
														handleGetAllUniversityDepartment(
															event,
															event.target.value
														);

														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		faculty:
																			{
																				...context
																					?.modalData
																					?.educationInfoDTO
																					?.faculty,
																				id: event
																					.target
																					.value,
																			},
																	},
															},
														});
													}}
													disabled={!faculties}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{faculties &&
														faculties.length > 0 &&
														faculties.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Bölüm</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityDepartment
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityDepartment
															.id
													}
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityDepartment:
																			{
																				...context
																					?.modalData
																					?.educationInfoDTO
																					?.universityDepartment,
																				id: event
																					.target
																					.value,
																			},
																	},
															},
														});
													}}
													disabled={!departments}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{departments &&
														departments.length >
															0 &&
														departments.map(
															(department) => (
																<option
																	key={
																		department.id &&
																		department.id
																	}
																	value={
																		department.id &&
																		department.id
																	}
																>
																	{department.name &&
																		department.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Yüksek Lisans
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.degree &&
														context.modalData
															.educationInfoDTO
															.degree
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																educationInfoDTO:
																	{
																		...context
																			.modalData
																			.educationInfoDTO,
																		degree: event
																			.target
																			.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Doktora
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.doctorate &&
														context.modalData
															.educationInfoDTO
															.doctorate
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																educationInfoDTO:
																	{
																		...context
																			.modalData
																			.educationInfoDTO,
																		doctorate:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Çalışma Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-4"
								>
									<h5 className="text-orange mb-0">
										Çalışma Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-4"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Çalıştığı Firma
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.jobInfoDTO
															?.firmName &&
														context.modalData
															.jobInfoDTO.firmName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																jobInfoDTO: {
																	...context
																		.modalData
																		.jobInfoDTO,
																	firmName:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Sektör</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.jobInfoDTO
															?.sectorName &&
														context.modalData
															.jobInfoDTO
															.sectorName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																jobInfoDTO: {
																	...context
																		.modalData
																		.jobInfoDTO,
																	sectorName:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													İş Ünvanı
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.jobInfoDTO
															?.jobTitle &&
														context.modalData
															.jobInfoDTO.jobTitle
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																jobInfoDTO: {
																	...context
																		.modalData
																		.jobInfoDTO,
																	jobTitle:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Geçmiş İş Bilgisi
												</h6>
											</div>
											<div className="col-8">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.jobInfoDTO
															?.jobPastInfo &&
														context.modalData
															.jobInfoDTO
															.jobPastInfo
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																jobInfoDTO: {
																	...context
																		.modalData
																		.jobInfoDTO,
																	jobPastInfo:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İlgi Alanları */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-5"
								>
									<h5 className="text-orange mb-0">
										İlgi Alanları
									</h5>
								</button>
							</h2>
							<div
								id="group-5"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-12">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.interest &&
														context.modalData
															.interest
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																interest:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Diğer */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-7"
								>
									<h5 className="text-orange mb-0">Diğer</h5>
								</button>
							</h2>
							<div
								id="group-7"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionProfile"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Durum</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.mentorStatus &&
														context.modalData
															.mentorStatus
													}
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																mentorStatus:
																	event.target
																		.value,
															},
														});
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="ACTIVE">
														Aktif
													</option>
													<option value="PASSIVE">
														Pasif
													</option>
													<option value="POOL">
														Havuz
													</option>
												</select>
											</div>
										</div>
									</div>

									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Not</h6>
											</div>
											<div className="col-8">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.mentorNote &&
														context.modalData
															.mentorNote
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																mentorNote:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* eslint-disable */}
						{/* Diğer Bilgiler */}
						{/* <div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed bg-transparent p-4"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#group-6"
									>
										<h5 className="text-orange mb-0">
											Diğer Bilgiler
										</h5>
									</button>
								</h2>
								<div
									id="group-6"
									className="accordion-collapse collapse"
									data-bs-parent="#accordionProfile"
								>
									<div className="accordion-body p-4">
										<div className="mb-3">
											<div className="row">
												<div className="col-4">
													<h6 className="mb-0">Durum</h6>
												</div>
												<div className="col-8">
													<select
														value={
															context?.modalData
																?.mentorStatus &&
															context.modalData
																.mentorStatus
														}
														className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
														onChange={(event) =>
															setContext({
																...context,
																modalData: {
																	...context.modalData,
																	mentorStatus:
																		event.target
																			.value,
																},
															})
														}
													>
														<option value="">
															Lütfen seçiniz
														</option>
														<option value="ACTIVE">
															Aktif
														</option>
														<option value="PASSIVE">
															Pasif
														</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						{/* eslint-enable */}
					</div>

					<div className="modal-footer justify-content-center">
						<button
							type="button"
							className="btn bg-white text-orange border border-2 border-orange text-white rounded-pill px-4 py-2 me-2"
							onClick={(e) => handleActivation(e, 'mail')}
						>
							<i className="fas fa-envelope me-2" /> Mail
						</button>
						<button
							type="button"
							className="btn bg-white text-orange border border-2 border-orange text-white rounded-pill px-4 py-2 me-2"
							onClick={(e) => handleActivation(e, 'sms')}
						>
							<i className="fas fa-sms me-2" /> SMS
						</button>
						<button
							type="button"
							className="btn bg-white text-orange border border-2 border-orange text-white rounded-pill px-4 py-2 me-2"
							onClick={() =>
								navigate('/admin/iletisim', {
									state: {
										receiver: {
											id:
												context?.modalData?.id &&
												context.modalData.id,
											name:
												context?.modalData?.name &&
												context.modalData.name,
											surname:
												context?.modalData?.surname &&
												context.modalData.surname,
										},
									},
								})
							}
						>
							<i className="fas fa-envelope me-2" /> Mesaj Gönder
						</button>
						<button
							type="button"
							className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
							onClick={(event) => handleSaveClick(event)}
						>
							Kaydet
						</button>
					</div>
				</div>
			</Modal>
			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminMentors;
