import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import Context from '../../stores/Context/Context';

const AdminHeader = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const navigate = useNavigate();

	const handleLogoutClick = (event) => {
		event.preventDefault();
		localStorage.setItem('context', JSON.stringify({}));
		setContext({});
		navigate('/giris');
		window.location.reload();
	};

	return (
		<div className="pe-xl-5 pe-lg-3 pe-0">
			<div className="shadow-lg bg-white position-relative px-lg-4 py-lg-5 p-3 rounded-2 mb-lg-0 mb-5">
				<div className="d-lg-none d-flex justify-content-between align-items-center">
					<NavLink to="/admin" className="navbar-logo">
						<img
							src={require('../../assets/images/logo-jarvis.png')}
							alt=""
							className="col-3"
						/>
					</NavLink>
					<a
						href=".offcanvas"
						className=""
						data-bs-toggle="offcanvas"
					>
						<i className="fas fa-bars fs-4 mb-0 text-blue-light" />
					</a>
				</div>

				<div className="offcanvas offcanvas-start offcanvas-responsive-lg">
					<button
						type="button"
						className="btn-close text-reset position-absolute end-0 top-0 me-3 mt-3 d-lg-none d-block"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					/>
					<div className="offcanvas-body">
						<div className="text-center mb-5">
							<NavLink to="/admin" className="navbar-logo">
								<img
									src={require('../../assets/images/logo-jarvis.png')}
									alt=""
									className="col-lg-8 col-4"
								/>
							</NavLink>
						</div>
						<ul className="list-unstyled gap-3 d-grid mb-0">
							<li>
								<NavLink
									end
									to="/admin"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-columns me-3"
										style={{ width: 16 }}
									/>
									Dashboard
								</NavLink>
							</li>
							<li>
								<NavLink
									end
									to="/admin/programlar"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-list me-3"
										style={{ width: 16 }}
									/>
									Komiteler
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/ogrenciler"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-users me-3"
										style={{ width: 16 }}
									/>
									Kurumsal Üyeler
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/mentorlar"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-chalkboard-user me-3"
										style={{ width: 16 }}
									/>
									Bireysel Üyeler
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/takvim"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-calendar-alt me-3"
										style={{ width: 16 }}
									/>
									Takvim
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/onaylar"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-check-circle me-3"
										style={{ width: 16 }}
									/>
									Onaylar
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/egitimler"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-book-open me-3"
										style={{ width: 16 }}
									/>
									Etkinlikler
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/dosyalar"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-folder me-3"
										style={{ width: 16 }}
									/>
									Dosyalar
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/icerikler"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="far fa-file-alt me-3"
										style={{ width: 16 }}
									/>
									İçerikler
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/uyarilar"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="far fa-bell me-3"
										style={{ width: 16 }}
									/>
									Hatırlatmalar
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/admin/raporlar"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="far fa-file me-3"
										style={{ width: 16 }}
									/>
									Raporlar
								</NavLink>
							</li>
							{context.dataUser?.id === 999 &&
							context.dataUser.userType === 'ADMIN' ? (
								<li>
									<NavLink
										to="/admin/destek-ekle"
										className="text-gray fw-medium text-decoration-none"
									>
										<i
											className="fas fa-phone me-3"
											style={{ width: 16 }}
										/>
										Destek
									</NavLink>
								</li>
							) : (
								<li>
									<NavLink
										to="/admin/destek"
										className="text-gray fw-medium text-decoration-none"
									>
										<i
											className="fas fa-phone me-3"
											style={{ width: 16 }}
										/>
										Destek
									</NavLink>
								</li>
							)}
							<li>
								<NavLink
									to="/admin/iletisim"
									className="text-gray fw-medium text-decoration-none"
								>
									<i
										className="fas fa-at me-3"
										style={{ width: 16 }}
									/>
									İletişim
								</NavLink>
							</li>
						</ul>
						<div className="mt-4">
							<button
								type="button"
								className="btn btn-link text-red border-0 rounded-0 p-0 text-decoration-none fw-medium"
								onClick={(event) => handleLogoutClick(event)}
							>
								<i
									className="fa-solid fa-arrow-right-from-bracket me-3"
									style={{ width: 16 }}
								/>
								Çıkış Yap
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminHeader;
