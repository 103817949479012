/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import constants from '../../constants';
import Context from '../../stores/Context/Context';
import WidgetCTA from '../../components/WidgetCTA/WidgetCTA';
import WidgetMentees from '../../components/WidgetMentees/WidgetMentees';
import WidgetMentor from '../../components/WidgetMentor/WidgetMentor';
import WidgetPrograms from '../../components/WidgetPrograms/WidgetPrograms';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

const ProfileTitle = () => {
	const [context, setContext] = React.useContext(Context);
	const { userRole } = context.dataLogin;
	const navigate = useNavigate();

	const handleLogoutClick = (event) => {
		event.preventDefault();
		localStorage.setItem('context', JSON.stringify({}));
		setContext({});
		navigate('/giris');
	};

	return (
		<>
			<div className="d-flex align-items-center justify-content-center mb-5">
				<div
					className="flex-shrink-0 me-3 rounded-circle bg-blue-light text-white d-flex justify-content-center align-items-center text-center fs-1 fw-medium mb-0 text-uppercase"
					style={{ width: 120, height: 120 }}
				>
					{`${
						context.dataUser.name &&
						context.dataUser.surname &&
						context.dataUser.name.substring(0, 1) +
							context.dataUser.surname.substring(0, 1)
					}`}
				</div>

				<div>
					<button
						type="button"
						className="btn btn-link text-red border-0 rounded-0 p-0 mb-2 fs-7 text-decoration-none fw-medium"
						onClick={(event) => handleLogoutClick(event)}
					>
						Çıkış Yap{' '}
						<i className="fa-solid fa-arrow-right-from-bracket ms-1" />
					</button>
					<h1 className="text-capitalize text-blue-light mb-2">
						{`${context.dataUser.name && context.dataUser.name} ${
							context.dataUser.surname && context.dataUser.surname
						}`}
					</h1>
					<span className="text-gray-light fs-5">
						{userRole === 'mentee' && 'Öğrenci'}
						{userRole === 'mentor' && 'Mentor'}
					</span>
				</div>
			</div>

			{context?.modalData?.cardStatus === 'NOTFILL' && (
				<div className="alert alert-red fs-7 text-center mb-4">
					Lütfen platform özelliklerinden faydalanabilmek ve giriş
					kartı oluşturmak üzere <br />
					<strong>
						Profil bilgilerinizi güncelleyin{' '}
						<i className="fas fa-arrow-right ms-1" />
					</strong>
				</div>
			)}
			{context?.modalData?.cardStatus === 'WAITINGAPPROVAL' && (
				<>
					<div className="alert alert-orange fs-7 text-center mb-4">
						<strong>
							<i className="fa-solid fa-clock me-2" /> Profiliniz
							onay bekliyor.
						</strong>
					</div>
					<style>
						{`
						.accordion input, .accordion select, .accordion textarea {
							border: none !important;
							pointer-events: none;
							background: none !important;
							appearance: none;
						}
						.accordion-body > div {
							border-bottom: 2px solid #FAFAFA;
							padding-bottom: 8px;
							margin-bottom: 8px !important;
						}
						`}
					</style>
				</>
			)}
			{context?.modalData?.cardStatus === 'ACCEPTED' && (
				<div className="alert alert-green fs-7 text-center mb-4">
					<strong>
						<i className="fa-solid fa-circle-check me-2" />{' '}
						Profiliniz onaylı
					</strong>
				</div>
			)}
			{context?.modalData?.cardStatus === 'DENIED' && (
				<div className="alert alert-red fs-7 text-center mb-4">
					<i className="fa-solid fa-circle-xmark me-2" />{' '}
					<strong>Profil bilgileriniz reddedildi:</strong>{' '}
					{context?.modalData?.rejectedResult}
				</div>
			)}
		</>
	);
};

const ProfileSectionInfo = () => {
	const [context, setContext] = React.useContext(Context);
	const [state, setState] = React.useState({
		showLoading: false,
	});
	const { userRole } = context.dataLogin;
	const [dataProvinces, setDataProvinces] = React.useState(null);
	const [activeAccordion, setActiveAccordion] = React.useState('group-1');

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const getPrograms = (listContext) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp && resp.content) {
					// Context
					setContext({
						...context,
						...listContext,
						dataProgramList: resp.content,
					});
				}

				// Loader Hide
				setState({
					...state,
					showLoading: false,
				});
			});
	};

	const getUserById = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/getUser/${id}`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					setState({ ...state, showLoading: true });
					if (
						response?.data?.educationInfoDTO?.universityDTO?.id &&
						!response?.data?.educationInfoDTO?.faculty?.id &&
						response.data.educationInfoDTO.universityDTO.id
					) {
						// Get Universities
						fetch(
							`${constants.API_BASE_URL}/public/getUniversityNameAll`,
							{
								method: 'GET',
								headers: {
									'Content-Type': 'application/json',
									Authorization: `Bearer ${context.dataLogin.token}`,
								},
							}
						)
							.then((university) => university.json())
							.then((university) => {
								if (university.data) {
									setState({
										...state,
										showLoading: true,
									});

									// Get Faculties
									fetch(
										`${constants.API_BASE_URL}/public/getFacultyAll/${response.data.educationInfoDTO.universityDTO.id}`,
										{
											method: 'GET',
											headers: {
												'Content-Type':
													'application/json',
												Authorization: `Bearer ${context.dataLogin.token}`,
											},
										}
									)
										.then(
											(responseFaculty) =>
												responseFaculty.json()
											// eslint-disable-next-line function-paren-newline
										)
										.then((responseFaculty) => {
											if (responseFaculty.data) {
												setState({
													...state,
													showLoading: false,
												});

												getPrograms({
													...context,
													modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
													modalData: response.data,
													showModal: true,
													universityList:
														university.data,
													universityFacultyList:
														responseFaculty.data,
													universityDepartmentList:
														undefined,
												});
											}
										});
								}
							});
					} else if (
						response?.data?.educationInfoDTO?.universityDTO?.id &&
						response?.data?.educationInfoDTO?.faculty?.id &&
						response.data.educationInfoDTO.faculty.id
					) {
						// Get Universities
						fetch(
							`${constants.API_BASE_URL}/public/getUniversityNameAll`,
							{
								method: 'GET',
								headers: {
									'Content-Type': 'application/json',
									Authorization: `Bearer ${context.dataLogin.token}`,
								},
							}
						)
							.then((university) => university.json())
							.then((university) => {
								if (university.data) {
									setState({
										...state,
										showLoading: true,
									});

									// Get Faculties
									fetch(
										`${constants.API_BASE_URL}/public/getFacultyAll/${response.data.educationInfoDTO.universityDTO.id}`,
										{
											method: 'GET',
											headers: {
												'Content-Type':
													'application/json',
												Authorization: `Bearer ${context.dataLogin.token}`,
											},
										}
									)
										.then(
											(responseFaculty) =>
												responseFaculty.json()
											// eslint-disable-next-line function-paren-newline
										)
										.then((responseFaculty) => {
											if (responseFaculty.data) {
												setState({
													...state,
													showLoading: true,
												});

												// Get Departments
												fetch(
													`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${response.data.educationInfoDTO.faculty.id}`,
													{
														method: 'GET',
														headers: {
															'Content-Type':
																'application/json',
															Authorization: `Bearer ${context.dataLogin.token}`,
														},
													}
												)
													.then(
														(responseDepartment) =>
															responseDepartment.json()
													)
													.then(
														(
															responseDepartment
														) => {
															if (
																responseDepartment.data
															) {
																setState({
																	...state,
																	showLoading: false,
																});

																getPrograms({
																	...context,
																	modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
																	modalData:
																		response.data,
																	showModal: true,
																	universityList:
																		university.data,
																	universityFacultyList:
																		responseFaculty.data,
																	universityDepartmentList:
																		responseDepartment.data,
																});
															} else {
																setState({
																	...state,
																	showLoading: false,
																});

																getPrograms({
																	...context,
																	modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
																	modalData:
																		response.data,
																	showModal: true,
																	universityList:
																		university.data,
																	universityFacultyList:
																		responseFaculty.data,
																	universityDepartmentList:
																		undefined,
																});
															}
														}
													);
											} else {
												setState({
													...state,
													showLoading: false,
												});

												setContext({
													...context,
													universityList:
														university.data,
													universityFacultyList:
														undefined,
													universityDepartmentList:
														undefined,
												});
											}
										});
								}
							});
					} else {
						// Get Universities
						fetch(
							`${constants.API_BASE_URL}/public/getUniversityNameAll`,
							{
								method: 'GET',
								headers: {
									'Content-Type': 'application/json',
									Authorization: `Bearer ${context.dataLogin.token}`,
								},
							}
						)
							.then((university) => university.json())
							.then((university) => {
								if (university.data) {
									setState({
										...state,
										showLoading: false,
									});

									getPrograms({
										...context,
										modalDataHeader: `${response?.data?.name} ${response?.data?.surname}`,
										modalData: response.data,
										showModal: true,
										universityList: university.data,
										universityFacultyList: undefined,
										universityDepartmentList: undefined,
									});
								} else {
									setState({ ...state, showLoading: false });
									setContext({
										...context,
										universityList: undefined,
										universityFacultyList: undefined,
										universityDepartmentList: undefined,
									});
								}
							});
					}

					// Loader Hide
					setState({
						...state,
						showLoading: false,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			});
	};

	const getUniversityFaculty = (universityId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getFacultyAll/${universityId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								universityDTO: {
									...context?.modalData?.educationInfoDTO
										?.universityDTO,
									id: universityId,
								},
							},
						},
						universityFacultyList: response.data,
						universityDepartmentList: undefined,
					});
				} else {
					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								universityDTO: {
									...context?.modalData?.educationInfoDTO
										?.universityDTO,
									id: universityId,
								},
							},
						},
					});
					setState({ ...state, showLoading: false });
				}
			});
	};

	const getUniversityDepartment = (facultyId) => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/public/getUniversityDepartmentAll/${facultyId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
					});

					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								faculty: {
									...context?.modalData?.educationInfoDTO
										?.faculty,
									id: facultyId,
								},
							},
						},
						universityDepartmentList: response.data,
					});
				} else {
					setContext({
						...context,
						modalData: {
							...context?.modalData,
							educationInfoDTO: {
								...context?.modalData?.educationInfoDTO,
								faculty: {
									...context?.modalData?.educationInfoDTO
										?.faculty,
									id: facultyId,
								},
							},
						},
					});
					setState({ ...state, showLoading: false });
				}
			});
	};

	const handleGetAllUniversityFaculty = (e, universityId) => {
		e.preventDefault();

		if (universityId !== '') {
			getUniversityFaculty(universityId);
		} else {
			setContext({
				...context,
				universityFacultyList: undefined,
				universityDepartmentList: undefined,
			});
		}
	};

	const handleGetAllUniversityDepartment = (e, facultyId) => {
		e.preventDefault();

		if (facultyId !== '') {
			getUniversityDepartment(facultyId);
		} else {
			setContext({
				...context,
				universityDepartmentList: undefined,
			});
		}
	};

	const handleSaveClick = async (event) => {
		event.preventDefault();

		setContext({
			...context,
			modalData: {
				...context.modalData,
				userType: 'MENTEE',
			},
		});

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/updateUser`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...context.modalData,
				...(context.NewCertificateImage ||
				context.modalData.certificateImage
					? {
							certificateImage:
								context.modalData.certificateImage &&
								!context.NewCertificateImage
									? context.modalData.certificateImage
									: await toBase64(
											context.NewCertificateImage
									  ),
					  }
					: {}),
				...(context.NewTranscriptImage ||
				context.modalData.transcriptImage
					? {
							transcriptImage:
								context.modalData.transcriptImage &&
								!context.NewTranscriptImage
									? context.modalData.transcriptImage
									: await toBase64(
											context.NewTranscriptImage
									  ),
					  }
					: {}),
				...(context.NewStkImage || context.modalData.stkImage
					? {
							stkImage:
								context.modalData.stkImage &&
								!context.NewStkImage
									? context.modalData.stkImage
									: await toBase64(context.NewStkImage),
					  }
					: {}),
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'success',
							message:
								'Bilgileriniz yönetici onayına gönderilmek üzere kaydedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message:
								'Lütfen tüm alanları doldurduğunuzdan emin olun! Lütfen sistem yöneticisine başvurun...',
						},
					});
				}
			});
	};

	React.useEffect(() => {
		fetch(`${constants.API_BASE_URL}/public/getAllProvince`, {
			method: 'GET',
			headers: constants.API_HEADERS_PUBLIC,
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.data) {
					setDataProvinces(response.data);
					getUserById(context.dataUser.id);

					// Loader Hide
					setState({
						...state,
						showLoading: false,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					showModal: false,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	return (
		<section>
			<div className="accordion" id="accordionProfile">
				{userRole === 'mentee' && (
					<>
						{/* Kişisel Bilgiler  */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-1' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-1'
												? null
												: 'group-1'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										Kişisel Bilgiler
									</h5>
								</button>
							</h2>
							<div
								id="group-1"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-1' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">İsim</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.name &&
														context.modalData.name
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																name: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Soyisim
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.surname &&
														context.modalData
															.surname
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																surname:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">TCKN</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.identityNo &&
														context.modalData
															.identityNo
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																identityNo:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Doğum Tarihi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="date"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.birthday &&
														context.modalData
															.birthday
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																birthday:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Cinsiyet
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.gender &&
														context.modalData.gender
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																gender: event
																	.target
																	.value,
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="MAN">
														Erkek
													</option>
													<option value="WOMAN">
														Kadın
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Iban</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.iban &&
														context.modalData.iban
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																iban: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İletişim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-2' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-2'
												? null
												: 'group-2'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										İletişim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-2"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-2' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Telefon Numarası
												</h6>
											</div>
											<div className="col-8">
												<input
													type="tel"
													minLength={10}
													maxLength={11}
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.phoneNumber &&
														context.modalData
															.phoneNumber
													}
													onChange={(event) => {
														if (
															event.target.value.startsWith(
																'0'
															)
														) {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																	phoneNumber:
																		event
																			.target
																			.value,
																},
															});
														} else {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																	phoneNumber: `0${event.target.value}`,
																},
															});
														}
													}}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													E-Posta Adresi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.email &&
														context.modalData.email
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																email: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Aile Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-3' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-3'
												? null
												: 'group-3'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										Aile Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-3"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-3' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Aile Durumu
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.familyInfoDTO
															?.familyStatus &&
														context.modalData
															.familyInfoDTO
															.familyStatus
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																familyInfoDTO: {
																	...context
																		?.modalData
																		?.familyInfoDTO,
																	familyStatus:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="MRFR">
														Anne Sağ, Baba Sağ
													</option>
													<option value="MRFN">
														Anne Sağ, Baba Yok
													</option>
													<option value="MNFR">
														Anne Yok, Baba Sağ
													</option>
													<option value="MNFN">
														Anne Yok, Baba Yok
													</option>
													<option value="DIVORCED">
														Boşanmışlar
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Aile Şehri
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.familyInfoDTO
															?.familyCity?.id &&
														context.modalData
															.familyInfoDTO
															.familyCity.id
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																familyInfoDTO: {
																	...context
																		?.modalData
																		?.familyInfoDTO,
																	familyCity:
																		{
																			...context
																				?.modalData
																				?.familyInfoDTO
																				?.familyCity,
																			id: event
																				.target
																				.value,
																		},
																},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{dataProvinces &&
														dataProvinces.length >
															0 &&
														dataProvinces.map(
															(province) => (
																<option
																	key={
																		province.id &&
																		province.id
																	}
																	value={
																		province.id &&
																		province.id
																	}
																>
																	{province.name &&
																		province.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													İletişim Kurulacak Kişi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.familyInfoDTO
															?.parentComName &&
														context.modalData
															.familyInfoDTO
															.parentComName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																familyInfoDTO: {
																	...context
																		?.modalData
																		?.familyInfoDTO,
																	parentComName:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													İletişim Kurulacak Kişi
													Telefon
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.familyInfoDTO
															?.parentComTel &&
														context.modalData
															.familyInfoDTO
															.parentComTel
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																familyInfoDTO: {
																	...context
																		?.modalData
																		?.familyInfoDTO,
																	parentComTel:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Eğitim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-4' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-4'
												? null
												: 'group-4'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										Eğitim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-4"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-4' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Lise Adı
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.highSchoolName &&
														context.modalData
															.educationInfoDTO
															.highSchoolName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		highSchoolName:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Üniversite Adı
												</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityDTO
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityDTO.id
													}
													onChange={(event) => {
														handleGetAllUniversityFaculty(
															event,
															event.target.value
														);
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityList &&
														context.universityList
															.length > 0 &&
														context.universityList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Fakülte
												</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.faculty?.id &&
														context.modalData
															.educationInfoDTO
															.faculty.id
													}
													onChange={(event) => {
														handleGetAllUniversityDepartment(
															event,
															event.target.value
														);
													}}
													disabled={
														!context.universityFacultyList
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityFacultyList &&
														context
															.universityFacultyList
															.length > 0 &&
														context.universityFacultyList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Bölüm</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityDepartment
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityDepartment
															.id
													}
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityDepartment:
																			{
																				...context
																					?.modalData
																					?.educationInfoDTO
																					?.universityDepartment,
																				id: event
																					.target
																					.value,
																			},
																	},
															},
														});
													}}
													disabled={
														!context.universityDepartmentList
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityDepartmentList &&
														context
															.universityDepartmentList
															.length > 0 &&
														context.universityDepartmentList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Sınıf</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityClass &&
														context.modalData
															.educationInfoDTO
															.universityClass
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityClass:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="PRE">
														Hazırlık
													</option>
													<option value="ONE">
														1.Sınıf
													</option>
													<option value="TWO">
														2.Sınıf
													</option>
													<option value="THREE">
														3.Sınıf
													</option>
													<option value="FOUR">
														4.Sınıf
													</option>
													<option value="FIVE">
														5.Sınıf
													</option>
													<option value="SIX">
														6.Sınıf
													</option>
													<option value="GRADUATE">
														Mezun
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Başlangıç Yılı
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityStartDate &&
														context.modalData
															.educationInfoDTO
															?.universityStartDate
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityStartDate:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Üniversite Şehri
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityCity
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityCity.id
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityCity:
																			{
																				...context
																					?.modalData
																					?.educationInfoDTO
																					?.universityCity,
																				id: event
																					.target
																					.value,
																			},
																	},
															},
														});
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{dataProvinces?.length >
														0 &&
														dataProvinces.map(
															(province) => (
																<option
																	key={
																		province.id &&
																		province.id
																	}
																	value={
																		province.id &&
																		province.id
																	}
																>
																	{province.name &&
																		province.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Kaldığı Yer
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.educationInfoDTO
															?.accommodationStatus &&
														context.modalData
															.educationInfoDTO
															.accommodationStatus
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		accommodationStatus:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="HOME">
														Ev
													</option>
													<option value="FAMILY">
														Aile
													</option>
													<option value="RELATIVE">
														Akraba
													</option>
													<option value="GOVDORM">
														Devlet Yurdu
													</option>
													<option value="PRIVDORM">
														Özel Yurt
													</option>
													<option value="UNIDORM">
														Üniversite Yurdu
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Transkript Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-5' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-5'
												? null
												: 'group-5'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										Transkript Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-5"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-5' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									{/* Genel */}
									<div className="mb-5">
										<h5 className="text-blue-light mb-4">
											Genel
										</h5>
										<div className="mb-3">
											<div className="row align-items-center">
												<div className="col-4">
													<h6 className="mb-0">
														Genel Ortalama
													</h6>
												</div>
												<div className="col-8">
													<input
														type="text"
														className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
														placeholder="Lütfen giriniz"
														value={
															context?.modalData
																?.transcriptDTO
																?.endOfYearAverage &&
															context.modalData
																.transcriptDTO
																.endOfYearAverage
														}
														onChange={(event) =>
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																	transcriptDTO:
																		{
																			...context
																				?.modalData
																				?.transcriptDTO,
																			endOfYearAverage:
																				event
																					.target
																					.value,
																		},
																},
															})
														}
													/>
												</div>
											</div>
										</div>
										<div className="mb-3">
											<div className="row align-items-center">
												<div className="col-4">
													<h6 className="mb-0">
														Genel Kalan Ders
													</h6>
												</div>
												<div className="col-8">
													<input
														type="text"
														className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
														placeholder="Lütfen giriniz"
														value={
															context?.modalData
																?.transcriptDTO
																?.endOfYearFailedLesson &&
															context.modalData
																.transcriptDTO
																.endOfYearFailedLesson
														}
														onChange={(event) =>
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																	transcriptDTO:
																		{
																			...context
																				?.modalData
																				?.transcriptDTO,
																			endOfYearFailedLesson:
																				event
																					.target
																					.value,
																		},
																},
															})
														}
													/>
												</div>
											</div>
										</div>
									</div>

									{/* Hazırlık */}
									{[
										'PRE',
										'ONE',
										'TWO',
										'THREE',
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												Hazırlık
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Hazırlık Okudu mu?
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.preparetoryInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.preparetoryInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				preparetoryInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																Evet
															</option>
															<option value="NO">
																Hayır
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Hazırlıktan Geçti
															mi?
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.preparetoryResultInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.preparetoryResultInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				preparetoryResultInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																Evet
															</option>
															<option value="NO">
																Hayır
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Hazırlık Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="text"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.preparetoryAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.preparetoryAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				preparetoryAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 1. Sınıf */}
									{[
										'ONE',
										'TWO',
										'THREE',
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												1. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															min={0}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.firstTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				firstTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.firstTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.secondTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.secondTermFailedLesson
															}
															onChange={(
																event
															) => {
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																});
															}}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.firstSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.firstSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.firstSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.firstYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.firstYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.firstYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				firstYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 2. Sınıf */}
									{[
										'TWO',
										'THREE',
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												2. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				thirdTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.secondSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.secondSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.secondSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.secondYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.secondYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.secondYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				secondYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 3. Sınıf */}
									{[
										'THREE',
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												3. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				fifthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.thirdYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.thirdYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				thirdYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 4. Sınıf */}
									{[
										'FOUR',
										'FIVE',
										'SIX',
										'GRADUATE',
									].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												4. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.seventhTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.seventhTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				seventhTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.seventhTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.seventhTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				seventhTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.eighthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.eighthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				eighthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.eighthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.eighthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				eighthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fourthYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fourthYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fourthYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 5. Sınıf */}
									{['FIVE', 'SIX', 'GRADUATE'].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div className="mb-5">
											<h5 className="text-blue-light mb-4">
												5. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.ninethTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.ninethTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				ninethTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.ninethTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.ninethTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				ninethTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.tenthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.tenthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				tenthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.tenthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.tenthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				tenthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.fifthYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.fifthYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				fifthYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* 6. Sınıf */}
									{['SIX', 'GRADUATE'].includes(
										context?.modalData?.educationInfoDTO
											?.universityClass
									) && (
										<div>
											<h5 className="text-blue-light mb-4">
												6. Sınıf
											</h5>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.eleventhTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.eleventhTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...(context
																					?.modalData
																					?.transcriptDTO &&
																					context
																						.modalData
																						.transcriptDTO),
																				eleventhTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Güz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.eleventhTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.eleventhTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				eleventhTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.twelfthTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.twelfthTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				twelfthTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Bahar Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.twelfthTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.twelfthTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				twelfthTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
															Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthSummerTermAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthSummerTermAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthSummerTermAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi
														</h6>
													</div>
													<div className="col-8">
														<select
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthSummerInfo &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthSummerInfo
															}
															className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthSummerInfo:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														>
															<option value="">
																Lütfen seçiniz
															</option>
															<option value="YES">
																EVET
															</option>
															<option value="NO">
																HAYIR
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yaz Dönemi Kalan
															Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthSummerTermFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthSummerTermFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthSummerTermFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Ortalaması
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthYearAverage &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthYearAverage
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthYearAverage:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="row align-items-center">
													<div className="col-4">
														<h6 className="mb-0">
															Yıl Sonu Kalan Ders
														</h6>
													</div>
													<div className="col-8">
														<input
															type="number"
															onWheel={(e) =>
																e.target.blur()
															}
															className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
															placeholder="Lütfen giriniz"
															value={
																context
																	?.modalData
																	?.transcriptDTO
																	?.sixthYearFailedLesson &&
																context
																	.modalData
																	.transcriptDTO
																	.sixthYearFailedLesson
															}
															onChange={(event) =>
																setContext({
																	...context,
																	modalData: {
																		...context?.modalData,
																		transcriptDTO:
																			{
																				...context
																					?.modalData
																					?.transcriptDTO,
																				sixthYearFailedLesson:
																					event
																						.target
																						.value,
																			},
																	},
																})
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>

						{/* Belgeler */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-6' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-6'
												? null
												: 'group-6'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										Belgeler
									</h5>
								</button>
							</h2>
							<div
								id="group-6"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-6' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<span className="fs-7 text-red mb-4 d-block">
										Dosyalar PDF formatında ve en fazla 4MB
										boyutunda olmalıdır.
									</span>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Öğrenci Belgesi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="file"
													accept="application/pdf"
													name="NewCertificateImage"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																},
																NewCertificateImage:
																	event.target
																		.files[0],
															});
														}
													}}
												/>
												{context?.modalData
													?.certificateImage &&
													!context.NewCertificateImage && (
														<a
															href={`https://${context.modalData.certificateImage}`}
															target="_blank"
															rel="noreferrer"
															className="my-3 text-decoration-none fs-7 text-green d-block"
														>
															Öğrenci Belgesi{' '}
															<i
																className="fas fa-check-circle ms-2"
																style={{
																	width: 16,
																}}
															/>
														</a>
													)}
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Transkript Belgesi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="file"
													accept="application/pdf"
													name="NewTranscriptImage"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																},
																NewTranscriptImage:
																	event.target
																		.files[0],
															});
														}
													}}
												/>
												{context?.modalData
													?.transcriptImage &&
													!context.NewTranscriptImage && (
														<a
															href={`https://${context.modalData.transcriptImage}`}
															target="_blank"
															rel="noreferrer"
															className="my-3 text-decoration-none fs-7 text-green d-block"
														>
															Transkript Belgesi{' '}
															<i
																className="fas fa-check-circle ms-2"
																style={{
																	width: 16,
																}}
															/>
														</a>
													)}
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													STK Belgesi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="file"
													accept="application/pdf"
													name="NewStkImage"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														if (
															event?.target
																?.files?.[0]
														) {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																},
																NewStkImage:
																	event.target
																		.files[0],
															});
														}
													}}
												/>
												{context?.modalData?.stkImage &&
													!context.NewStkImage && (
														<a
															href={`https://${context.modalData.stkImage}`}
															target="_blank"
															rel="noreferrer"
															className="my-3 text-decoration-none fs-7 text-green d-block"
														>
															STK Belgesi{' '}
															<i
																className="fas fa-check-circle ms-2"
																style={{
																	width: 16,
																}}
															/>
														</a>
													)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İlgi Alanları */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-7' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-7'
												? null
												: 'group-7'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										İlgi Alanları
									</h5>
								</button>
							</h2>
							<div
								id="group-7"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-7' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-12">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.interest &&
														context.modalData
															.interest
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																interest:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
				{userRole === 'mentor' && (
					<>
						{/* Kişisel Bilgiler  */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-1' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-1'
												? null
												: 'group-1'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										Kişisel Bilgiler
									</h5>
								</button>
							</h2>
							<div
								id="group-1"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-1' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">İsim</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.name &&
														context.modalData.name
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																name: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Soyisim
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.surname &&
														context.modalData
															.surname
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																surname:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">TCKN</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.identityNo &&
														context.modalData
															.identityNo
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																identityNo:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Doğum Tarihi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="date"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.birthday &&
														context.modalData
															.birthday
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																birthday:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Cinsiyet
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.gender &&
														context.modalData.gender
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																gender: event
																	.target
																	.value,
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="MAN">
														Erkek
													</option>
													<option value="WOMAN">
														Kadın
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İletişim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-2' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-2'
												? null
												: 'group-2'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										İletişim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-2"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-2' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Telefon Numarası
												</h6>
											</div>
											<div className="col-8">
												<input
													type="tel"
													minLength={10}
													maxLength={11}
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.phoneNumber &&
														context.modalData
															.phoneNumber
													}
													onChange={(event) => {
														if (
															event.target.value.startsWith(
																'0'
															)
														) {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																	phoneNumber:
																		event
																			.target
																			.value,
																},
															});
														} else {
															setContext({
																...context,
																modalData: {
																	...context?.modalData,
																	phoneNumber: `0${event.target.value}`,
																},
															});
														}
													}}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													E-Posta Adresi
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.email &&
														context.modalData.email
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																email: event
																	.target
																	.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Bulunduğu İl
												</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.mentorCity?.id &&
														context.modalData
															.mentorCity.id
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																mentorCity: {
																	...context
																		?.modalData
																		?.mentorCity,
																	id: event
																		.target
																		.value,
																},
															},
														});
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{dataProvinces?.length >
														0 &&
														dataProvinces.map(
															(province) => (
																<option
																	key={
																		province.id &&
																		province.id
																	}
																	value={
																		province.id &&
																		province.id
																	}
																>
																	{province.name &&
																		province.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Eğitim Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-3' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-3'
												? null
												: 'group-3'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										Eğitim Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-3"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-3' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Lise Adı
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.highSchoolName &&
														context.modalData
															.educationInfoDTO
															.highSchoolName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		highSchoolName:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Üniversite Adı
												</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityDTO
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityDTO.id
													}
													onChange={(event) => {
														handleGetAllUniversityFaculty(
															event,
															event.target.value
														);
													}}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityList &&
														context.universityList
															.length > 0 &&
														context.universityList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Fakülte
												</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.faculty?.id &&
														context.modalData
															.educationInfoDTO
															.faculty.id
													}
													onChange={(event) => {
														handleGetAllUniversityDepartment(
															event,
															event.target.value
														);
													}}
													disabled={
														!context.universityFacultyList
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityFacultyList &&
														context
															.universityFacultyList
															.length > 0 &&
														context.universityFacultyList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Bölüm</h6>
											</div>
											<div className="col-8">
												<select
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													value={
														context?.modalData
															?.educationInfoDTO
															?.universityDepartment
															?.id &&
														context.modalData
															.educationInfoDTO
															.universityDepartment
															.id
													}
													onChange={(event) => {
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																educationInfoDTO:
																	{
																		...context
																			?.modalData
																			?.educationInfoDTO,
																		universityDepartment:
																			{
																				...context
																					?.modalData
																					?.educationInfoDTO
																					?.universityDepartment,
																				id: event
																					.target
																					.value,
																			},
																	},
															},
														});
													}}
													disabled={
														!context.universityDepartmentList
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													{context.universityDepartmentList &&
														context
															.universityDepartmentList
															.length > 0 &&
														context.universityDepartmentList.map(
															(university) => (
																<option
																	key={
																		university.id &&
																		university.id
																	}
																	value={
																		university.id &&
																		university.id
																	}
																>
																	{university.name &&
																		university.name}
																</option>
															)
														)}
												</select>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Yüksek Lisans
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.degree &&
														context.modalData
															.educationInfoDTO
															.degree
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																educationInfoDTO:
																	{
																		...context
																			.modalData
																			.educationInfoDTO,
																		degree: event
																			.target
																			.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Doktora
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.educationInfoDTO
															?.doctorate &&
														context.modalData
															.educationInfoDTO
															.doctorate
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																educationInfoDTO:
																	{
																		...context
																			.modalData
																			.educationInfoDTO,
																		doctorate:
																			event
																				.target
																				.value,
																	},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Çalışma Bilgileri */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-4' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-4'
												? null
												: 'group-4'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										Çalışma Bilgileri
									</h5>
								</button>
							</h2>
							<div
								id="group-4"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-4' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Çalıştığı Firma
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.jobInfoDTO
															?.firmName &&
														context.modalData
															.jobInfoDTO.firmName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																jobInfoDTO: {
																	...context
																		.modalData
																		.jobInfoDTO,
																	firmName:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">Sektör</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.jobInfoDTO
															?.sectorName &&
														context.modalData
															.jobInfoDTO
															.sectorName
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																jobInfoDTO: {
																	...context
																		.modalData
																		.jobInfoDTO,
																	sectorName:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													İş Ünvanı
												</h6>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.jobInfoDTO
															?.jobTitle &&
														context.modalData
															.jobInfoDTO.jobTitle
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																jobInfoDTO: {
																	...context
																		.modalData
																		.jobInfoDTO,
																	jobTitle:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-4">
												<h6 className="mb-0">
													Geçmiş İş Bilgisi
												</h6>
											</div>
											<div className="col-8">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.jobInfoDTO
															?.jobPastInfo &&
														context.modalData
															.jobInfoDTO
															.jobPastInfo
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																jobInfoDTO: {
																	...context
																		.modalData
																		.jobInfoDTO,
																	jobPastInfo:
																		event
																			.target
																			.value,
																},
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* İlgi Alanları */}
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className={`accordion-button bg-transparent p-4 ${
										activeAccordion !== 'group-5' &&
										'collapsed'
									}`}
									type="button"
									onClick={() => {
										setActiveAccordion(
											activeAccordion === 'group-5'
												? null
												: 'group-5'
										);
									}}
								>
									<h5 className="text-orange mb-0">
										İlgi Alanları
									</h5>
								</button>
							</h2>
							<div
								id="group-5"
								className={`accordion-collapse collapse ${
									activeAccordion === 'group-5' && 'show'
								}`}
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row align-items-center">
											<div className="col-12">
												<textarea
													rows="4"
													className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
													placeholder="Lütfen giriniz"
													value={
														context?.modalData
															?.interest &&
														context.modalData
															.interest
													}
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context?.modalData,
																interest:
																	event.target
																		.value,
															},
														})
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* eslint-disable */}
						{/* Diğer Bilgiler */}
						{/* <div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button collapsed bg-transparent p-4"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#group-6"
								>
									<h5 className="text-orange mb-0">
										Diğer Bilgiler
									</h5>
								</button>
							</h2>
							<div
								id="group-6"
								className="accordion-collapse collapse"
							>
								<div className="accordion-body p-4">
									<div className="mb-3">
										<div className="row">
											<div className="col-4">
												<h6 className="mb-0">Durum</h6>
											</div>
											<div className="col-8">
												<select
													value={
														context?.modalData
															?.mentorStatus &&
														context.modalData
															.mentorStatus
													}
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
													onChange={(event) =>
														setContext({
															...context,
															modalData: {
																...context.modalData,
																mentorStatus:
																	event.target
																		.value,
															},
														})
													}
												>
													<option value="">
														Lütfen seçiniz
													</option>
													<option value="ACTIVE">
														Aktif
													</option>
													<option value="PASSIVE">
														Pasif
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
						{/* eslint-enable */}
					</>
				)}
			</div>

			{context?.modalData?.cardStatus !== 'WAITINGAPPROVAL' && (
				<div className="text-center mt-5">
					<button
						type="button"
						className="btn btn-orange text-white rounded-pill px-5 py-3"
						onClick={(event) => handleSaveClick(event)}
					>
						Güncelle <i className="fas fa-arrow-right ms-2" />
					</button>
				</div>
			)}

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</section>
	);
};

const Profile = () => {
	const [context] = React.useContext(Context);
	const { userRole } = context.dataLogin;

	return (
		<main>
			<div className="container">
				<div className="row justify-content-center gy-5">
					<div className="col-xl-6 col-lg-8">
						<div className="pe-xl-0 pe-lg-5 pe-0">
							<ProfileTitle />
							<ProfileSectionInfo />
						</div>
					</div>

					<div className="col-xl-3 col-lg-4 offset-xl-1 offset-0">
						<div>{userRole === 'mentee' && <WidgetPrograms />}</div>
						<div>
							{userRole === 'mentee' && <WidgetMentor />}
							{userRole === 'mentor' && <WidgetMentees />}
						</div>
					</div>
				</div>

				<WidgetCTA />
			</div>
		</main>
	);
};

export default Profile;
