import React from 'react';
import $ from 'jquery';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Context from '../../stores/Context/Context';
import constants from '../../constants';

const Header = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [showMenu, setShowMenu] = React.useState(false);
	const [showNotifications, setShowNotifications] = React.useState(false);
	const [notifications, setNotifications] = React.useState({});
	const [notificationsCount, setNotificationsCount] = React.useState(0);
	const { userRole } = context.dataLogin;
	const navigate = useNavigate();
	const location = useLocation();

	const getNotifications = () => {
		fetch(`${constants.API_BASE_URL}/notifications`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setNotifications(response.data);
					let count = 0;
					response.data.forEach((notification) => {
						if (notification.unread) {
							count += 1;
						}
					});
					setNotificationsCount(count);
				}
			});
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault();
		const { search } = Object.fromEntries(new FormData(event.target));
		navigate('/egitimler', { state: { s: search } });
	};

	const handleNotificationsClick = () => {
		setShowNotifications(true);
		fetch(`${constants.API_BASE_URL}/notifications/set/read`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then(() => {
				setTimeout(() => {
					getNotifications();
				}, 3000);
			});
	};

	React.useEffect(() => {
		/* eslint-disable */
		const header = 'header';
		if ($(header).length) {
			$(window).scroll(() => {
				const y = $(window).scrollTop();
				if (y > 0) {
					$(header).addClass('scrolled');
				} else {
					$(header).removeClass('scrolled');
				}
			});
		}
		/* eslint-enable */

		document.addEventListener('mousedown', () => {
			setShowNotifications(false);
		});
	}, []);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		getNotifications();
		setShowNotifications(false);
	}, [location]);

	return (
		<header
			className={
				useLocation().pathname === '/'
					? 'sticky-top bg-white'
					: 'sticky-top bg-white shadow-lg mb-7'
			}
		>
			<nav className="navbar navbar-expand-xl py-0">
				<div className="container py-xl-4 py-3 d-flex justify-content-start">
					<NavLink to="/" className="navbar-logo">
						<img
							src={require('../../assets/images/logo-jarvis.png')}
							alt=""
							style={{ width: 80, height: 'auto' }}
						/>
					</NavLink>

					<button
						className={`navbar-toggler order-xl-3 ms-auto ${
							!showMenu && 'collapsed'
						}`}
						type="button"
						onClick={() => setShowMenu(!showMenu)}
					>
						<span />
						<span />
						<span />
					</button>

					<div
						className={`collapse navbar-collapse ${
							showMenu && 'show'
						}`}
					>
						<ul className="navbar-nav gap-xxl-5 gap-4 py-xl-0 py-4 pb-2 mx-xl-5 mx-0 mt-xl-0 mt-3">
							<li className="nav-item">
								<NavLink
									to="/profil"
									className="nav-link text-gray fw-medium p-0"
								>
									<i className="fas fa-user me-2 d-inline-block d-xl-none d-xxl-inline-block" />
									Profil
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									to="/takvim"
									className="nav-link text-gray fw-medium p-0"
								>
									<i className="fas fa-calendar-alt me-2 d-inline-block d-xl-none d-xxl-inline-block" />
									Takvim
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									to="/egitimler"
									className="nav-link text-gray fw-medium p-0"
								>
									<i className="fas fa-book-open me-2 d-inline-block d-xl-none d-xxl-inline-block" />
									Etkinlikler
								</NavLink>
							</li>
							{userRole === 'mentor' && (
								<li className="nav-item">
									<NavLink
										to="/ogrenciler"
										className="nav-link text-gray fw-medium p-0"
									>
										<i className="fas fa-users me-2 d-inline-block d-xl-none d-xxl-inline-block" />
										Kurumsal Üyeler
									</NavLink>
								</li>
							)}
							<li className="nav-item">
								<a
									href="/assets/content/Program-Rehberi.pdf"
									target="_blank"
									className="nav-link text-gray fw-medium p-0"
								>
									<i className="fas fa-book me-2 d-inline-block d-xl-none d-xxl-inline-block" />
									Program Rehberi
								</a>
							</li>
							<li className="nav-item">
								<NavLink
									to="/iletisim"
									className="nav-link text-gray fw-medium p-0"
								>
									<i className="fas fa-at me-2 d-inline-block d-xl-none d-xxl-inline-block" />
									İletişim
								</NavLink>
							</li>
						</ul>

						<div className="ms-auto mt-xl-0 mt-3 d-xl-flex d-block align-items-center">
							<div className="position-relative d-xl-block d-none me-4">
								<button
									type="button"
									className="btn p-0 text-gray text-decoration-none position-relative"
									onClick={() => {
										handleNotificationsClick();
									}}
									onBlur={() => {
										setShowNotifications(false);
									}}
								>
									<i className="far fa-bell fs-5" />
									{notificationsCount > 0 && (
										<span
											className="rounded-circle px-2 lh-lg bg-red text-white position-absolute top-0 start-100 translate-middle fs-9"
											style={{ zIndex: 100 }}
										>
											{notificationsCount}
										</span>
									)}
								</button>
								{showNotifications && (
									<div
										className="position-absolute top-0 end-0 p-4 bg-white shadow-lg rounded rounded-2 fs-7"
										style={{ width: 300 }}
									>
										<h6 className="text-blue-light">
											Bildirimler
										</h6>
										{notifications ? (
											notifications.map(
												(notification, index) => (
													<div
														key={notification.id}
														className={`py-3 text-gray ${
															index + 1 !==
																notifications.length &&
															'border-bottom border-2 border-gray-lighter'
														} ${
															notification.unread &&
															'fw-bold'
														}`}
													>
														{notification.body}
													</div>
												)
											)
										) : (
											<div>Hiç bildirim yok.</div>
										)}
									</div>
								)}
							</div>

							<div className="position-relative">
								<form
									/* eslint-disable */
									onSubmit={(event) =>
										handleSearchSubmit(event)
									}
									/* eslint-enable */
								>
									<input
										type="text"
										name="search"
										placeholder="Eğitim Ara"
										className="form-control border-0 rounded-pill bg-gray-lightest fs-7 py-2 px-4 lh-lg"
									/>
									<button
										type="submit"
										className="btn p-0 rounded-0 border-0 position-absolute top-50 end-0 me-2 translate-middle"
									>
										<i className="fas fa-search" />
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</header>
	);
};

export default Header;
