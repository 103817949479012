import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import constants from '../../constants';

import FooterGeneric from '../../layouts/FooterGeneric/FooterGeneric';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';
import Modal from '../../components/Modal/Modal';

const Register = () => {
	const [state, setState] = React.useState({ policyChecked: false });
	const navigate = useNavigate();
	const [phoneNumber, setPhoneNumber] = React.useState(null);

	const handleFormSubmit = (event) => {
		event.preventDefault();

		if (!state.policyChecked) {
			setState({
				...state,
				showLoading: false,
				toast: {
					type: 'error',
					message:
						'Üye olabilmek için lütfen kullanım koşullarını, gizlilik politikasını, çerez politikasını ve aydınlatma metnini onaylayın.',
				},
			});
			return;
		}

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/createUser`, {
			method: 'POST',
			headers: constants.API_HEADERS_PUBLIC,
			body: JSON.stringify(
				Object.fromEntries(new FormData(event.target))
			),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					event.target.reset();
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message:
								'Yeni üye başvurunuz aldındı. Başvuru sonucunuz girmiş olduğunuz e-posta adresinize en kısa sürede iletilecektir. 10 saniye içinde giriş sayfasına yönlendirileceksiniz.',
						},
					});
					setTimeout(() => {
						navigate('/giris');
					}, 10000);
				} else {
					event.target.reset();
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.description
								? response.description
								: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<>
			<main>
				<div className="py-md-8 py-4">
					<div className="container">
						<NavLink
							to="/giris"
							className="d-inline-block text-orange mb-4"
						>
							<i className="fas fa-arrow-left me-3" />
							Giriş
						</NavLink>

						<div className="row justify-content-center mb-8">
							<div className="col-xxl-4 col-lg-6 col-md-8 text-center">
								<h1 className="text-blue-light mb-3">
									Yeni Üye Ol
								</h1>
								<p className="h5 lh-base mb-0 fw-normal text-gray-light">
									F4Future platformuna üye olabilmek için
									lütfen için gerekli alanları doldurun.
								</p>
							</div>
						</div>

						<form onSubmit={(event) => handleFormSubmit(event)}>
							<div className="row justify-content-center">
								<div className="col-lg-6">
									<div className="row g-4 mb-8">
										<div className="col-6">
											<div className="border border-2 border-gray-lighter rounded-2 p-4 text-center">
												<label
													className="form-check-label"
													htmlFor="userTypeMentee"
													style={{
														cursor: 'pointer',
													}}
												>
													<i className="fas fa-user-graduate fs-1 mb-3" />
												</label>
												<h5 className="d-flex justify-content-center align-items-center">
													<input
														id="userTypeMentee"
														className="form-check-input mt-0 me-2"
														type="radio"
														name="userType"
														value="MENTEE"
														required
														defaultChecked
													/>{' '}
													<label
														className="form-check-label"
														htmlFor="userTypeMentee"
														style={{
															cursor: 'pointer',
														}}
													>
														Öğrenci
													</label>
												</h5>
											</div>
										</div>
										<div className="col-6">
											<div className="border border-2 border-gray-lighter rounded-2 p-4 text-center">
												<label
													className="form-check-label"
													htmlFor="userTypeMentor"
													style={{
														cursor: 'pointer',
													}}
												>
													<i className="fas fa-user-check fs-1 mb-3" />
												</label>
												<h5 className="d-flex justify-content-center align-items-center">
													<input
														id="userTypeMentor"
														className="form-check-input mt-0 me-2"
														type="radio"
														name="userType"
														required
														value="MENTOR"
													/>{' '}
													<label
														className="form-check-label"
														htmlFor="userTypeMentor"
														style={{
															cursor: 'pointer',
														}}
													>
														Mentör
													</label>
												</h5>
											</div>
										</div>
									</div>

									<div className="row g-4">
										<h5 className="text-orange mb-4 mt-0">
											Üyelik Bilgileri
										</h5>

										<div className="col-sm-6">
											<label className="form-label fw-medium">
												İsim *
											</label>
											<input
												type="text"
												name="name"
												className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
												required
												maxLength={50}
											/>
										</div>
										<div className="col-sm-6">
											<label className="form-label fw-medium">
												Soyisim *
											</label>
											<input
												type="text"
												name="surname"
												className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
												required
												maxLength={50}
											/>
										</div>
										<div className="col-sm-6">
											<label className="form-label fw-medium">
												E-Posta *
											</label>
											<input
												type="email"
												name="email"
												className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
												required
												maxLength={50}
											/>
										</div>
										<div className="col-sm-6">
											<label className="form-label fw-medium">
												Telefon Numarası *
											</label>
											<input
												type="tel"
												name="phoneNumber"
												className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
												required
												minLength={10}
												maxLength={11}
												value={phoneNumber}
												onChange={(event) => {
													if (
														event.target.value.startsWith(
															'0'
														)
													) {
														setPhoneNumber(
															event.target.value
														);
													} else {
														setPhoneNumber(
															`0${event.target.value}`
														);
													}
												}}
											/>
										</div>
										<div className="col-sm-6">
											<label className="form-label fw-medium">
												Doğum Tarihi *
											</label>
											<input
												type="date"
												name="birthday"
												className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
												required
											/>
										</div>

										<div className="col-12 mt-5">
											<div className="form-check">
												<input
													name="termsCheck"
													value="true"
													id="policyChecked"
													className="form-check-input"
													type="checkbox"
													required
													checked={
														state.policyChecked
													}
													onChange={() => {
														setState({
															...state,
															policyChecked:
																!state.policyChecked,
														});
													}}
												/>
												<label
													className="form-check-label fs-7 text-gray-light"
													htmlFor="policyChecked"
													style={{
														cursor: 'pointer',
													}}
												>
													Giriş yaparak{' '}
													<a
														href="#"
														className="text-reset"
														onClick={(event) => {
															event.preventDefault();
															setState({
																...state,
																showModal1: true,
															});
														}}
													>
														Kullanım Koşulları ve
														Gizlilik Politikasını
													</a>
													,{' '}
													<a
														href="#"
														className="text-reset"
														onClick={(event) => {
															event.preventDefault();
															setState({
																...state,
																showModal2: true,
															});
														}}
													>
														Çerez Politikasını
													</a>{' '}
													ve{' '}
													<a
														href="#"
														className="text-reset"
														onClick={(event) => {
															event.preventDefault();
															setState({
																...state,
																showModal3: true,
															});
														}}
													>
														KVKK Aydınlatma Metnini
													</a>{' '}
													onaylıyorum.
												</label>
											</div>
										</div>
										<div className="col-12 mt-5 text-center">
											<button
												type="submit"
												className="btn btn-orange text-white rounded-pill px-4 py-2"
											>
												Gönder{' '}
												<i className="fas fa-arrow-right ms-2" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>

				<Toast
					showToast={state.toast}
					onClose={() =>
						setState({
							...state,
							toast: false,
						})
					}
					type={state.toast?.type}
					message={state.toast?.message}
				/>
				<Loading showLoading={state.showLoading} />
			</main>

			<Modal
				showModal={state.showModal1}
				onClose={() =>
					setState({
						...state,
						showModal1: false,
					})
				}
				header="Kullanım Koşulları"
				size="lg"
			>
				<div className="modal-body p-4">
					<h5 className="my-4">Giriş</h5>
					<p>
						Jarvis Vakfı (“Jarvis Vakfı/Vakıf”), toplumsal
						sorumluluk ve duyarlılık bilinciyle hareket ederek bu
						anlayışın yayılmasını sağlamaya çalışmaktadır.{' '}
					</p>
					<p>
						Jarvis Vakfı, eğitim ve öğretim döneminde olan öncelikli
						olarak yetim ve öksüz olmak üzere her yaştan çocuk, genç
						ve yetişkinlerin akılcı, bağımsız düşünebilen çağdaş,
						araştırmacı bireyler olarak yetişmesine katkıda
						bulunmak, iyi bir eğitim almalarını ve meslek sahibi
						olmalarını sağlamak, maddi imkansızlıklar sebebiyle
						okuyamayan kişileri destekleyerek, eğitim ve
						öğretimlerine devam edebilmeleri için gerekli maddi
						imkanları sağlamak için faaliyetlerini sürdürmektedir.{' '}
					</p>
					<p>
						Bu kapsamda yürüttüğümüz faaliyetleri etkili bir şekilde
						sürdürebilmek, daha geniş kitlelere ulaşabilmek ve
						Vakfımızın tanıtım faaliyetlerine yürütebilmek amacıyla
						… ibareli internet adresi (“İnternet Sitesi”) üzerinden
						içerikler üretilmektedir.{' '}
					</p>
					<p>
						İnternet Sitemizde yer alan içeriklere eriştiğinizde
						Jarvis Vakfı ile bağlayıcı bir sözleşme akdetmiş
						oluyorsunuz.{' '}
					</p>
					<p>
						İşbu sözleşme Vakfın Kullanım Koşullarını, Gizlilik ve
						Kişisel Verilerin Korunması Politikasını, Çerez
						Politikasını ve Sözleşmelerin Bütünlüğü maddesinde
						belirtildiği üzere her türlü ek sözleşmeleri ve şartları
						(“Sözleşmeler”) içerir. Sözleşmelerin şartlarını
						incelemek isterseniz, güncel ve geçerli versiyonunu
						İnternet Sitemizde bulabilirsiniz.
					</p>
					<p>Lütfen sözleşmeleri dikkatlice okuyunuz.</p>
					<p>
						İnternet Sitemizi kullanmak ve herhangi bir içeriğe
						erişmek için, (i) 18 yaşından büyük olmanız ve/veya
						bizimle bağlayıcı bir sözleşme akdetme yetkisine sahip
						olmanız ve (ii) İnternet Sitemizi kullanmanıza yönelik
						daha önceden size herhangi bir kısıtlama yapılmamış
						olması gerekmektedir. Ayrıca, Vakıf ile iletişime
						geçtiğiniz sırada Vakfa gönderdiğiniz her türlü bilginin
						gerçek, doğru, eksiksiz ve güncel olduğunu ve bu
						bilgileri her zaman bu şekilde tutmayı kabul, beyan ve
						taahhüt etmektesiniz.
					</p>
					<p>
						Bu kapsamda Jarvis Vakfı, kullanıcılar tarafından
						iletilen, değiştirilen veya sağlanan bilgi ve
						içeriklerin doğruluğunu araştırma, bu bilgi ve
						içeriklerin güvenli, doğru ve hukuka uygun olduğunu
						taahhüt ve garanti etmekle yükümlü ve sorumlu olmadığı
						gibi, söz konusu bilgi ve içeriklerin yanlış veya hatalı
						olmasından dolayı ortaya çıkacak hiçbir zarardan da
						sorumlu tutulamayacaktır.
					</p>
					<h5 className="my-4">İnternet Sitesinin Kullanımı</h5>
					<p>
						İnternet Sitemizde yer alan içerikleri herhangi bir
						üyelik gerektirmeden erişebilmeniz mümkündür.{' '}
					</p>
					<p>
						Jarvis Vakfı, burs verenler, mentorlar ve bursiyerler
						hukuken bağımsız taraflardır. Aralarında ortaklık,
						temsilcilik veya işçi-işveren ilişkisi bulunmamaktadır.
						Jarvis Vakfı Kullanım Koşullarının onaylanması ve
						uygulanması sonucunda, ortaklık, temsilcilik veya
						işçi-işveren ilişkisi doğmaz.
					</p>

					<h5 className="my-4">Fikri ve Sınai Haklar</h5>
					<p>
						İnternet Sitemizde bulunan bilgiler, yazılar, yayınlar,
						resimler, markalar, slogan ve diğer işaretler ile sair
						fikri ve sınaî mülkiyet haklarına ilişkin bilgilerin
						korunmasına yönelik programlarla, sayfa düzeni ve
						platformun sunumu Jarvis Vakfı’nın ve Jarvis’in
						mülkiyetindedir.
					</p>
					<p>
						İşbu İnternet Sitesindeki bilgilerin ya da İnternet
						Sitesi sayfalarına ilişkin her tür veri tabanı, web
						sitesi, software-code'lar, html kodu ve diğer kodlar vs.
						ile platform içeriğinde bulunan tasarımların,
						resimlerin, metinlerin, görsel, işitsel imgelerin, video
						kliplerin, dosyaların, katalogların ve listelerin kısmen
						ya da tamamen kopyalanması, değiştirilmesi,
						yayınlanması, online ya da diğer bir medya kullanılmak
						suretiyle gönderimi, dağıtımı, satılması yasaktır.
					</p>
					<p>
						Dolayısıyla yukarıda sayılan ve bunlarla sınırlı olmayan
						İnternet Sitesi yazılım, donanım ve içeriğini
						çoğaltmayacağınızı, kopyalamayacağınızı,
						dağıtmayacağınızı, işlemeyeceğinizi gerek bu
						eylemleriniz ile gerekse de başka yollarla Jarvis
						Vakfı’nın haklarına doğrudan ve/veya dolaylı olarak
						zarar vermeyeceğinizi kabul ve taahhüt etmektesiniz.
					</p>
					<p>
						Vakıf hizmetlerini, bilgilerini ve Vakfın telif
						haklarına tâbi çalışmalarını yeniden satma, işleme,
						paylaşma, dağıtma, sergileme veya başkasının Vakfın
						hizmetlerine erişmesi veya kullanmasına izin verme
						hakkınız bulunmamaktadır.
					</p>
					<p>
						İnternet Sitemizde yer alan bilgilerin kısmen
						kopyalanması, basılması, işlenmesi, dağıtılması,
						çoğaltılması, sergilenmesi ancak ticari olmayan kişisel
						ihtiyaçlar için ve Jarvis Vakfı’nın yazılı izni ile
						mümkündür.
					</p>

					<h5 className="my-4">Kişisel Verilerin Korunması</h5>
					<p>
						Vakıf, platform üzerinden kullanıcılar tarafından
						kendisine iletilen bilgileri Gizlilik ve Kişisel
						Verilerin Korunması Politikası hükümleri doğrultusunda
						kullanabilir, bu bilgileri işbu Kullanım Koşulları ve
						6698 sayılı Kişisel Verilerin Korunması Kanunu’na uygun
						olarak işleyebilir, bir veri tabanı üzerinde tasnif edip
						muhafaza edebilir. Kişisel bilgileriniz, kanunla yetkili
						kılınan mercilerin talebi ve Gizlilik ve Kişisel
						Verilerin Korunması Politikasında sayılan haller hariç
						olmak üzere gerçek ve tüzel üçüncü kişilere
						açıklanmayacaktır. Detaylı bilgi için Gizlilik ve
						Kişisel Verilerin Korunması Politikasını incelemenizi
						rica ederiz.
					</p>
					<p>
						Burs verenler/iş ortaklarımız/mentorlarımız; hizmet
						kapsamında her ne şekilde olursa olsun elde edeceği
						bursiyer ve/veya bursiyer adaylarına, öğrencilere ait
						kişisel verileri, 6698 sayılı Kişisel Verilerin
						Korunması başta olmak üzere ilgili diğer tüm mevzuatlar
						ile belirlenen tüm ilke, kural ve şartlar ile amaç ve
						vasıtalara uygun olarak işleyecek olup veri güvenliğine
						ilişkin tüm önlemleri almak, söz konusu verilerin
						gizliliğini sağlamak için gizlilik esaslarına uygun
						hareket etmek, işbu bilgilerin yetkisiz kişilerce
						kullanımını önlemek ve her türlü suistimalden korumak
						için her türlü önlemi almakla yükümlüdür. Burs
						verenler/iş ortaklarımız/mentorlarımızın bu yükümlülüğü
						süresiz olarak devam edecektir. Söz konusu
						yükümlülüklerden, burs verenler ve/veya iş
						ortaklarımızın münhasıran sorumlu olup Vakfın hiçbir
						sorumluluğu bulunmamaktadır.
					</p>
					<p>
						İşbu Kullanım Koşullarının ve kişisel verilerin
						korunmasına ilişkin mevzuatlar ile getirilen
						yükümlülüğün ihlali halinde doğabilecek her türlü
						hukuki, idari ve cezai sorumluluk burs verenler/iş
						ortaklarımız/mentorlarımıza ait olup bu yükümlülüklerin
						ihlalinden doğacak her türlü zararın tazmini de yine tek
						başına burs verenler/iş ortaklarımız/mentorlarımıza
						aittir.
					</p>
					<p>
						Burs verenler/iş ortaklarımız/mentorlarımız, yukarıda
						ifade edilen yükümlülüklerine aykırı hareket etmesi
						durumunda, de facto veri sorumlusu sıfatını haiz olacak
						ve ortaya çıkabilecek hukuki, cezai ve idari
						sorumlulukların veri sorumlusu olarak, tek başına bizzat
						kendisi muhatabı olacaktır.
					</p>

					<h5 className="my-4">Jarvis Vakfı Hizmetleri</h5>
					<p>
						Vakıf, mevcut bir hizmetini değiştirebilir, kapsamını
						genişletebilir veya daraltabilir, askıya alabilir veya
						sonlandırabilir; yeni hizmetler ekleyebilir veya kendi
						takdir yetkisinde olmak üzere değiştirip düzenleyebilir.
						Yasaların izin verdiği ölçüde, bu değişiklikler size
						bildirimde bulunulduğu andan itibaren geçerlidir.
					</p>
					<p>
						Vakıf bir depolama hizmeti sunmamaktadır. Yürürlükteki
						yasaların gerektirdiği ve Gizlilik ve Kişisel Verilerin
						Korunması Politikasında not edilen durumlar hariç olmak
						üzere, sizin veya diğer kullanıcıların sunduğu herhangi
						bir bilgi veya içeriği depolama, muhafaza etme veya size
						bir kopyasını sunma yükümlülüğümüzün bulunmadığını kabul
						etmektesiniz.
					</p>

					<h5 className="my-4">Bildirimler ve Masajlar </h5>
					<p>
						İnternet Sitemizi kullanarak bize bildirdiğiniz iletişim
						kanallarından (email, cep telefonu, adres vb.), amaçla
						bağlantılı ve sınırlı olarak, bildirim ve mesaj
						gönderebileceğimizi kabul etmektesiniz.
					</p>

					<h5 className="my-4">Sağladığınız Bilgiler </h5>
					<p>
						Vakfımızın sunduğu hizmetler kapsamında sağlamış
						olduğunuz bilgi ve içerikler diğer burs verenler/iş
						ortaklarımız/mentorlarımız ile paylaşılabilir. Sağlamış
						olduğunuz içeriğin kısmen veya tamamen kopyalanması
						durumlarında Vakfın ve Jarvis'in herhangi bir hukuki
						sorumluluğu bulunmayacağını peşinen onaylarsınız.
					</p>
					<p>
						Vakıf ile paylaştığınız bilgi ve içeriklerden sadece siz
						sorumlusunuz. Vakfın bu bilgilerin doğruluğunu kontrol
						etmek gibi bir yükümlülüğü bulunmamaktadır. Sağlamış
						olduğunuz bilgilerin yanlışlığının sonradan anlaşılması
						halinde burs kesilmesi vb. durumlar ile karşılaşmanız
						gibi neticelerden dolayı Vakfın herhangi bir sorumluluğu
						bulunmadığını, Vakfa bu bağlamda herhangi bir
						alacak/tazminat talebi yöneltilemeyeceğini kabul
						etmektesiniz.{' '}
					</p>
					<p>
						Bursiyer adaylarının burs başvurusunda bulunması işbu
						başvurunun mutlaka olumlu sonuçlanacağı anlamına
						gelmemektedir.{' '}
					</p>

					<h5 className="my-4">Sözleşmenin Bütünlüğü </h5>
					<p>
						Bu maddede belirtilen ya da Vakıf ile aranızda yazılı
						olarak açıkça anlaşılan durumlar haricinde, sözleşmeler
						Vakıf ile sizin aranızda kabul edilen tüm şartları ve
						koşulları teşkil eder ve bu sözleşmelerin konusuna
						ilişkin olarak önceden var olan yazılı veya sözlü tüm
						sözleşmelerin yerini alır. Gizlilik ve Kişisel Verilerin
						Korunması Politikası ve Çerez Politikası vb. bu
						Sözleşmelerin kapsamındadır.
					</p>

					<h5 className="my-4">
						Sorumluluk Reddi ve Yükümlülüğün Sınırı
					</h5>
					<p>
						Vakıf ve Jarvis Holding, hizmetlerin kesintisiz ve
						hatasız olması taahhüdü dahil olmak üzere bu hizmetlerle
						ilgili herhangi bir taahhüt ve garanti sunmaz ve
						hizmetleri (içerik ve bilgiler ile birlikte) “olduğu
						gibi” ve “kullanılabilir olduğu müddetçe” sağlar. Vakıf
						ve Jarvis, yürürlükteki kanunların izin verdiği ölçüde,
						zımni mülkiyet teminatı, verilerin doğruluğu, belirli
						bir amaç için ihlal durumu, ticaret veya uygunluk dahil
						olmak üzere zımni veya kanuni tüm garantileri
						reddetmektedir.
					</p>
					<p>
						Yasaların izin verdiği ölçüde (ve Vakıf işbu sözleşmeyi
						geçersiz kılacak yazılı farklı bir anlaşma
						akdetmedikçe), Vakıf ve Jarvis (ve hizmetleri sunmak
						için Vakfın çalıştığı kişiler) hizmetlerle ilgili olarak
						size veya diğerlerine karşı dolaylı, arızi, özel, sonuç
						olarak ortaya çıkan veya cezai hasar ya da herhangi bir
						veri, fırsat, itibar, kâr veya gelir için (örn. rahatsız
						edici veya alçaltıcı beyanlar, kişisel bilgilerinizin
						kullanımı veya bunlar üzerindeki değişiklikler) sorumlu
						olmayacaktır.
					</p>

					<h5 className="my-4">
						Uygulanacak Hukuk ve Uyuşmazlıkların Çözümü
					</h5>
					<p>
						İşbu kullanım koşullarının yorumunda ve/veya işbu
						kullanım koşulları sebebiyle ortaya çıkacak olan her
						türlü uyuşmazlığın çözümünde, -herhangi bir şekilde
						yabancılık unsuru dahil olsa dahi Kanunlar İhtilafı
						Kurallarına gidilmeksizin-, doğrudan Türk Hukuku
						uygulanacaktır.{' '}
					</p>
					<p>
						İşbu kullanım koşullarından doğan her türlü uyuşmazlıkta
						İstanbul (Çağlayan) Mahkemeleri ve İcra Daireleri
						yetkili olacaktır.{' '}
					</p>

					<h5 className="my-4">Yürürlük</h5>
					<p>
						Kullanıcının, okudum/anladım opsiyonunu işaretlemesi
						halinde kullanım koşullarında yer alan tüm maddeleri
						okuduğu ve kullanım koşullarında yer alan maddeleri
						kabul ettiği anlamına gelir. Vakıf dilediği zaman işbu
						kullanım koşullarındaki hükümlerde değişikliğe
						gidebilir, değişiklikler, sürüm numarası ve değişiklik
						tarihi belirtilerek site üzerinden yayınlanır ve aynı
						tarihte yürürlüğe girer.
					</p>
				</div>
			</Modal>

			<Modal
				showModal={state.showModal2}
				onClose={() =>
					setState({
						...state,
						showModal2: false,
					})
				}
				header="Çerez Politikası"
				size="lg"
			>
				<div className="modal-body p-4">
					<h5 className="my-4">GİRİŞ</h5>
					<p>
						Aydınlı İstanbul AYOSB Mahallesi 2. Sanayi Cadde No: 10
						Tuzla İstanbul/Türkiye adresinde mukim Jarvis Vakfı
						(“Vakıf/Jarvis Vakfı” veya “biz”) olarak, internet
						sitemizin (www.jarvis.com.tr) düzgün çalışmasını
						sağlamak ve ziyaretçilerimize (“kullanıcı(lar)” veya
						“siz”) en alakalı hizmetleri sunmak amacıyla cihazlarına
						birtakım çerezler yerleştirmekteyiz.{' '}
					</p>
					<p>
						İşbu Çerez Politikası, size çerezler ve çerezlerin nasıl
						kontrol edileceği hakkında bilgi vermek amacıyla
						hazırlanmıştır.
					</p>

					<h5 className="my-4">ÇEREZ NEDİR?</h5>
					<p>
						Çerezler, internet sitemizi ziyaret ettiğinizde
						bilgisayarınıza ya da mobil cihazınıza kaydedilen küçük
						metin dosyalarıdır. Bu metin dosyaları ile beraber web
						işaretçileri, pikseller veya etiketler gibi diğer
						takipçiler de işbu Çerez Politikası kapsamında çerez
						olarak kabul edilecektir.
					</p>
					<p>
						Çerezler aracılığıyla, internet sitesini ziyaret
						ettiğiniz sırada gerçekleştirdiğiniz tercihler, internet
						sitesi sunucusu aracılığıyla saklanmaktadır. Böylelikle,
						aynı ziyaretçi sitemizi tekrar ziyaret ettiğinde sunucu
						bunu anlayabilecektir.
					</p>

					<h5 className="my-4">ÜÇÜNCÜ TARAF ÇEREZLER</h5>
					<p>
						Ziyaret ettiğiniz web sitesinin erişim adresindeki alan
						adı tarafından oluşturulan çerezler birincil
						çerezlerdir. Ziyaret edilen sitede sunulan içerik erişim
						adresindeki alan adı dışında başka alan adları üzerinden
						de sağlanıyor olabilir (örneğin bir video platformunda
						barındırılan içeriğin sosyal medya sitesi üzerinden
						görüntülenmesi); bu durumda her bir alan adı kendine
						münhasır üçüncü taraf çerezler yaratabilir.
					</p>

					<h5 className="my-4">ÇEREZLERİ NEDEN KULLANIYORUZ?</h5>
					<p>Çerezleri aşağıdaki amaçlarla kullanmaktayız:</p>
					<ul>
						<li>Sitenin güvenli bir şekilde çalışabilmesi, </li>
						<li>
							Kullanıcıların site üzerinde hareket edebilmesi ve
							sağlanan hizmet ve özelliklerden faydalanabilmesi,
						</li>
						<li>
							Kullanıcılar (kullanıcı aygıtları veya tarayıcı
							önbellekleri üzerinde) ve web site kullanımları
							hakkında bilgileri saklamak,
						</li>
						<li>
							Kullanıcıların site üzerindeki tercihleri üzerinden
							sitenin işlevli hale getirilmesi ve sitenin
							kullanıcılara uygun şekilde kişiselleştirilmesi,
						</li>
						<li>
							Kullanıcıların site kullanımlarının analiz edilmesi
							ve site üzerindeki genel eğiliminin tespit edilmesi,
						</li>
						<li>
							Web sitesinin performansını arttırmak, sitelerin
							kullanımını daha kolay ve/veya hızlı hale getirmek,
						</li>
						s<li>Web siteleri üzerinden yeni özellikler sunmak.</li>
						<li>
							Kullanıcıların ilgi alanlarına ve/veya profillerine
							göre hedefli reklamların gösterilmesi ve diğer
							pazarlama faaliyetlerinin yürütülmesi.
						</li>
					</ul>

					<h5 className="my-4">ÇEREZLERİN GENEL ÖZELLİKLERİ</h5>
					<p>
						Çerezler, sitemizde oturum çerezleri ve kalıcı çerezler
						olarak işlev göstermektedir. Oturum çerezleri,
						tarayıcınızı kapatmanız ile birlikte çalışmayı
						durdurmaktadır. Kalıcı çerezler ise hard diskinizde uzun
						süre boyunca kalabilmektedir.
					</p>

					<h5 className="my-4">KULLANILAN ÇEREZLERİN TÜRLERİ</h5>
					<p>
						Sitemizde kullanılan çerezlerin türlerini aşağıda
						listelenmiştir. Bu çerezler aracılığıyla topladığımız
						verilerin kişisel veri niteliğinde olması halinde, işbu
						Çerez Politikası’nı tamamlar nitelikteki HASÇELİK
						Aydınlatma Metni uygulanacaktır. Konuya ilişkin daha
						fazla bilgiye sahip olmak için Aydınlatma Metnimizi
						inceleyebilirsiniz.
					</p>

					<h6 className="my-4">Gerekli Çerezler</h6>
					<p>
						Bu tür çerezler, internet sitemizin güvenli şekilde
						çalışabilmesi, kullanıcıların sitemiz üzerinde hareket
						edebilmesi ve sağlanan hizmetlerden ve özelliklerden
						faydalanabilmesi için kesinlikle gerekli olan
						çerezlerdir. Bu tür çerezler internet sitemizin
						çalışabilmesi için esastır ve kullanıcılar tarafından
						devre dışı bırakılamaz.
					</p>

					<table className="table my-6">
						<thead>
							<tr>
								<td>Çerez İşlevi</td>
								<td>Açıklama</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Oturum</td>
								<td>
									Oturum çerezleri oturumun sürekliliğinin
									sağlanması amacıyla kullanılır. Bu çerezler;
									ziyaretçilerin internet sitesini ziyaretleri
									süresince kullanılan, tarayıcı kapatıldıktan
									sonra silinen geçici çerezlerdir. Çevrimiçi
									kanalların düzgün çalışması için gereklidir.
									Bu tür çerezlerin kullanılmasının temel
									amacı, ziyaret süresince internet sitesinin
									kullanışlı olmasını, hatasız çalışması ve
									kullanıcıların birden fazla bilgi girmek
									zorunda kalmamasını sağlamaktır. Örneğin;
									tekrar eden formların doldurulmasını
									kolaylaştırmak.
								</td>
							</tr>
						</tbody>
					</table>

					<h6 className="my-4">Tercih ve İşlevsellik Çerezleri</h6>
					<p>
						Tercih çerezleri, kullanıcıların tercihlerine ilişkin
						bilgileri toplar ve dil veya kullanıcıların diğer yerel
						ayarlarını hatırlamamızı ve sitemizi kullanıcılara uygun
						şekilde kişiselleştirmemizi sağlar. Bu tür çerezler,
						tarafımızca veya sayfalarınıza hizmetlerini eklediğiniz
						üçüncü taraf sağlayıcılar tarafından koyulabilir.
						Kullanıcılar bu tür çerezleri devre dışı bıraktığı
						takdirde, bu işlevlerin bazıları veya tümü düzgün
						çalışamayabilir.
					</p>

					<table className="table my-6">
						<thead>
							<tr>
								<td>Çerez İşlevi</td>
								<td>Açıklama</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Dil</td>
								<td>
									Kullanıcının seçtiği dili kaydeder ve buna
									uygun seçenekler sunar.
								</td>
							</tr>
							<tr>
								<td>Aktif sekme</td>
								<td>
									Kullanıcının uçuş tablosunda hangi seçmeyi
									seçili bıraktığını tutar.
								</td>
							</tr>
							<tr>
								<td>Aktif tema</td>
								<td>
									Yöneticinin en son hangi temayı kullandığını
									tutar.
								</td>
							</tr>
						</tbody>
					</table>

					<h6 className="my-4">Analitik Çerezler</h6>
					<p>
						Kullanıcıların sitemizi nasıl kullandıklarını anlamak
						amacıyla analitik çerezler kullanmaktayız. Bu çerezler,
						toplu bir şekilde bilgi toplayarak sitemizin nasıl
						kullanıldığına dair bize fikir vermekte ve sitemizi
						geliştirmemize yardımcı olmaktadır. Örneğin, bu tip
						çerezler, site üzerinde en çok hangi sayfaların ziyaret
						edildiğini göstermekte ve site içinde yaşanılan
						zorlukların kaydedilmesine yardımcı olmaktadır.
					</p>

					<table className="table my-6">
						<thead>
							<tr>
								<td>Çerez İşlevi</td>
								<td>Açıklama</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Google analitikleri</td>
								<td>
									Bu tür çerezler tüm istatistiksel verilerin
									toplanmasını bu şekilde sitenin sunumunun ve
									kullanımının geliştirilmesini sağlar.
									Google, bu istatistiklere toplumsal
									istatistikler ve ilgilere ilişkin veriler
									eklemek suretiyle, kullanıcıları daha iyi
									anlamamızı sağlar.
								</td>
							</tr>
						</tbody>
					</table>

					<h6 className="my-4">Reklam/Pazarlama Çerezleri</h6>

					<p>
						Bu çerezleri, reklam ve pazarlama amaçları ile
						kullanmaktayız. Bu çerezler, kullanıcıların
						tarayıcılarını ve cihazlarını bir şekilde tanımlayarak
						çalışırlar. Kullanıcıların ilgi alanlarının profillerini
						oluşturmak ve kullanıcılara diğer sitelerde alakalı
						reklamlar göstermek amacıyla kullanılabilmekteyiz.
						Kullanıcılar, bu çerezlere izin vermedikleri takdirde,
						kullanıcılar hedefli reklamlar gösterilemeyecektir.
					</p>

					<table className="table my-6">
						<thead>
							<tr>
								<td>Çerez İşlevi</td>
								<td>Açıklama</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Reklam</td>
								<td>
									Kullanıcılara davranışsal ve hedef odaklı
									reklamların ulaştırılmasını sağlar.
								</td>
							</tr>
						</tbody>
					</table>

					<h5 className="my-4">
						ÇEREZLER NASIL KONTROL EDİLİR/DEVRE DIŞI BIRAKILIR?
					</h5>
					<p>
						Çerez tercihlerinizi, tarayıcı ayarlarınızı değiştirerek
						isteğinize göre uyarlayabilirsiniz. Tarayıcınızın sunmuş
						olduğu imkanlara göre, çerezlerin kullanılmasını
						engelleyebilir, çerez kullanılmadan önce uyarı alabilir
						veya sadece bazı çerezleri devre dışı bırakabilir ya da
						silebilirsiniz. Çerezlerle alakalı tercihler, sitemize
						erişim sağladığınız her bir cihaz (tarayıcı ayarları)
						özelinde ayrı olarak silinmesi gerekmektedir.
					</p>
					<p>
						Çerezleri nasıl etkinleştireceğiniz, devre dışı
						bırakacağınız ya da kaldıracağınıza ilişkin bilgiler,
						internet tarayıcısı sağlayıcısının sitesindeki Yardım
						ekranında yer almaktadır.
					</p>
					<ul>
						<li>
							Google Chrome:{' '}
							<a
								href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=tr"
								target="_blank"
								rel="noreferrer"
							>
								https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=tr
							</a>
						</li>
						<li>
							Mozilla:
							<a
								href="https://support.mozilla.org/tr/kb/%C3%87erezleri%20engellemek"
								target="_blank"
								rel="noreferrer"
							>
								https://support.mozilla.org/tr/kb/%C3%87erezleri%20engellemek
							</a>
						</li>
						<li>
							Internet Explorer:
							<a
								href="https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies"
								target="_blank"
								rel="noreferrer"
							>
								https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies
							</a>
						</li>
						<li>
							Safari:
							<a
								href="https://support.apple.com/kb/PH5042?locale=en-GB"
								target="_blank"
								rel="noreferrer"
							>
								https://support.apple.com/kb/PH5042?locale=en-GB
							</a>
						</li>
						<li>
							Opera:{' '}
							<a
								href="http://www.opera.com/help/tutorials/"
								target="_blank"
								rel="noreferrer"
							>
								http://www.opera.com/help/tutorials/
							</a>
						</li>
					</ul>
					<p>
						Bazı çerezleri devre dışı bırakmanız halinde sitemizin
						çeşitli fonksiyonlarının çalışmayabileceğini hatırlatmak
						isteriz.
					</p>
					<p>
						Eğer ziyaret ettiğiniz internet sitelerinden kalan
						çerezlerin hepsini temizlemek istiyorsanız, aşağıdaki
						linkleri kullanarak, izleme çerezlerinin silinmesini
						sağlayan bu üç programı indirebilirsiniz:
					</p>
					<ul>
						<li>
							<a
								href="http://www.spybot.info/en/download/index.html"
								target="_blank"
								rel="noreferrer"
							>
								http://www.spybot.info/en/download/index.html
							</a>
						</li>
						<li>
							<a
								href="http://www.webroot.com/consumer/products/spysweeper/"
								target="_blank"
								rel="noreferrer"
							>
								http://www.webroot.com/consumer/products/spysweeper/
							</a>
						</li>
					</ul>

					<h5 className="my-4">GİZLİLİK POLİTİKAMIZ</h5>
					<p>
						Müşterilerimizin güvenliği bizim için özel öneme sahip
						olup, tüm kişisel verileriniz tüm idari ve teknik
						tedbirler alınarak en güvenilir seviyede internet
						sitemizde korunmaktadır.{' '}
					</p>
					<p>
						Daha detaylı bilgi almak için, internet sitemizde yer
						alan “Kişisel Verilerin Korunması ve İşlenmesi Hakkında
						Politikası”na ulaşabilirsiniz.
					</p>

					<h5 className="my-4">BİZE ULAŞIN</h5>
					<p>
						Jarvis Vakfı, Çerez Politikası’nın hükümlerini dilediği
						zaman değiştirebilecektir. Yaptığımız değişikliklere
						ilişkin gerekli bilgilendirmeleri internet sitemiz
						www.jarvis.com.tr üzerinden veya bize bildirmiş
						olduğunuz e-posta adresiniz üzerinden sizlerle
						paylaşacağız.
					</p>
					<p>
						Her türlü soru, görüş ve önerileriniz için bizimle
						info@jarvis.com.tr üzerinden iletişime geçebilirsiniz.
					</p>
				</div>
			</Modal>

			<Modal
				showModal={state.showModal3}
				onClose={() =>
					setState({
						...state,
						showModal3: false,
					})
				}
				header="KVKK Aydınlatma Metni"
				size="lg"
			>
				<div className="modal-body p-4">
					<p>
						Hasan Jarvis Vakfı olarak; kişilerin özel hayatın
						gizliliği hakkı ve kişisel verilerin korun- ması hakkına
						oldukça önem vermekteyiz. İşbu Aydınlatma Metni,
						projelerimiz kapsamında bursiyerlerimiz, mentörlerimiz,
						gönüllülerimiz ve iş ortaklarımız başta olmak üzere
						ilgili kişilerin 6698 sayılı Kişisel Verilerin Korunması
						Kanunu (“Kanun/KVKK”) ve ilgili mevzuata uygun olarak
						kişisel verilerinin işlenmesini ve paylaşılmasını
						sağlamak, kişisel verilerinin işlenmesi faaliyetine
						ilişkin ilgili kişileri bilgilendirmek amacıyla
						hazırlanmıştır.
					</p>

					<h5 className="my-4">İşlediğimiz Kişisel Verileriniz</h5>
					<p>
						İşbu Aydınlatma Metni’nde belirtilen kişisel veri işleme
						amaçlarımızı gerçekleştirebilmek amacıyla, aşağıdaki
						kişisel verilerini işleyebilmekteyiz:
					</p>
					<h6 className="my-4">Bursiyerler</h6>
					<ul>
						<li>
							Ad, soyad, T.C. Kimlik numarası, imza, doğum yeri,
							doğum tarihi, nüfusa kayıtlı olduğu yer, cinsiyet,
							kimlik belgesinde veya ehliyet fotokopisinde yer
							alan diğer bilgiler dahil kimlik bilgileriniz,
						</li>
						<li>
							E-posta, telefon numarası, cep telefonu dahil
							iletişim bilgileriniz,
						</li>
						<li>
							İkamet bilgisi, adres bilgisi dahil lokasyon
							bilgileriniz,
						</li>
						<li>
							CV’ler (beraberinde gelen dernek ve vakıf üyeliği
							bilgileri), geçmiş eğitim ve meslek bilgileri,
							diploma bilgileri, gidilen kurslar, STK ve
							üniversite kulüplerine üyelik bilgileriniz dahil
							eğitim ve deneyim bilgileriniz,
						</li>
						<li>
							Banka hesap bilgileriniz dahil finansal
							bilgileriniz,
						</li>
						<li>
							Adli sicil kaydı bilgisi dahil ceza mahkumiyeti ve
							güvenlik tedbirleri bilgileriniz,
						</li>
						<li>
							Sağlık raporunda belirtilen bilgiler, engellilik
							durum bilgileri, kullanılan cihaz ve protez
							bilgileri dahil sağlık bilgileriniz,
						</li>
						<li>Aile yakını bilgileriniz,</li>
						<li>Hobiler ve ilgi alanları bilgileriniz.</li>
					</ul>

					<h6 className="my-4">Mentörler</h6>
					<ul>
						<li>
							Ad, soyad, doğum yeri, doğum tarihiniz dahil kimlik
							bilgileriniz,
						</li>
						<li>
							E-posta adresi, cep telefonu numarası dahil iletişim
							bilgileriniz,
						</li>
						<li>Adres bilgileriniz,</li>
						<li>
							Lise ve üniversite mezuniyet dahil eğitim
							bilgileriniz,
						</li>
						<li>
							Çalıştığınız sektör, şirket adı ve pozisyonunuz
							dahil mesleki bilgileriniz ve daha önce çalıştığınız
							sektör bilgileri dahil mesleki deneyim bilgileriniz,
						</li>
						<li>Mentörlük deneyimlerinize dair bilgiler,</li>
						<li>İlgi alanları bilgileriniz.</li>
					</ul>

					<h6 className="my-4">Bağışçılar</h6>
					<ul>
						<li>Ad, soyad dahil kimlik bilgileriniz,</li>
						<li>
							E-posta, telefon numarası dahil iletişim
							bilgileriniz,
						</li>
						<li>Bağış ve yardımlarınıza dair bilgiler.</li>
					</ul>

					<h6 className="my-4">Gönüller</h6>
					<ul>
						<li>
							Ad, soyad, doğum yeri, doğum tarihi dahil kimlik
							bilgileriniz,
						</li>
						<li>
							E-posta adresi, cep telefonu numarası dahil iletişim
							bilgileriniz,
						</li>
						<li>Adres bilgileriniz,</li>
						<li>
							Vakfımızla nasıl tanıştığınıza ve daha önceki
							gönüllülük deneyimlerinize dair bilgiler,
						</li>
						<li>
							Vakıfta yaptığınız gönüllülük faaliyetlerine dair
							bilgileriniz.{' '}
						</li>
					</ul>

					<h6 className="my-4">
						İş Ortakları (Okul Yöneticileri vs.) ve Tedarikçiler
					</h6>
					<ul>
						<li>Ad, soyad dahil kimlik bilgileriniz,</li>
						<li>
							E-posta adresi, telefon numarası dahil iletişim
							bilgileriniz.
						</li>
					</ul>

					<h5 className="my-4">
						Kişisel Verilerinizi İşleme Amaçlarımız{' '}
					</h5>
					<p>
						Bu kişisel verilerinizi, aşağıdaki amaçlar ile sınırlı
						olarak işlemekteyiz:
					</p>
					<ul>
						<li>
							Mezun Club üyelerimiz ve mentörlerimiz ile
							iletişiminizi sağlamak ve etkinlik ve
							organizasyonları düzenlemek,
						</li>
						<li>Burs ödeme sürecinin yürütülmesi,</li>
						<li>
							Bursiyerlerin staj, sosyo külterel ve eğitim
							faaliyetlerine katılması,
						</li>
						<li>
							Bursiyerlerin zorunlu staj yerlerini bulmalarına ve
							mezun olduklarında çalışmaları için işyeri
							bulmalarına destek olunması,
						</li>
						<li>Mentör Mentee programının yürütülmesi,</li>
						<li>
							İnternet üzerinden yapılan bağışların yönetilmesi,
						</li>
						<li>
							Panel ve etkinliklerin düzenlenmesine ilişkin iş
							ortakları ile iletişime geçilmesi,
						</li>
						<li>
							Vakfın çeşitli faaliyetlerinde destek hizmetlerin
							yapılması veya vakfın bilinirliğini arttırmak
							üzerine faaliyetlerin yürütülmesi,
						</li>
						<li>
							Vakıf faaliyetlerinde gönüllülerin yer almasının
							sağlanması ve gönüllülük sürecinin yürütülmesi,
						</li>
						<li>
							Acil durum yönetimi süreçlerinin yürütülmesi
							(yaşanabilecek acil durumlarda birimler ve kurumlar
							arasında iletişimin sağlanması gibi),
						</li>
						<li>
							Eğitim faaliyetlerinin yürütülmesi
							(bursiyerlerin/bursiyer adaylarının ilgili eğitim
							programlarına uygunluklarının tespit edilmesi gibi),
						</li>
						<li>
							Faaliyetlerin mevzuata uygun yürütülmesi (5737
							sayılı Vakıflar Kanunu gibi yasal düzenlemelere
							uyulması gibi),
						</li>
						<li>
							Finans ve muhasebe işlerinin yürütülmesi
							(masrafların ödenmesi, bağışların alınması gibi),
						</li>
						<li>
							Hukuk işlerinin takibi ve yürütülmesi (muhtemel
							davalarda kişilerin ve Vakfın kendini koruyabilmesi
							gibi),
						</li>
						<li>
							İletişim faaliyetlerinin yürütülmesi (e-posta
							gönderimi yapılması, telefonla iletişime geçilmesi,
							Vakfımızla ile ilgili e-bültenlerin ve haberlerin
							gönderilmesi ve duyurularının yapılması gibi),
						</li>
						<li>İş faaliyetlerinin yürütülmesi/denetimi,</li>
						<li>
							İş sürekliliğinin sağlanması faaliyetlerinin
							yürütülmesi (yürütülen programların yıllık olarak
							yenilenmesi gibi),
						</li>
						<li>
							Organizasyon ve etkinlik yönetimi (panel ve
							etkinliklerin düzenlenmesi, düzenlenen panel ve
							etkinliklerde konuşmaların yapılmması ve anketlerin
							yürütülmesi, bu etkinlik ve panellerdeki
							konuşmacıların ve katılımcıların fotoğraf ve
							videolarının çekilmesi ve sosyal medya
							hesaplarımızda paylaşılması, mühendislik
							mesleklerinin tanıtılması gibi),
						</li>
						<li>
							Sosyal sorumluluk ve sivil toplum aktivitelerinin
							yürütülmesi,
						</li>
						<li>
							Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi
							(resmi kurumlardan ve mahkemelerden gelen
							müzekkerelere/yazılara cevap verilmesi gibi).
						</li>
					</ul>
					<h5 className="my-4">
						Kişisel Verilerinizi Toplama Yöntemi ve Hukuki Sebebi
					</h5>
					<p>
						Kişisel verileriniz, e-posta ve internet sitemiz, CCTV,
						görüntülü video konferans gibi elektronik ortamlar
						üzerinden otomatik olan yollarla ve fiziki form, posta,
						kargo ya da kurye hizmetler, yüz yüze görüşmeler gibi
						otomatik olmayan yöntemlerle toplamaktayız.
					</p>
					<p>
						Kişisel verilerinizi, KVKK madde 5/2(c) uyarınca “Bir
						sözleşmenin kurulması veya ifasıyla doğrudan doğruya
						ilgili olması kaydıyla, sözleşmenin taraflarına ait
						kişisel verilerin işlenmesinin gerekli olması”, KVKK
						madde 5/2(ç) “Veri sorumlusunun hukuki yükümlülüğünü
						yerine getirebilmesi için zorunlu olması”, KVKK madde
						5(2)(e) uyarınca “bir hakkın tesisi, kullanılması veya
						korunması için veri işlemenin zorunlu olması” ve KVKK
						madde 5/2(f) uyarınca “ilgili kişinin temel hak ve
						özgürlüklerine zarar vermemek kaydıyla, veri
						sorumlusunun meşru menfaatleri için veri işlenmesinin
						zorunlu olması” sebeplerine dayanarak işlemekteyiz.
					</p>

					<h5 className="my-4">
						Kişisel Verilerinizin Yurt İçi ve Yurt Dışına
						Aktarılması ve Aktarılması
					</h5>
					<p>
						Kişisel verileriniz KVKK'nın 8. ve 9. maddesinde
						belirtilen veri aktarma ve işleme şartları ile yukarıda
						sayılan amaçların gerçekleştirilmesini sağlamak amacı
						ile mevzuatta belirlenen güvenlik ve gizlilik esasları
						uyarınca yeterli ve etkili önlemler alınmak kaydıyla;
						hukuken yetkili kamu kurumlarına, adli mercilere ve
						kolluk kuvvetlerine aktarılabilecektir.
					</p>
					<p>
						Kişisel verilerinizi, Kanun madde 5(2)(e) uyarınca “bir
						hakkın tesisi, kullanılması veya korunması için veri
						işlemenin zorunlu olması” ve Kanun madde 5/2(f) uyarınca
						“ilgili kişinin temel hak ve özgürlüklerine zarar
						vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
						için veri işlenmesinin zorunlu olması” sebeplerine
						dayanarak ve başvurularınızın Vakfa ulaşması amacıyla
						bursiyerler ile burs verenler arasında aracılık yapmakta
						olan ve işbirliği yapmakta olduğumuz firmalara
						aktarmaktayız.
					</p>
					<p>
						Sizlerle Mezun Club üyelerimiz arasında iletişimi
						sağlamak amacıyla ad-soyad ve iletişim bilgilerinizi
						Mezun Club üyelerimize ve Jarvis topluluk şirketlerine
						aktarabilmekteyiz.
					</p>
					<h5 className="my-4">İstisna Hükmü </h5>
					<p>
						Kişisel verilerinizin KVKK’nın 28 inci maddesinin (ç)
						bendinde belirtilen “milli savunmayı, milli güvenliği,
						kamu güvenliğini, kamu düzenini veya ekonomik güvenliği
						sağlamaya yönelik olarak kanunla görev ve yetki verilmiş
						kamu kurum ve kuruluşları tarafından yürütülen önleyici,
						koruyucu ve istihbari faaliyetler kapsamında
						işlenmesini” gerektiren durumlarından varlığı halinde
						(salgın hastalık, doğal afet, milli güvenliği tehdit
						edecek çalışan faaliyetlerinin tespiti vb.), Vakfımız
						yetkili kamu kurum ve kuruluşlarına gerekli kişisel veri
						aktarımlarını gerçekleştirmekle mükelleftir. Bu
						durumlarda, KVKK’nın 28 inci maddesinin istisna hükmü
						olması nedeniyle KVKK uygulama alanı bulmayacaktır.
						Ancak, her halükarda bu kapsamda gerçekleştirilecek veri
						işleme faaliyetlerinde de Jarvis Vakfı temel prensiplere
						ve ölçülülük ilkesine uygun hareket edecektir
					</p>
					<h5 className="my-4">
						Kişisel Verilerinizin Saklanması ve İmhası
					</h5>
					<p>
						Kişisel verileriniz Vakfımız tarafından, aksine yasal
						bir düzenleme olmadıkça, on (10) yıl süreyle muhafaza
						edilecektir.{' '}
					</p>
					<p>
						Bu sürelerin sona ermesi ve Vakfımız kişisel
						verilerinizi işlemek için herhangi bir hukuki sebebi
						kalmaması halinde kişisel verileriniz KVKK’nın ilgili
						hükümleri, “Kişisel Verilerin Silinmesi, Yok Edilmesi
						veya Anonim Hale Getirilmesi Hakkında Yönetmelik” ve
						Vakıf Politikalarımıza uygun şekilde derhal silinecek,
						yok edilecek veya anonim hale getirilecektir. Daha fazla
						bilgi için Kişisel Verileri Saklama ve İmha Politikamızı
						inceleyebilirsiniz.
					</p>

					<h5 className="my-4">
						Kişisel Verilerinize İlişkin Haklarınız
					</h5>
					<p>
						Kişisel verilerinize ilişkin aşağıdaki hususların tamamı
						hakkında her zaman bilgi talep edebilirsiniz:
					</p>
					<ul>
						<li>
							Kişisel verilerinizin işlenip işlenmediğini öğrenme,
						</li>
						<li>
							Kişisel verileriniz işlenmişse buna ilişkin bilgi
							talep etme,
						</li>
						<li>
							Kişisel verilerin işlenme amacını ve bunların
							amacına uygun kullanılıp kullanılmadığını öğ- renme,
						</li>
						<li>
							Yurt içinde veya yurt dışında kişisel verilerinizin
							aktarıldığı üçüncü kişileri bilme,
						</li>
						<li>
							Kişisel verilerinizin eksik veya yanlış işlenmiş
							olması halinde bunların düzeltilmesini isteme ve bu
							kapsamda yapılan işlemin kişisel verilerinizin
							aktarıldığı üçüncü kişilere bildirilmesini isteme,
						</li>
						<li>
							İşlenme şartlarının ortadan kalkması halinde kişisel
							verilerinizin silinmesini veya yok edilmesini isteme
							ve bu kapsamda yapılan işlemin kişisel verilerinizin
							aktarıldığı üçüncü kişilere bildiril- mesini isteme,
						</li>
						<li>
							İşlenen verilerin münhasıran otomatik sistemler
							vasıtasıyla analiz edilmesi suretiyle aleyhinize bir
							sonucun ortaya çıkmasına itiraz etme,
						</li>
						<li>
							Kişisel verilerin kanuna aykırı olarak işlenmesi
							sebebiyle zarara uğramanız halinde bu zararın
							giderilmesini talep etme.
						</li>
					</ul>
					<h5 className="my-4">
						Haklarınız Kapsamında Başvuru Yolları
					</h5>
					<p>
						Yukarıda sayılan haklarınıza ilişkin başvurularınızı
						Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında
						Tebliğ’de öngörülen başvuru usullerine uygun olarak
						yapabilirsiniz. KVKK kapsamındaki haklarınızı kullanmak
						ile ilgili talebinizi kimliğinizi tespit edecek belgeler
						ile birlikte;
					</p>
					<ul>
						<li>
							Şahsen başvuru yapılması halinde; yazılı başvuru,
							Vakfın İstanbul Anadolu Yakası Organize Sanayi
							Bölgesi 2. Sanayi No:10, Tuzla/İstanbul adresine,
							zarfın üzerine “Kişisel Verilerin Korunması Kanunu
							Kapsamında Bilgi Talebi” yazılması suretiyle
							yapılacaktır.
						</li>
						<li>
							Noter kanalı ile tebligat gönderilmesi yoluyla
							başvuru yapılması halinde; yazılı başvuru, Vakfın
							İstanbul Anadolu Yakası Organize Sanayi Bölgesi 2.
							Sanayi No:10, Tuzla/İstanbul adresine, zarfın
							üzerine “Kişisel Verilerin Korunması Kanunu
							Kapsamında Bilgi Talebi” yazılması suretiyle
							yapılacaktır.
						</li>
						<li>
							Güvenli elektronik imza ile imzalanarak Kayıtlı
							Elektronik Posta (KEP) yoluyla başvuru yapılması
							halinde; yazılı başvuru, info@jarvis.com.tr e-posta
							hesabına, e-postanın konu bölümüne “Kişisel
							Verilerin Korunması Kanunu Bilgi Talebi” yazılması
							suretiyle yapılacaktır.{' '}
						</li>
					</ul>
					<p>
						Ayrıca, Kurul’un belirleyeceği diğer yöntemler
						duyurulduktan sonra bu yöntemler üzerinden de
						başvuruların ne şekilde alınacağı Vakfımızca
						duyurulacaktır.
					</p>
				</div>
			</Modal>

			<FooterGeneric />
		</>
	);
};

export default Register;
