import React from 'react';

import { Tooltip } from 'bootstrap';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';
import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';

const AdminCourses = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		view: 'grid',
	});

	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/programs/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.content) {
					fetch(
						`${constants.API_BASE_URL}/admin/allActivitiesAdmin/?size=9999`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${context.dataLogin.token}`,
							},
						}
					)
						.then((response2) => response2.json())
						.then((response2) => {
							if (response2.content) {
								setState({
									...state,
									showLoading: false,
									courses: response2.content,
									programs: response.content,
								});
							} else {
								setState({
									...state,
									showLoading: false,
									programs: response.content,
								});
							}
						})
						.catch((error) => {
							setState({
								...state,
								toast: {
									type: 'error',
									message:
										error.message === 'Failed to fetch'
											? 'Sunucu ile bağlantı kurulamadı.'
											: error.message,
								},
								showLoading: false,
							});
						});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	React.useEffect(() => {
		/* eslint-disable */
		const tooltipTriggerList = document.querySelectorAll(
			'[data-bs-toggle="tooltip"]'
		);
		const tooltipList = [...tooltipTriggerList].map(
			(tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
		);
		/* eslint-enable */
	}, [state]);

	const handleSearch = (event) => {
		setState({
			...state,
			search: event.target.value,
		});
	};

	const handleEditClick = (item) => {
		if (item) {
			setState({
				...state,
				showModal: true,
				header: `#${item.id} Düzenle`,
				id: item.id,
				name: item.name,
				description: item.description,
				instructor: item.instructor,
				url: item.url,
				dateTimeBegin: item.dateTimeBegin.substring(0, 16),
				dateTimeEnd: item.dateTimeEnd.substring(0, 16),
				isMandatory: item.isMandatory,
				programDTOList: item.programDTOList,
			});
		} else {
			setState({
				...state,
				showModal: true,
				header: 'Yeni',
				name: '',
				description: '',
				instructor: '',
				url: '',
				dateTimeBegin: '',
				dateTimeEnd: '',
				isMandatory: false,
				programDTOList: [],
			});
		}
	};

	const handleSaveClick = () => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/activities/save`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				...(state.id && { id: state.id }),
				name: state.name,
				description: state.description,
				instructor: state.instructor,
				url: state.url,
				dateTimeBegin: `${state.dateTimeBegin}:00+03:00`,
				dateTimeEnd: `${state.dateTimeEnd}:00+03:00`,
				isMandatory: state.isMandatory,
				programDTOList: state.programDTOList,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'success',
							message: 'Eğitim kaydedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
					showModal: false,
				});
			});
	};

	const handleRemoveClick = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/activities/deactive/${id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Eğitim silindi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleViewClick = (event, type) => {
		event.preventDefault();

		setState({
			...state,
			view: type,
		});
	};

	return (
		<>
			<WidgetAdminTitleBar title="Etkinlikler" />

			<div className="row g-md-4 g-2 align-items-center mb-5">
				<div className="col-md-3">
					<div className="d-flex align-items-center">
						<label className="form-label fw-medium mb-0 me-3 fs-7">
							Arama
						</label>
						<input
							type="text"
							className="form-control border border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
							onChange={(event) => handleSearch(event)}
							value={state.search}
						/>
					</div>
				</div>
				<div className="col-auto ms-auto">
					<div className="d-flex align-items-center gap-3 justify-content-end">
						<a
							href="#"
							className={`d-md-block d-none ${
								state.view === 'grid'
									? 'text-orange'
									: 'text-gray-light'
							}`}
							onClick={(event) => handleViewClick(event, 'grid')}
						>
							<i className="fas fa-th-large" />
						</a>
						<a
							href="#"
							className={`d-md-block d-none ${
								state.view === 'list'
									? 'text-orange'
									: 'text-gray-light'
							}`}
							onClick={(event) => handleViewClick(event, 'list')}
						>
							<i className="fas fa-list-ul" />
						</a>

						<button
							type="button"
							className="btn btn-orange text-white rounded-pill px-4 py-2 ms-md-3 ms-0 flex-md-grow-0 flex-fill"
							onClick={() => handleEditClick()}
						>
							Yeni
							<i className="fas fa-plus ms-2" />
						</button>
					</div>
				</div>
			</div>

			{state.view === 'list' && (
				<div className="table-responsive">
					<table className="table table-striped">
						<thead>
							<tr className="fs-7">
								<th
									scope="col"
									className="align-middle text-gray text-center"
									width="60"
								>
									ID
								</th>
								<th
									scope="col"
									className="align-middle text-gray"
									width="300"
								>
									İsim & Açıklama
								</th>
								<th
									scope="col"
									className="align-middle text-gray text-center"
								>
									Tanımlı Katılımcı
								</th>
								<th
									scope="col"
									className="align-middle text-gray text-center"
								>
									Tarih & Saat
								</th>
								<th
									scope="col"
									className="align-middle text-gray text-center"
									style={{ width: 170 }}
								>
									İşlem
								</th>
							</tr>
						</thead>
						<tbody className="fs-7">
							{state.courses &&
								state.courses
									.filter(
										(item) =>
											(item.name + item.description)
												.toLowerCase()
												.search(
													state?.search?.toLowerCase()
												) > -1
									)
									.map((item) => (
										<tr key={item.id}>
											<td className="align-middle text-gray-light text-center fw-medium fs-8">
												{item.id}
											</td>
											<td className="align-middle text-gray">
												<div className="py-3">
													<h6 className="mb-2 text-blue-light">
														{item.name}
													</h6>
													<p className="mb-2">
														{item.description}
													</p>
													<p className="mb-2 fs-8">
														<a
															href={item.url}
															target="_blank"
															rel="noreferrer"
															className="text-blue-light"
														>
															{item?.url?.substring(
																0,
																40
															)}
															...
														</a>
													</p>
													<small>
														{item.instructor}
													</small>
												</div>
											</td>
											<td className="align-middle fw-medium text-gray text-center">
												{
													/* eslint-disable */
													item?.isMandatory ? (
														<span className="text-red ">
															Zorunlu
														</span>
													) : (
														<span className="text-green">
															Opsiyonel
														</span>
													)
													/* eslint-enable */
												}
											</td>
											<td className="align-middle text-gray fw-medium text-center">
												<p className="mb-1">
													{new Date(
														item.dateTimeBegin
													).toLocaleDateString(
														'tr-TR'
													)}
													<span className="mx-1">
														-
													</span>
													{new Date(
														item.dateTimeBegin
													)
														.toLocaleTimeString(
															'tr-TR'
														)
														.substring(0, 5)}
												</p>
												<p className="mb-0">
													{new Date(
														item.dateTimeEnd
													).toLocaleDateString(
														'tr-TR'
													)}
													<span className="mx-1">
														-
													</span>
													{new Date(item.dateTimeEnd)
														.toLocaleTimeString(
															'tr-TR'
														)
														.substring(0, 5)}
												</p>
											</td>
											<td className="align-middle text-gray text-center text-nowrap">
												<button
													type="button"
													className="btn border border-2 border-red text-red bg-white rounded-pill px-3 py-1 fs-8 me-1"
													onClick={() =>
														handleRemoveClick(
															item.id
														)
													}
												>
													Sil
												</button>
												<button
													type="button"
													className="btn border border-2 border-orange text-orange bg-white rounded-pill px-3 py-1 fs-8"
													onClick={() =>
														handleEditClick(item)
													}
												>
													Düzenle
												</button>
											</td>
										</tr>
									))}
						</tbody>
					</table>
				</div>
			)}

			{state.view === 'grid' && (
				<div className="row g-4">
					{state.courses &&
						state.courses
							.filter(
								(item) =>
									(item.name + item.description)
										.toLowerCase()
										.search(state?.search?.toLowerCase()) >
									-1
							)
							.map((item) => (
								<div
									className="col-xl-4 col-md-6"
									key={item.id}
								>
									<div className="d-block text-decoration-none text-reset h-100 rounded rounded-2 bg-gray-lightest overflow-hidden">
										<div
											className="position-relative bg-white d-flex justify-content-center align-items-center border border-2 border-gray-lightest rounded-top rounded-2"
											style={{
												height: 160,
											}}
										>
											<img
												src={require('../../assets/images/logo-jarvis.png')}
												alt=""
												style={{
													width: '40%',
												}}
											/>
										</div>
										<div className="p-4">
											<h5 className="mb-3 lh-base">
												{item.name}
											</h5>
											<p className="text-gray-light fs-7 mb-2">
												{item.description}
											</p>

											<p className="mb-2 fs-8">
												<a
													href={item?.url}
													target="_blank"
													rel="noreferrer"
													className="text-blue-light"
												>
													{item?.url?.substring(
														0,
														40
													)}
													...
												</a>
											</p>
											<p className="fs-8 mb-3">
												{item.instructor}
											</p>

											<div className="border-top border-2 d-flex justify-content-between pt-3 fs-7 fw-medium text-blue-light mb-2">
												<span>Başlangıç</span>
												<span>
													{new Date(
														item.dateTimeBegin
													).toLocaleDateString(
														'tr-TR'
													)}
													<span className="mx-1">
														-
													</span>
													{new Date(
														item.dateTimeBegin
													)
														.toLocaleTimeString(
															'tr-TR'
														)
														.substring(0, 5)}
												</span>
											</div>
											<div className="border-bottom border-2 d-flex justify-content-between pb-3 fs-7 fw-medium text-blue-light mb-3">
												<span>Bitiş</span>
												<span>
													{new Date(
														item.dateTimeEnd
													).toLocaleDateString(
														'tr-TR'
													)}
													<span className="mx-1">
														-
													</span>
													{new Date(item.dateTimeEnd)
														.toLocaleTimeString(
															'tr-TR'
														)
														.substring(0, 5)}
												</span>
											</div>
											<div className="d-flex align-items-center justify-content-between">
												<span className="fs-7 fw-medium text-green">
													{/* eslint-disable */}
													{item?.isMandatory ? (
														<span className="text-red">
															Zorunlu
														</span>
													) : (
														<span className="text-green">
															Opsiyonel
														</span>
													)}
												</span>
												{/* eslint-enable */}
												<div>
													<button
														type="button"
														className="btn border border-2 border-red text-red bg-white rounded-pill px-3 py-1 fs-8 me-1"
														onClick={() =>
															handleRemoveClick(
																item.id
															)
														}
													>
														Sil
													</button>
													<button
														type="button"
														className="btn border border-2 border-orange text-orange bg-white rounded-pill px-3 py-1 fs-8"
														onClick={() =>
															handleEditClick(
																item
															)
														}
													>
														Düzenle
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
				</div>
			)}

			<Modal
				showModal={state.showModal}
				onClose={() =>
					setState({
						...state,
						showModal: false,
					})
				}
				header={state.header}
			>
				<div className="modal-body p-4">
					<form>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									İsim
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 50 karakter uzunluğunda bir eğitim ismi giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="name"
									value={state.name}
									onChange={(event) => {
										setState({
											...state,
											name: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={50}
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Açıklama
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 50 karakter uzunluğunda bir eğitim açıklaması giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="description"
									value={state.description}
									onChange={(event) => {
										setState({
											...state,
											description: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={50}
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Eğitmen
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="instructor"
									value={state.instructor}
									onChange={(event) => {
										setState({
											...state,
											instructor: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									URL
								</label>
							</div>
							<div className="col-7">
								<input
									type="url"
									name="url"
									value={state.url}
									onChange={(event) => {
										setState({
											...state,
											url: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Başlangıç
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="dateTimeBegin"
									value={state.dateTimeBegin}
									onChange={(event) => {
										setState({
											...state,
											dateTimeBegin: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Bitiş
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="dateTimeEnd"
									value={state.dateTimeEnd}
									onChange={(event) => {
										setState({
											...state,
											dateTimeEnd: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Katılım
								</label>
							</div>
							<div className="col-7">
								<div className="form-check">
									<input
										type="checkbox"
										name="isMandatory"
										id="isMandatory"
										className="form-check-input"
										value={state.isMandatory}
										onChange={() => {
											setState({
												...state,
												isMandatory: !state.isMandatory,
											});
										}}
										checked={state.isMandatory}
									/>
									<label
										className="form-check-label"
										htmlFor="isMandatory"
									>
										Zorunlu
									</label>
								</div>
							</div>
						</div>
						{state.isMandatory && (
							<div className="row g-4 align-items-center mb-3">
								<div className="col-5">
									<label className="col-form-label fw-medium">
										Komiteler
									</label>
								</div>
								<div className="col-7">
									{state?.programs &&
										state.programs.map((item) => {
											let checked = false;
											state?.programDTOList?.forEach(
												(item2) => {
													if (item2.id === item.id) {
														checked = true;
													}
												}
											);

											return (
												<div
													className="form-check"
													key={item.id}
												>
													<input
														type="checkbox"
														name="program[]"
														id={`program-${item.id}`}
														className="form-check-input"
														onChange={() => {
															const programDTOList =
																state?.programDTOList
																	? state?.programDTOList
																	: [];
															const index =
																programDTOList.findIndex(
																	(program) =>
																		program.id ===
																		item.id
																);
															if (index > -1) {
																programDTOList.splice(
																	index,
																	1
																);
															} else {
																programDTOList.push(
																	{
																		id: item.id,
																	}
																);
															}
															setState({
																...state,
																programDTOList,
															});
														}}
														defaultChecked={checked}
													/>
													<label
														className="form-check-label"
														htmlFor={`program-${item.id}`}
													>
														{item.name}
													</label>
												</div>
											);
										})}
								</div>
							</div>
						)}
					</form>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() => handleSaveClick()}
					>
						Kaydet
					</button>
				</div>
			</Modal>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminCourses;
