import React from 'react';

import { Tooltip } from 'bootstrap';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';
import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';

const AdminNotifications = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});

	React.useEffect(() => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/getWarningAll/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.data?.content) {
					setState({
						...state,
						showLoading: false,
						notifications: response.data.content,
					});
				} else {
					setState({
						...state,
						showLoading: false,
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	}, []);

	React.useEffect(() => {
		/* eslint-disable */
		const tooltipTriggerList = document.querySelectorAll(
			'[data-bs-toggle="tooltip"]'
		);
		const tooltipList = [...tooltipTriggerList].map(
			(tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
		);
		/* eslint-enable */
	}, [state]);

	const handleSearch = (event) => {
		setState({
			...state,
			search: event.target.value,
		});
	};

	const handleEditClick = (item) => {
		if (item) {
			setState({
				...state,
				showModal: true,
				header: `#${item.id} Düzenle`,
				id: item.id,
				title: item.title,
				description: item.description,
				viewType: item.viewType,
			});
		} else {
			setState({
				...state,
				showModal: true,
				header: 'Yeni',
				title: '',
				description: '',
				viewType: 'ALL',
			});
		}
	};

	const handleSaveClick = () => {
		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/admin/createUpdateWarning/?size=9999`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
				body: JSON.stringify({
					...(state.id && { id: state.id }),
					title: state.title,
					description: state.description,
					viewType: state.viewType,
					dateTimeBegin: '1000-01-01T00:00:00+00:00',
					dateTimeEnd: '3000-01-01T00:00:00+00:00',
				}),
			}
		)
			.then((response) => response.json())
			.then((response) => {
				if (response.data) {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'success',
							message: 'Uyarı kaydedildi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
					showModal: false,
				});
			});
	};

	const handleRemoveClick = (id) => {
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/deleteWarning/${id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (!response.error) {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'success',
							message: 'Program silindi.',
						},
					});
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<>
			<WidgetAdminTitleBar title="Hatırlatmalar" />

			<div className="row g-md-4 g-2 align-items-center  mb-5">
				<div className="col-md-3">
					<div className="d-flex align-items-center">
						<label className="form-label fw-medium mb-0 me-3 fs-7">
							Arama
						</label>
						<input
							type="text"
							className="form-control border border-2 border-gray-lighter py-1 px-2 lh-sm fs-7"
							onChange={(event) => handleSearch(event)}
							value={state.search}
						/>
					</div>
				</div>
				<div className="col-auto ms-auto">
					<div className="d-flex align-items-center gap-3 justify-content-end">
						<button
							type="button"
							className="btn btn-orange text-white rounded-pill px-4 py-2 ms-md-3 ms-0 flex-md-grow-0 flex-fill"
							onClick={() => handleEditClick()}
						>
							Yeni
							<i className="fas fa-plus ms-2" />
						</button>
					</div>
				</div>
			</div>
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr className="fs-7">
							<th
								scope="col"
								className="align-middle text-gray text-center"
								width="60"
							>
								ID
							</th>
							<th scope="col" className="align-middle text-gray">
								Hedef Kitle
							</th>
							<th scope="col" className="align-middle text-gray">
								Başlık
							</th>
							<th scope="col" className="align-middle text-gray">
								Açıklama
							</th>
							<th
								scope="col"
								className="align-middle text-gray text-center"
								style={{ width: 170 }}
							>
								İşlem
							</th>
						</tr>
					</thead>
					<tbody className="fs-7">
						{state.notifications &&
							state.notifications
								.filter(
									(item) =>
										(item.title + item.description)
											.toLowerCase()
											.search(
												state?.search?.toLowerCase()
											) > -1
								)
								.map((item) => (
									<tr key={item.id}>
										<td className="align-middle text-gray-light text-center fw-medium fs-8">
											{item.id}
										</td>
										<td className="align-middle text-gray">
											{item.viewType === 'ALL' && 'Tümü'}
											{item.viewType === 'MENTEE' &&
												'Öğrenci'}
											{item.viewType === 'MENTOR' &&
												'Mentor'}
										</td>
										<td className="align-middle text-gray">
											{item.title}
										</td>
										<td className="align-middle text-gray">
											{item.description}
										</td>
										<td className="align-middle text-gray text-center">
											<button
												type="button"
												className="btn border border-2 border-red text-red bg-white rounded-pill px-3 py-1 fs-7 mx-1"
												onClick={() =>
													handleRemoveClick(item.id)
												}
											>
												Sil
											</button>
											<button
												type="button"
												className="btn border border-2 border-orange text-orange bg-white rounded-pill px-3 py-1 fs-7 mx-1"
												onClick={() =>
													handleEditClick(item)
												}
											>
												Düzenle
											</button>
										</td>
									</tr>
								))}
					</tbody>
				</table>
			</div>

			<Modal
				showModal={state.showModal}
				onClose={() =>
					setState({
						...state,
						showModal: false,
					})
				}
				header={state.header}
			>
				<div className="modal-body p-4">
					<form>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Başlık
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 50 karakter uzunluğunda bir uyarı başlığı giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="name"
									value={state.title}
									onChange={(event) => {
										setState({
											...state,
											title: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={50}
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Açıklama
									<i
										className="fas fa-question-circle ms-2 opacity-75"
										data-bs-toggle="tooltip"
										data-bs-title="En fazla 80 karakter uzunluğunda bir uyarı açıklaması giriniz"
									/>
								</label>
							</div>
							<div className="col-7">
								<input
									type="text"
									name="description"
									value={state.description}
									onChange={(event) => {
										setState({
											...state,
											description: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
									maxLength={80}
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Hedef Kitle
								</label>
							</div>
							<div className="col-7">
								<select
									name="viewType"
									className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg"
									value={state.viewType}
									onChange={(event) => {
										setState({
											...state,
											viewType: event.target.value,
										});
									}}
									required
								>
									<option value="ALL">Tümü</option>
									<option value="MENTEE">Öğrenci</option>
									<option value="MENTOR">Mentor</option>
								</select>
							</div>
						</div>
					</form>
				</div>
				<div className="modal-footer justify-content-center">
					<button
						type="button"
						className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						onClick={() => handleSaveClick()}
					>
						Kaydet
					</button>
				</div>
			</Modal>
			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminNotifications;
