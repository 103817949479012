import React from 'react';

import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';
import Modal from '../../components/Modal/Modal';

const FooterGeneric = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({});

	const [cookies, setCookies] = React.useState(null);
	const [terms, setTerms] = React.useState(null);
	const [kvkk, setKvkk] = React.useState(null);

	const GetCookies = () => {
		fetch(`${constants.API_BASE_URL}/public/getCookies`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response.data) {
					setCookies(response.data);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	const GetTerms = () => {
		fetch(`${constants.API_BASE_URL}/public/getTermsOfUse`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response.data) {
					setTerms(response.data);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	const GetKvkk = () => {
		fetch(`${constants.API_BASE_URL}/public/getKVKKText`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response.data) {
					setKvkk(response.data);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		GetCookies();
		GetTerms();
		GetKvkk();
	}, []);

	React.useEffect(() => {
		if (cookies && terms && kvkk) {
			setContext({
				...context,
				cookies,
				terms,
				kvkk,
			});
		}
	}, [cookies && terms && kvkk]);

	return (
		<footer className="border-top border-2 border-gray-lighter py-4 mt-8">
			<div className="container">
				<div className="row justify-content-between align-items-center gy-4">
					<div className="col-lg-4">
						<div className="d-flex align-items-center justify-content-center">
							<img
								src={require('../../assets/images/logo-jarvis.png')}
								alt=""
								style={{ width: 60 }}
							/>
							<p className="fs-7 text-gray-light ms-4 mb-0">
								<a
									href="#"
									target="_blank"
									className="text-gray text-decoration-none"
									rel="noreferrer"
								>
									Jarvis Vakfı
								</a>{' '}
								<br />© 2022 Tüm hakları saklıdır.
							</p>
						</div>
					</div>
					<div className="col-lg-4 fs-7 text-center">
						<a
							href="#"
							className="text-reset me-2 opacity-50"
							onClick={(event) => {
								event.preventDefault();
								setState({
									...state,
									showModal2: true,
								});
							}}
						>
							Çerez Politikası
						</a>
						<a
							href="#"
							className="text-reset me-2 opacity-50"
							onClick={(event) => {
								event.preventDefault();
								setState({
									...state,
									showModal3: true,
								});
							}}
						>
							KVKK Aydınlatma Metni
						</a>
						<a
							href="#"
							className="text-reset me-2 opacity-50"
							onClick={(event) => {
								event.preventDefault();
								setState({
									...state,
									showModal1: true,
								});
							}}
						>
							Kullanım Koşulları
						</a>
					</div>
					<div className="col-lg-4">
						<div className="d-flex justify-content-center align-items-center text-gray fs-7">
							<img
								src={require('../../assets/images/logo-jarvis.png')}
								alt=""
								className="me-2"
								style={{ width: 30 }}
							/>
							<a
								href="https://www.jarvis.com.tr/"
								target="_blank"
								rel="noreferrer"
								className="text-reset me-1"
							>
								Jarvis Yazılım A.Ş.
							</a>{' '}
							tarafından geliştirildi
						</div>
					</div>
				</div>
			</div>

			<Modal
				showModal={state.showModal1}
				onClose={() =>
					setState({
						...state,
						showModal1: false,
					})
				}
				header={context?.terms?.title}
				size="lg"
			>
				<div
					className="modal-body p-4"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: context?.terms?.description,
					}}
				/>
			</Modal>

			<Modal
				showModal={state.showModal2}
				onClose={() =>
					setState({
						...state,
						showModal2: false,
					})
				}
				header={context?.cookies?.title}
				size="lg"
			>
				<div
					className="modal-body p-4"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: context?.cookies?.description,
					}}
				/>
			</Modal>

			<Modal
				showModal={state.showModal3}
				onClose={() =>
					setState({
						...state,
						showModal3: false,
					})
				}
				header={context?.kvkk?.title}
				size="lg"
			>
				<div
					className="modal-body p-4"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: context?.kvkk?.description,
					}}
				/>
			</Modal>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</footer>
	);
};

export default FooterGeneric;
