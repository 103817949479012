// eslint-disable-next-line import/named
import { baseURL, surveyUrl, gtmCode } from '../config/api.config';

const constants = {
	API_BASE_URL: baseURL,
	API_SURVEY_URL: surveyUrl,
	API_HEADERS_PUBLIC: {
		'Content-Type': 'application/json',
	},
	ADMIN_USERS: [999],
	GTM_CODE: gtmCode,
};

export default constants;
