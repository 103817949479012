import React from 'react';
import { NavLink } from 'react-router-dom';

import Context from '../../stores/Context/Context';

const Footer = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const { userRole } = context.dataLogin;

	return (
		<>
			<footer className="border-top border-2 border-gray-lighter py-8 mt-8">
				<div className="container">
					<div className="row gx-4 gy-5">
						<div className="col-xl-4 col-md-3 col-6">
							<img
								src={require('../../assets/images/logo-jarvis.png')}
								alt=""
								className="mb-4"
								style={{ maxWidth: '100px' }}
							/>
							<p className="fs-7 text-gray-light mb-0">
								<a
									href="#"
									target="_blank"
									className="text-gray text-decoration-none"
									rel="noreferrer"
								>
									Jarvis Vakfı
								</a>{' '}
								<br />© 2022 Tüm hakları saklıdır.
							</p>
						</div>
						<div className="col-xl-2 col-md-3 col-6">
							<h6 className="mb-4">Portal</h6>
							<div className="d-grid gap-2">
								<NavLink
									to="/profil"
									className="text-decoration-none text-gray-light"
								>
									Profil
								</NavLink>
								<NavLink
									to="/takvim"
									className="text-decoration-none text-gray-light"
								>
									Takvim
								</NavLink>
								<NavLink
									to="/egitimler"
									className="text-decoration-none text-gray-light"
								>
									Etkinlikler
								</NavLink>
								{userRole === 'mentor' && (
									<NavLink
										to="/ogrenciler"
										className="text-decoration-none text-gray-light"
									>
										Kurumsal Üyeler
									</NavLink>
								)}
								<a
									href="/assets/content/Program-Rehberi.pdf"
									target="_blank"
									className="text-decoration-none text-gray-light"
								>
									Program Rehberi
								</a>
								<NavLink
									to="/iletisim"
									className="text-decoration-none text-gray-light"
								>
									İletişim
								</NavLink>
							</div>
						</div>
						<div className="col-xl-2 col-md-3 col-6">
							<h6 className="mb-4">Jarvis Vakfı</h6>
							<div className="d-grid gap-2">
								<a
									href="#"
									target="_blank"
									className="text-decoration-none text-gray-light"
									rel="noreferrer"
								>
									Hakkımızda
								</a>
								<a
									href="#"
									target="_blank"
									className="text-decoration-none text-gray-light"
									rel="noreferrer"
								>
									Projeler
								</a>
								<a
									href="#"
									target="_blank"
									className="text-decoration-none text-gray-light"
									rel="noreferrer"
								>
									Sosyal Sorumluluk
								</a>
							</div>
						</div>
						<div className="col-xl-2 col-md-3 col-6">
							<h6 className="mb-4">Legal</h6>
							<div className="d-grid gap-2">
								<NavLink
									to="/cerez-politikasi"
									className="text-decoration-none text-gray-light"
								>
									Çerez Politikasi
								</NavLink>
								<NavLink
									to="/aydinlatma-metni"
									className="text-decoration-none text-gray-light"
								>
									KVKK Aydınlatma Metni
								</NavLink>
								<NavLink
									to="/kullanim-kosullari"
									className="text-decoration-none text-gray-light"
								>
									Kullanım Koşulları
								</NavLink>
								<NavLink
									to="/sss"
									className="text-decoration-none text-gray-light"
								>
									Sıkça Sorulan Sorular
								</NavLink>
							</div>
						</div>
						<div className="col-xl-2 col-md-3 col-6">
							<h6 className="mb-4">İletişim</h6>
							<div className="d-grid gap-2">
								<a
									href="#"
									target="_blank"
									className="text-decoration-none text-gray-light"
									rel="noreferrer"
								>
									Bize Ulaşın
								</a>
								<div className="d-flex mt-3">
									<a
										href="#"
										target="_blank"
										className="text-orange me-3 fs-4"
										rel="noreferrer"
									>
										<i className="fab fa-facebook" />
									</a>
									<a
										href="#"
										target="_blank"
										className="text-orange me-3 fs-4"
										rel="noreferrer"
									>
										<i className="fab fa-twitter" />
									</a>
									<a
										href="#"
										target="_blank"
										className="text-orange me-3 fs-4"
										rel="noreferrer"
									>
										<i className="fab fa-linkedin-in" />
									</a>
									<a
										href="#"
										target="_blank"
										className="text-orange fs-4"
										rel="noreferrer"
									>
										<i className="fab fa-instagram" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			<div className="border-top border-2 border-gray-lighter py-4 text-center fs-7">
				<div className="container">
					<div className="d-flex justify-content-center align-items-center text-gray">
						<img
							src={require('../../assets/images/logo-jarvis.png')}
							alt=""
							className="me-2"
							style={{ width: 30 }}
						/>
						<a
							href="https://www.jarvis.com.tr/"
							target="_blank"
							rel="noreferrer"
							className="text-reset me-1"
						>
							Jarvis Yazılım A.Ş.
						</a>{' '}
						tarafından geliştirildi
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
