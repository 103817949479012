import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

const AdminContact = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [toast, setToast] = React.useState(null);
	const [isShowingLoading, setIsShowingLoading] = React.useState(null);
	const [activeAccordion, setActiveAccordion] = React.useState(null);
	const [subjects, setSubjects] = React.useState(null);
	const [activeTab, setActiveTab] = React.useState('messages');
	const [programs, setPrograms] = React.useState(null);
	const [sendSmsType, setSendSmsType] = React.useState(null);
	const { state } = useLocation();
	const navigate = useNavigate();

	const getSubjects = () => {
		setIsShowingLoading(true);
		fetch(`${constants.API_BASE_URL}/contactSubjectList`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					setIsShowingLoading(false);
					setSubjects(response.data);
				} else {
					setIsShowingLoading(false);
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				setIsShowingLoading(false);
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	const getPrograms = () => {
		setIsShowingLoading(true);
		fetch(`${constants.API_BASE_URL}/programs/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.content) {
					setIsShowingLoading(false);
					setPrograms(response.content);
				} else {
					setIsShowingLoading(false);
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				setIsShowingLoading(false);
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	React.useEffect(() => {
		getSubjects();
		getPrograms();
	}, []);

	const handleInitialSubmit = (event, id) => {
		event.preventDefault();
		setIsShowingLoading(true);
		fetch(`${constants.API_BASE_URL}/contactMessageInitial/save`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				message: Object.fromEntries(new FormData(event.target)).message,
				contactSubjectText: Object.fromEntries(
					new FormData(event.target)
				).subject,
				receiverUserId: id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					setIsShowingLoading(false);
					setToast({
						type: 'bg-green',
						message: 'Mesaj gönderildi.',
					});
					window.history.replaceState({}, document.title);
					setInterval(() => {
						window.location.reload();
					}, 1000);
				} else {
					setIsShowingLoading(false);
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				setIsShowingLoading(false);
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	const handleSubmit = (event, id) => {
		event.preventDefault();
		fetch(`${constants.API_BASE_URL}/contactMessage/save`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				message: Object.fromEntries(new FormData(event.target)).message,
				contactSubjectId: id,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					getSubjects();
					event.target.reset();
				} else {
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	const handleRemove = (event, id) => {
		event.preventDefault();
		fetch(`${constants.API_BASE_URL}/admin/contactMessage/close`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify(id),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					getSubjects();
				} else {
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	const handleSMSSubmit = (event) => {
		event.preventDefault();
		setIsShowingLoading(true);
		fetch(`${constants.API_BASE_URL}/admin/send/sms/many`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
			body: JSON.stringify({
				text: Object.fromEntries(new FormData(event.target)).text,
				sendSmsType: Object.fromEntries(new FormData(event.target))
					.sendSmsType,
				...(Object.fromEntries(new FormData(event.target))
					.programId && {
					programId: Object.fromEntries(new FormData(event.target))
						.programId,
				}),
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				event.target.reset();
				if (response) {
					setIsShowingLoading(false);
					setToast({
						type: 'bg-green',
						message: 'SMS gönderildi.',
					});
				} else {
					setToast({
						type: 'error',
						message: response.description
							? response.description
							: 'Lütfen daha sonra tekrar deneyin.',
					});
				}
			})
			.catch((error) => {
				event.target.reset();
				setToast({
					type: 'error',
					message:
						error.message === 'Failed to fetch'
							? 'Sunucu ile bağlantı kurulamadı.'
							: error.message,
				});
			});
	};

	return (
		<main>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col">
						<div className="d-flex align-items-center mb-5">
							<h1 className="mb-0 text-blue-light">İletişim</h1>
						</div>
					</div>
				</div>

				<div className="mb-5 d-flex flex-sm-row flex-column gap-2 position-relative">
					<button
						type="button"
						className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
							activeTab === 'messages'
								? 'btn-orange text-white'
								: 'border border-2 border-orange text-orange'
						}      `}
						onClick={() => setActiveTab('messages')}
					>
						Mesajlar
					</button>
					<button
						type="button"
						className={`btn rounded-pill px-3 py-1 fs-7 me-sm-2 me-0 ${
							activeTab === 'sms'
								? 'btn-orange text-white'
								: 'border border-2 border-orange text-orange'
						}      `}
						onClick={() => setActiveTab('sms')}
					>
						SMS Gönderimi
					</button>

					{constants.ADMIN_USERS.indexOf(context.dataUser?.id) ===
						-1 &&
						context.dataUser.userType === 'ADMIN' && (
							<button
								type="button"
								className="btn btn-support bg-white text-orange border border-2 border-orange text-white rounded-pill px-3 py-1 fs-7 me-sm-2 me-0"
								onClick={() => {
									setActiveTab('messages');
									navigate('/admin/iletisim', {
										state: {
											receiver: {
												id: constants.ADMIN_USERS[0],
												name: 'Super',
												surname: 'Admin',
											},
										},
									});
								}}
							>
								<i className="fas fa-envelope me-2" /> Destek
							</button>
						)}
				</div>

				{activeTab === 'messages' &&
					(state ? (
						<div className="row justify-content-center">
							<div className="col">
								<div className="bg-gray-lightest rounded rounded-2 mb-2 border-0">
									<div className="d-block text-reset text-decoration-none p-4">
										<div className="row align-items-center g-3">
											<div className="col">
												<div className="d-flex align-items-center">
													<div
														className="bg-blue text-white rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
														style={{
															width: 48,
															height: 48,
														}}
													>
														<i className="fas fa-envelope" />
													</div>
													<div className="ms-3 overflow-hidden">
														<h5 className="mb-0">
															<span className="fs-7 d-block mb-1 opacity-50">
																Yeni Mesaj
															</span>{' '}
															{`${
																state.receiver
																	.name
																	? state
																			.receiver
																			.name
																	: ''
															} ${
																state.receiver
																	.surname
																	? state
																			.receiver
																			.surname
																	: ''
															}`}
														</h5>
													</div>
													<button
														type="button"
														className="btn btn-link text-red fs-7 fw-semibold ms-auto"
														onClick={() => {
															window.history.replaceState(
																{},
																document.title
															);
															window.location.reload();
														}}
													>
														İptal
													</button>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div className="p-4 pt-0">
											<form
												onSubmit={(event) => {
													handleInitialSubmit(
														event,
														state.receiver.id
													);
												}}
											>
												<div className="bg-white border border-2 border-gray-lighter rounded rounded-2 p-3 d-flex mb-3">
													<input
														type="text"
														name="subject"
														className="form-control flex-fill border-0 p-0 rounded-0"
														placeholder="Başlık"
													/>
												</div>
												<div className="bg-white border border-2 border-gray-lighter rounded rounded-2 p-3 d-flex mb-3">
													<textarea
														rows={3}
														name="message"
														className="form-control flex-fill border-0 p-0 rounded-0"
														placeholder="Mesaj"
													/>
												</div>
												<div className="text-end">
													<button
														type="submit"
														className="btn border border-2 border-orange text-orange rounded-pill px-3 py-2 fs-6"
													>
														Gönder
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : subjects && Object.keys(subjects).length ? (
						<div className="row justify-content-center">
							<div className="col">
								<div className="accordion accordion-flush">
									{subjects?.map((subject, index) => (
										<div
											className="accordion-item bg-gray-lightest rounded rounded-2 mb-2 border-0"
											key={subject.id}
										>
											<button
												type="button"
												className="btn w-100 text-start d-block text-reset text-decoration-none p-4"
												onClick={() => {
													if (
														activeAccordion ===
														subject.id
													) {
														setActiveAccordion(
															null
														);
													} else {
														setActiveAccordion(
															subject.id
														);
														fetch(
															`${constants.API_BASE_URL}/contactMessageList/${subject.id}`,
															{
																method: 'GET',
																headers: {
																	'Content-Type':
																		'application/json',
																	Authorization: `Bearer ${context.dataLogin.token}`,
																},
															}
														).then(() => {
															getSubjects();
														});
													}
												}}
											>
												<div className="row align-items-center g-3">
													<div className="col">
														<div className="d-flex align-items-center">
															<div
																className={`text-white rounded-circle d-flex justify-content-center align-items-center flex-shrink-0 ${
																	subject.listMessage &&
																	subject.listMessage.filter(
																		(
																			message
																		) =>
																			message.unreadMessage &&
																			message
																				.sender
																				.id !==
																				context
																					.dataUser
																					.id
																	).length > 0
																		? 'bg-green'
																		: 'bg-blue'
																}`}
																style={{
																	width: 48,
																	height: 48,
																}}
															>
																{subject.listMessage &&
																subject.listMessage.filter(
																	(message) =>
																		message.unreadMessage &&
																		message
																			.sender
																			.id !==
																			context
																				.dataUser
																				.id
																).length > 0 ? (
																	<i className="fas fa-envelope" />
																) : (
																	<i className="fas fa-envelope-open" />
																)}
															</div>
															<div className="ms-3 overflow-hidden">
																<div className="d-flex align-items-center">
																	<h6 className="mb-0">
																		{
																			subject.subject
																		}
																	</h6>
																	{subject.listMessage &&
																		subject.listMessage.filter(
																			(
																				message
																			) =>
																				message.unreadMessage &&
																				message
																					.sender
																					.id !==
																					context
																						.dataUser
																						.id
																		)
																			.length >
																			0 && (
																			<div className="bg-green text-white fs-8 rounded-pill px-2 ms-3">
																				Yeni
																			</div>
																		)}
																</div>
																<p className="mb-0 fs-7 mt-1 d-lg-block d-none text-truncate">
																	{
																		subject
																			.sender
																			?.name
																	}{' '}
																	{
																		subject
																			.sender
																			?.surname
																	}
																</p>
															</div>
															<span className="ms-auto fs-8 fw-medium opacity-75">
																{subject.listMessage &&
																	moment(
																		subject
																			.listMessage[0]
																			.date
																	).format(
																		'DD.MM.YYYY HH:mm'
																	)}
															</span>
															<button
																type="button"
																className="btn btn-link ms-4 text-red fs-7"
																onClick={(
																	event
																) => {
																	handleRemove(
																		event,
																		subject.id
																	);
																}}
															>
																Konuşmayı Sil
															</button>
														</div>
													</div>
												</div>
											</button>
											<div
												id={`item-${index}`}
												className={`accordion-collapse collapse ${
													activeAccordion ===
														subject.id && 'show'
												}`}
											>
												<div className="p-4 pt-0">
													<div
														className="d-flex flex-column flex-column-reverse mb-3"
														style={{
															height: 300,
															overflowY: 'scroll',
														}}
													>
														{subject.listMessage &&
															subject.listMessage.map(
																(message) => (
																	<div
																		className={
																			message
																				.sender
																				.id ===
																			context
																				.dataUser
																				.id
																				? 'bg-green-lightest p-3 rounded rounded-2 fs-7 text-green lh-lg align-self-end mb-3'
																				: 'bg-blue-lightest p-3 rounded rounded-2 fs-7 text-blue-light lh-lg mb-3'
																		}
																		style={{
																			maxWidth:
																				'75%',
																		}}
																		key={
																			message.id
																		}
																	>
																		{
																			message.message
																		}
																		<span className="d-block fs-9 opacity-50">
																			{moment(
																				message.date
																			).format(
																				'DD.MM.YYYY HH:mm'
																			)}
																		</span>
																	</div>
																)
															)}
													</div>
													<form
														className="bg-white border border-2 border-gray-lighter rounded rounded-2 p-3 d-flex"
														onSubmit={(event) => {
															handleSubmit(
																event,
																subject.id
															);
														}}
													>
														<input
															type="text"
															name="message"
															className="form-control flex-fill border-0 p-0 rounded-0"
															placeholder="Mesajınız"
														/>
														<button
															type="submit"
															className="btn border border-2 border-orange text-orange rounded-pill px-3 py-1 fs-7 ms-3 d-lg-inline d-none flex-shrink-0"
														>
															Gönder
														</button>
													</form>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					) : (
						<div className="flex-fill rounded-2 d-flex mt-4 flex-column justify-content-center align-items-center text-blue-light text-center">
							<i className="fa-solid fa-envelope h1 mb-3" />
							<h6 className="mb-0 lh-base">
								Hiç mesajınız bulunmuyor.
							</h6>
						</div>
					))}

				{activeTab === 'sms' && (
					<div className="row justify-content-center">
						<div className="col">
							<div className="bg-gray-lightest rounded rounded-2 mb-2 border-0">
								<div className="d-block text-reset text-decoration-none p-4">
									<div className="row align-items-center g-3">
										<div className="col">
											<div className="d-flex align-items-center">
												<div
													className="bg-blue text-white rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
													style={{
														width: 48,
														height: 48,
													}}
												>
													<i className="fas fa-envelope" />
												</div>
												<div className="ms-3 overflow-hidden">
													<h6 className="mb-0">
														Yeni SMS Gönder
													</h6>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="p-4 pt-0">
										<form
											onSubmit={(event) => {
												handleSMSSubmit(event);
											}}
										>
											<select
												name="sendSmsType"
												className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg mb-3"
												defaultValue={sendSmsType}
												onChange={(event) => {
													setSendSmsType(
														event.target.value
													);
												}}
											>
												<option value="">
													Hedef Kitle Seçiniz
												</option>
												<option value="MENTOR">
													Tüm Mentorlar
												</option>
												<option value="MENTEE">
													Tüm Kurumsal Üyeler
												</option>
												<option value="PROGRAM">
													Programdaki Kurumsal Üyeler
												</option>
											</select>
											{sendSmsType === 'PROGRAM' && (
												<select
													name="programId"
													className="form-select border border-2 border-gray-lighter py-2 px-3 lh-lg mb-3"
												>
													<option value="">
														Program Seçiniz
													</option>
													{programs &&
														programs.map(
															(program) => (
																<option
																	value={
																		program.id
																	}
																	key={
																		program.id
																	}
																>
																	{
																		program.name
																	}
																</option>
															)
														)}
												</select>
											)}
											<div className="bg-white border border-2 border-gray-lighter rounded rounded-2 p-3 d-flex mb-3">
												<textarea
													rows={3}
													name="text"
													className="form-control flex-fill border-0 p-0 rounded-0"
													placeholder="Mesaj"
												/>
											</div>
											<div className="text-end">
												<button
													type="submit"
													className="btn border border-2 border-orange text-orange rounded-pill px-3 py-2 fs-6"
												>
													Gönder
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			<Toast
				showToast={toast}
				onClose={() => setToast(null)}
				type={toast?.type}
				message={toast?.message}
			/>
			<Loading showLoading={isShowingLoading} />
		</main>
	);
};

export default AdminContact;
