/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React from 'react';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom'; //eslint-disable-line
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/fontawesome-free/css/all.min.css';
import TagManager from 'react-gtm-module';
import constants from './constants';

import Context from './stores/Context/Context';

import AdminLayout from './layouts/AdminLayout/AdminLayout';
import Layout from './layouts/Layout/Layout';

import AdminApprovals from './screens/AdminApprovals/AdminApprovals';
import AdminCalendar from './screens/AdminCalendar/AdminCalendar';
import AdminContact from './screens/AdminContact/AdminContact';
import AdminContents from './screens/AdminContents/AdminContents';
import AdminCourses from './screens/AdminCourses/AdminCourses';
import AdminDashboard from './screens/AdminDashboard/AdminDashboard';
import AdminFiles from './screens/AdminFiles/AdminFiles';
import AdminHelp from './screens/AdminHelp/AdminHelp';
import SuperAdminHelp from './screens/SuperAdminHelp/SuperAdminHelp';
import AdminMentees from './screens/AdminMentees/AdminMentees';
import AdminMentors from './screens/AdminMentors/AdminMentors';
import AdminNotifications from './screens/AdminNotifications/AdminNotifications';
import AdminProfile from './screens/AdminProfile/AdminProfile';
import AdminPrograms from './screens/AdminPrograms/AdminPrograms';
import AdminReports from './screens/AdminReports/AdminReports';

import Calendar from './screens/Calendar/Calendar';
import Contact from './screens/Contact/Contact';
import Content from './screens/Content/Content';
import Courses from './screens/Courses/Courses';
import Dashboard from './screens/Dashboard/Dashboard';
import Login from './screens/Login/Login';
import Mentees from './screens/Mentees/Mentees';
import NotFound from './screens/NotFound/NotFound';
import Profile from './screens/Profile/Profile';
import Register from './screens/Register/Register';
import Reset from './screens/Reset/Reset';
import Disclaimer from './screens/Disclaimer/Disclaimer';

import Modal from './components/Modal/Modal';

import './App.scss';

const App = () => {
	const [state, setState] = React.useState({});
	const [targetCookiesSw, setTargetCookiesSw] = React.useState(false);
	const value = React.useMemo(() => [state, setState], [state]);

	if (
		Object.entries(state).length === 0 &&
		localStorage.getItem('context') &&
		JSON.parse(localStorage.getItem('context')).rememberMe
	) {
		setState({
			...JSON.parse(localStorage.getItem('context')),
		});
	}

	const [isCookiePolicyVisible, setIsCookiePolicyVisible] = React.useState(
		!localStorage.getItem('cookiePolicy')
	);

	const handleSavePolicy = () => {
		localStorage.setItem('cookiePolicy', true);
		localStorage.setItem('targetCookies', targetCookiesSw);
		setIsCookiePolicyVisible(false);

		if (state.targetCookies) {
			const tagManagerArgs = {
				gtmId: constants.GTM_CODE,
			};
			// Google TagManager
			TagManager.initialize(tagManagerArgs);
			window.dataLayer.push({
				event: 'pageview',
				page: {
					url: `/${
						window.location.href.split('/')[
							window.location.href.split('/').length - 1
						]
					}`,
					title: `${
						window.location.href.split('/')[
							window.location.href.split('/').length - 1
						]
					}`,
				},
			});
		}
	};

	return (
		<Context.Provider value={value}>
			<HashRouter>
				<Routes>
					<Route path="*" element={<NotFound />} />

					<Route path="/giris" element={<Login />} />
					<Route path="/kayit" element={<Register />} />
					<Route path="/sifremi-unuttum" element={<Reset />} />

					<Route path="/disclaimer" element={<Disclaimer />} />

					<Route path="/" element={<Layout />}>
						<Route index element={<Dashboard />} />
						<Route path="/profil" element={<Profile />} />
						<Route path="/takvim" element={<Calendar />} />
						<Route path="/egitimler" element={<Courses />} />
						<Route path="/ogrenciler" element={<Mentees />} />
						<Route path="/iletisim" element={<Contact />} />

						<Route path="/cerez-politikasi" element={<Content />} />
						<Route path="/aydinlatma-metni" element={<Content />} />
						<Route
							path="/kullanim-kosullari"
							element={<Content />}
						/>
						<Route path="/sss" element={<Content />} />
					</Route>

					<Route path="/admin" element={<AdminLayout />}>
						<Route index element={<AdminDashboard />} />
						<Route path="programlar" element={<AdminPrograms />} />
						<Route path="ogrenciler" element={<AdminMentees />} />
						<Route path="mentorlar" element={<AdminMentors />} />
						<Route path="profil" element={<AdminProfile />} />
						<Route path="takvim" element={<AdminCalendar />} />
						<Route path="onaylar" element={<AdminApprovals />} />
						<Route path="egitimler" element={<AdminCourses />} />
						<Route path="dosyalar" element={<AdminFiles />} />
						<Route path="icerikler" element={<AdminContents />} />
						<Route
							path="uyarilar"
							element={<AdminNotifications />}
						/>
						<Route path="raporlar" element={<AdminReports />} />
						<Route path="destek" element={<AdminHelp />} />
						<Route
							path="destek-ekle"
							element={<SuperAdminHelp />}
						/>
						<Route path="iletisim" element={<AdminContact />} />
					</Route>
				</Routes>
			</HashRouter>

			{isCookiePolicyVisible && !state.dataLogin && (
				<>
					<div
						className="bg-black position-fixed p-3 rounded text-white fs-7 text-center"
						style={{
							bottom: 16,
							right: 16,
							maxWidth: 600,
							opacity: 0.9,
						}}
					>
						<h3>Daha iyi bir deneyim için izninizi istiyoruz.</h3>
						<p>
							Web sitesinin çalışması için zorunlu bazı çerezler
							sistemlerimizde kullanılmaktadır ve kapatılamaz. Web
							sitesinde dolaşmanıza ve gizlilik tercihlerinizi
							belirleme, giriş yapma veya formları doldurma gibi
							hizmet talebine karşılık gelen eylemler gibi
							özelliklerini kullanmanıza olanak sağlamaktadır.
						</p>

						<div>
							{/* <button
							type="button"
							className="btn border border-2 border-red text-red bg-white rounded-pill px-3 py-1 fs-7 mx-1"
							onClick={() => setIsCookiePolicyVisible(false)}
						>
							Kapat
						</button> */}
							<button
								type="button"
								className="btn border border-2 border-orange text-orange bg-white rounded-pill px-3 py-1 fs-7 mx-1"
								onClick={() =>
									setState({
										...state,
										showModal: true,
									})
								}
							>
								Çerez Ayarları
							</button>
							<button
								type="button"
								className="btn border border-2 border-green text-white bg-green rounded-pill px-3 py-1 fs-7 mx-1"
								onClick={() => handleSavePolicy()}
							>
								Tümünü kabul et
							</button>
						</div>
					</div>

					<Modal
						showModal={state.showModal}
						onClose={() =>
							setState({
								...state,
								showModal: false,
							})
						}
						header="Çerez Ayarları"
					>
						<div className="modal-body p-4">
							<p>
								Bu internet sitesinde sizlere daha iyi hizmet
								sunulabilmesi için çerezler vasıtasıyla kişisel
								verileriniz toplanmaktadır. Çerez tercihlerinizi
								değiştirmek ve çerezler hakkında detaylı bilgi
								almak için Çerez Politikası’nı inceleyiniz.
								Çerez ayarlarını değiştirmeniz durumunda
								internet sitesinin bazı özelliklerinin
								işlevselliğini kaybedebileceğini dikkate alınız.
							</p>

							<div className="mt-4">
								<h5>Tercihlerinizi Yönetin</h5>
								<div>
									<div className="d-flex justify-content-between align-items-center mb-3">
										<h6 className="m-0">
											Reklam veya Hedefleme Çerezleri
										</h6>
										<div className="custom-slider">
											<input
												type="checkbox"
												id="switch"
												value={targetCookiesSw}
												onChange={() => {
													setTargetCookiesSw(
														!targetCookiesSw
													);
												}}
												checked={targetCookiesSw}
											/>
											<label htmlFor="switch">
												Toggle
											</label>
										</div>
									</div>
									<p className="m-0">
										Bu çerezleri, reklam ve pazarlama
										amaçları ile kullanmaktayız. Bu
										çerezler, kullanıcıların tarayıcılarını
										ve cihazlarını bir şekilde tanımlayarak
										çalışırlar. Kullanıcıların ilgi
										alanlarının profillerini oluşturmak ve
										kullanıcılara diğer sitelerde alakalı
										reklamlar göstermek amacıyla reklam veya
										hedefleme çerezlerini
										kullanılabilmekteyiz. Kullanıcılar, bu
										çerezlere izin vermedikleri takdirde,
										kullanıcılar hedefli reklamlar
										gösterilemeyecektir.
									</p>
								</div>
							</div>
						</div>
						<div className="modal-footer justify-content-center">
							<button
								type="button"
								className="btn border border-2 border-green text-white bg-green rounded-pill px-3 py-1 fs-7 mx-1"
								onClick={() => handleSavePolicy()}
							>
								Tümünü kabul et
							</button>

							<button
								type="button"
								className="btn border border-2 border-orange text-orange rounded-pill px-3 py-1 fs-7 mx-1"
								onClick={() => handleSavePolicy()}
							>
								Çerez Ayarlarımı Kaydet
							</button>
						</div>
					</Modal>
				</>
			)}
		</Context.Provider>
	);
};

export default App;
