import React from 'react';

import constants from '../../constants';
import Context from '../../stores/Context/Context';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Toast from '../../components/Toast/Toast';
import WidgetAdminTitleBar from '../../components/WidgetAdminTitleBar/WidgetAdminTitleBar';

const AdminReports = () => {
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		showLoading: false,
		toast: false,
		courses: null,
		activityId: null,
		showModal: false,
		localISOTime: undefined,
		dateTimeBegin: undefined,
		dateTimeBeginMatch: undefined,
		dateTimeBeginSTK: undefined,
		dateTimeEnd: undefined,
		dateTimeEndMatch: undefined,
		dateTimeEndSTK: undefined,
	});

	const [selectedProgram, setSelectedProgram] = React.useState(null);

	const timeZoneOffsetFix = () => {
		const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
		const localISOTimeCurrent = new Date(Date.now() - tzoffset)
			.toISOString()
			.slice(0, -8);

		return localISOTimeCurrent;
	};

	React.useEffect(() => {
		const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
		const localISOTimeCurrent = new Date(Date.now() - tzoffset)
			.toISOString()
			.slice(0, -8);

		setState({
			...state,
			localISOTime: localISOTimeCurrent,
		});
	}, [
		state.dateTimeBegin,
		state.dateTimeBeginMatch,
		state.dateTimeBeginSTK,
		state.dateTimeEnd,
		state.dateTimeEndMatch,
		state.dateTimeEndSTK,
	]);

	const handleEducationFilter = (event) => {
		event.preventDefault();

		const activityId = event.target.value;
		if (activityId && activityId !== '') {
			setState({ ...state, activityId });
		} else {
			setState({ ...state, activityId: null });
		}
	};

	// Eğitim Durumu Kritik Raporu
	const handleOpenEducationModal = (e) => {
		e.preventDefault();

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/allActivitiesAdmin/?size=9999`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.content) {
					setState({
						...state,
						showLoading: false,
						courses: response.content,
						showModal: true,
						header: 'Eğitim Durumu Kritik Raporu',
					});
				} else {
					setState({
						...state,
						showLoading: false,
						showModal: false,
						toast: {
							type: 'error',
							message: 'Lütfen eğitim tanımlayınız!',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleEducationCritical = (e) => {
		e.preventDefault();

		if (state.activityId && state.activityId !== '') {
			setState({ ...state, showLoading: true });
			fetch(
				`${constants.API_BASE_URL}/admin/activities/
			${state.activityId}/${state.dateTimeBegin}:00+03:00/${state.dateTimeEnd}:00+03:00/menteeList`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'blob',
						Authorization: `Bearer ${context.dataLogin.token}`,
					},
				}
			)
				.then((resp) => resp.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					// the filename you want
					a.download = 'EğitimDurumuKritikRaporud.xls';
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
					setState({ ...state, showLoading: false });
				})
				.catch((error) => {
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message:
								error.message === 'Failed to fetch'
									? 'Sunucu ile bağlantı kurulamadı.'
									: error.message,
						},
					});
				});
		} else {
			setState({
				...state,
				toast: {
					type: 'error',
					message: 'Lütfen eğitim seçiniz!',
				},
				showLoading: false,
			});
		}
	};

	// STK Durumu Kritik Raporu
	const handleSTKModal = (e) => {
		e.preventDefault();

		setState({
			...state,
			showModalSTK: true,
			headerSTK: 'STK Durumu Kritik Raporu',
		});
	};

	const handleSTKReport = (e) => {
		e.preventDefault();

		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/admin/stk/list/${state.dateTimeBeginSTK}:00+03:00/${state.dateTimeEndSTK}:00+03:00`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'blob',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = 'STKDurumuKritikRaporu.xls';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setState({ ...state, showLoading: false });
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	// Mentör Listesi Raporu
	const handleGetAllMentorReport = (e) => {
		e.preventDefault();

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/getAllMentorReport`, {
			method: 'POST',
			headers: {
				'Content-Type': 'blob',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = 'MentörListesiRaporu.xls';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setState({ ...state, showLoading: false });
			})
			.catch((error) => {
				setState({
					...state,
					showLoading: false,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
				});
			});
	};

	// Burs Durumu Kritik Raporu
	const handleBursaryReport = (e) => {
		e.preventDefault();

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/mentee/bursaryList`, {
			method: 'POST',
			headers: {
				'Content-Type': 'blob',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = 'BursDurumuKritikRaporu.xls';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setState({ ...state, showLoading: false });
			})
			.catch((error) => {
				setState({
					...state,
					showLoading: false,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
				});
			});
	};

	// Eşleşme Raporu
	const handleOpenGetActiveMatchesModal = (e) => {
		e.preventDefault();

		setState({
			...state,
			showLoading: false,
			showModalMatch: true,
			headerMatch: 'Eşleşme Raporu',
		});
	};

	const handleGetActiveMatchesReport = (e) => {
		e.preventDefault();

		setState({ ...state, showLoading: true });
		fetch(
			`${constants.API_BASE_URL}/admin/getActiveMatches/${state.dateTimeBeginMatch}:00+03:00/${state.dateTimeEndMatch}:00+03:00`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'blob',
					Authorization: `Bearer ${context.dataLogin.token}`,
				},
			}
		)
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = 'EşleşmeRaporu.xls';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setState({ ...state, showLoading: false });
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	// Bursiyer Grupları Raporu
	const handleOpenBursaryGroupsModal = (e) => {
		e.preventDefault();

		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/programs?size=9999`, {
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${context.dataLogin.token}`,
				'Content-Type': constants.API_HEADERS_PUBLIC,
			}),
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp && resp.content) {
					setState({
						...state,
						showLoading: false,
						programs: resp.content,
						showModalBursaryGroups: true,
						headerBursaryGroups: 'Bursiyer Grupları Raporu',
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleBursaryGroupsReport = (e) => {
		e.preventDefault();

		if (selectedProgram && selectedProgram !== '') {
			setState({ ...state, showLoading: true });
			fetch(
				`${constants.API_BASE_URL}/admin/programs/${selectedProgram}/menteeList`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'blob',
						Authorization: `Bearer ${context.dataLogin.token}`,
					},
				}
			)
				.then((resp) => resp.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					// the filename you want
					a.download = 'BursiyerGruplarıRaporu.xls';
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
					setState({ ...state, showLoading: false });
				})
				.catch((error) => {
					setState({
						...state,
						toast: {
							type: 'error',
							message:
								error.message === 'Failed to fetch'
									? 'Sunucu ile bağlantı kurulamadı.'
									: error.message,
						},
						showLoading: false,
					});
				});
		} else {
			setState({
				...state,
				toast: {
					type: 'error',
					message: 'Lütfen program seçiniz!',
				},
				showLoading: false,
			});
		}
	};

	const handleTranscriptReport = (e) => {
		e.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/transkript/list`, {
			method: 'POST',
			headers: {
				'Content-Type': 'blob',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = 'TranskriptRaporu.xls';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setState({ ...state, showLoading: false });
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleFoundationReport = (e) => {
		e.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/menteeInfoList`, {
			method: 'POST',
			headers: {
				'Content-Type': 'blob',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = 'VakıfGenelRaporu.xls';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setState({ ...state, showLoading: false });
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	const handleEducationParticipation = (e) => {
		e.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/admin/activityParticipationExcel`, {
			method: 'POST',
			headers: {
				'Content-Type': 'blob',
				Authorization: `Bearer ${context.dataLogin.token}`,
			},
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = 'EğitimKatılımDurumu.xls';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setState({ ...state, showLoading: false });
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	return (
		<>
			<WidgetAdminTitleBar title="Raporlar" />
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr className="fs-7">
							<th
								scope="col"
								className="align-middle text-gray text-center"
								width="60"
							>
								ID
							</th>
							<th scope="col" className="align-middle text-gray">
								Rapor Adı
							</th>
							<th scope="col" className="align-middle text-gray">
								Açıklama
							</th>
							<th
								scope="col"
								className="align-middle text-gray text-center"
								style={{ width: 170 }}
							>
								İşlem
							</th>
						</tr>
					</thead>
					<tbody className="fs-7">
						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								1
							</td>
							<td className="align-middle text-gray">
								Eğitim Durumu Kritik Raporu
							</td>
							<td className="align-middle text-gray">
								Eğitim durumu kritik seviyedeki öğrencilerin
								raporunu çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) =>
										handleOpenEducationModal(event)
									}
								>
									İndir
								</button>
							</td>
						</tr>

						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								2
							</td>
							<td className="align-middle text-gray">
								STK Durumu Kritik Raporu
							</td>
							<td className="align-middle text-gray">
								STK Durumu kritik seviyedeki öğrencilerin
								raporunu çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) => handleSTKModal(event)}
								>
									İndir
								</button>
							</td>
						</tr>

						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								3
							</td>
							<td className="align-middle text-gray">
								Burs Durumu Kritik Raporu
							</td>
							<td className="align-middle text-gray">
								Burs durumu kritik seviyedeki öğrencilerin
								raporlarını çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) =>
										handleBursaryReport(event)
									}
								>
									İndir
								</button>
							</td>
						</tr>

						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								4
							</td>
							<td className="align-middle text-gray">
								Mentör Listesi Raporu
							</td>
							<td className="align-middle text-gray">
								Mentör listelerinin raporunu çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) =>
										handleGetAllMentorReport(event)
									}
								>
									İndir
								</button>
							</td>
						</tr>

						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								5
							</td>
							<td className="align-middle text-gray">
								Bursiyer Grupları Raporu
							</td>
							<td className="align-middle text-gray">
								Bursiyer gruplarının raporlarını çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) =>
										handleOpenBursaryGroupsModal(event)
									}
								>
									İndir
								</button>
							</td>
						</tr>

						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								6
							</td>
							<td className="align-middle text-gray">
								Eşleşme Raporu
							</td>
							<td className="align-middle text-gray">
								Mentör ve Öğrenci eşleşmesi raporunu çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) =>
										handleOpenGetActiveMatchesModal(event)
									}
								>
									İndir
								</button>
							</td>
						</tr>

						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								7
							</td>
							<td className="align-middle text-gray">
								Transkript Raporu
							</td>
							<td className="align-middle text-gray">
								Öğrencilerin transkipt raporlarını çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green
									bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) =>
										handleTranscriptReport(event)
									}
								>
									İndir
								</button>
							</td>
						</tr>

						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								8
							</td>
							<td className="align-middle text-gray">
								Vakıf Genel Raporu
							</td>
							<td className="align-middle text-gray">
								Vakıf genel raporunu çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green
									bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) =>
										handleFoundationReport(event)
									}
								>
									İndir
								</button>
							</td>
						</tr>

						<tr>
							<td className="align-middle text-gray-light text-center fw-medium fs-8">
								9
							</td>
							<td className="align-middle text-gray">
								Eğitim Katılım Durumu Raporu
							</td>
							<td className="align-middle text-gray">
								Eğitim katılım durumu raporunu çıkarır!
							</td>
							<td className="align-middle text-gray text-center">
								<button
									type="button"
									className="btn border border-2 border-green text-green
									bg-white rounded-pill px-3 py-1 fs-7 mx-1"
									onClick={(event) =>
										handleEducationParticipation(event)
									}
								>
									İndir
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			{/* Eğitim Durumu Kritik Modal */}
			<Modal
				showModal={state.showModal}
				onClose={() =>
					setState({
						...state,
						showModal: false,
						dateTimeBegin: undefined,
						dateTimeBeginMatch: undefined,
						dateTimeBeginSTK: undefined,
						dateTimeEnd: undefined,
						dateTimeEndMatch: undefined,
						dateTimeEndSTK: undefined,
					})
				}
				header={state.header}
			>
				<form onSubmit={(e) => handleEducationCritical(e)}>
					<div className="modal-body p-4">
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Eğitim
								</label>
							</div>
							<div className="col-7">
								<select
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									onChange={(event) =>
										handleEducationFilter(event)
									}
								>
									<option value="">Lütfen Seçiniz</option>
									{state.courses &&
										state.courses.length > 0 &&
										// eslint-disable-next-line array-callback-return
										state.courses.map((item) => (
											<option
												key={item.id}
												value={item.id}
											>
												{item.name}
											</option>
										))}
								</select>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Başlangıç
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="dateTimeBegin"
									value={state.dateTimeBegin}
									max={timeZoneOffsetFix()}
									onChange={(event) => {
										setState({
											...state,
											dateTimeBegin: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Bitiş
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="dateTimeEnd"
									value={state.dateTimeEnd}
									max={state.localISOTime}
									onChange={(event) => {
										setState({
											...state,
											dateTimeEnd: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer justify-content-center">
						<button
							type="submit"
							className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						>
							İndir
						</button>
					</div>
				</form>
			</Modal>

			{/* STK Durumu Kritik Raporu Modal */}
			<Modal
				showModal={state.showModalSTK}
				onClose={() =>
					setState({
						...state,
						showModalSTK: false,
						dateTimeBegin: undefined,
						dateTimeBeginMatch: undefined,
						dateTimeBeginSTK: undefined,
						dateTimeEnd: undefined,
						dateTimeEndMatch: undefined,
						dateTimeEndSTK: undefined,
					})
				}
				header={state.headerSTK}
			>
				<form onSubmit={(e) => handleSTKReport(e)}>
					<div className="modal-body p-4">
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Başlangıç
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="dateTimeBeginSTK"
									value={state.dateTimeBeginSTK}
									max={state.localISOTime}
									onChange={(event) => {
										setState({
											...state,
											dateTimeBeginSTK:
												event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Bitiş
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="dateTimeEndSTK"
									value={state.dateTimeEndSTK}
									max={state.localISOTime}
									onChange={(event) => {
										setState({
											...state,
											dateTimeEndSTK: event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer justify-content-center">
						<button
							type="submit"
							className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						>
							İndir
						</button>
					</div>
				</form>
			</Modal>

			{/* Eşleşme Raporu Modal */}
			<Modal
				showModal={state.showModalMatch}
				onClose={() =>
					setState({
						...state,
						showModalMatch: false,
						dateTimeBegin: undefined,
						dateTimeBeginMatch: undefined,
						dateTimeBeginSTK: undefined,
						dateTimeEnd: undefined,
						dateTimeEndMatch: undefined,
						dateTimeEndSTK: undefined,
					})
				}
				header={state.headerMatch}
			>
				<form onSubmit={(e) => handleGetActiveMatchesReport(e)}>
					<div className="modal-body p-4">
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Başlangıç
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="dateTimeBeginMatch"
									value={state.dateTimeBeginMatch}
									max={state.localISOTime}
									onChange={(event) => {
										setState({
											...state,
											dateTimeBeginMatch:
												event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Bitiş
								</label>
							</div>
							<div className="col-7">
								<input
									type="datetime-local"
									name="dateTimeEndMatch"
									value={state.dateTimeEndMatch}
									max={state.localISOTime}
									onChange={(event) => {
										setState({
											...state,
											dateTimeEndMatch:
												event.target.value,
										});
									}}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									required
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer justify-content-center">
						<button
							type="submit"
							className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						>
							İndir
						</button>
					</div>
				</form>
			</Modal>

			{/* Bursiyer Grupları Raporu Modal */}
			<Modal
				showModal={state.showModalBursaryGroups}
				onClose={() =>
					setState({
						...state,
						showModalBursaryGroups: false,
					})
				}
				header={state.headerBursaryGroups}
			>
				<form onSubmit={(e) => handleBursaryGroupsReport(e)}>
					<div className="modal-body p-4">
						<div className="row g-4 align-items-center mb-3">
							<div className="col-5">
								<label className="col-form-label fw-medium">
									Program
								</label>
							</div>
							<div className="col-7">
								<select
									value={selectedProgram}
									className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
									onChange={(event) => {
										setSelectedProgram(event.target.value);
									}}
								>
									<option value="">Lütfen Seçiniz</option>
									{state.programs &&
										state.programs.length > 0 &&
										// eslint-disable-next-line array-callback-return
										state.programs.map((item) => (
											<option
												key={item.id}
												value={item.id}
											>
												{item.name}
											</option>
										))}
								</select>
							</div>
						</div>
					</div>
					<div className="modal-footer justify-content-center">
						<button
							type="submit"
							className="btn border border-2 border-orange text-orange rounded-pill px-4 py-2"
						>
							İndir
						</button>
					</div>
				</form>
			</Modal>

			<Toast
				showToast={state.toast}
				onClose={() =>
					setState({
						...state,
						toast: false,
					})
				}
				type={state.toast?.type}
				message={state.toast?.message}
			/>
			<Loading showLoading={state.showLoading} />
		</>
	);
};

export default AdminReports;
