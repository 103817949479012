import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import constants from '../../constants';

import Context from '../../stores/Context/Context';
import FooterGeneric from '../../layouts/FooterGeneric/FooterGeneric';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

const Login = () => {
	const location = useLocation();
	const [context, setContext] = React.useContext(Context); //eslint-disable-line
	const [state, setState] = React.useState({
		rememberMe: true,
		showLoading: false,
	});
	const [homeBanner, setHomeBanner] = React.useState(null);

	const navigate = useNavigate();

	const handleFormSubmit = (event) => {
		event.preventDefault();
		setState({ ...state, showLoading: true });
		fetch(`${constants.API_BASE_URL}/public/login`, {
			method: 'POST',
			headers: constants.API_HEADERS_PUBLIC,
			body: JSON.stringify(
				Object.fromEntries(new FormData(event.target))
			),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response?.data) {
					fetch(`${constants.API_BASE_URL}/getLoginUser`, {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${response.data.token}`,
						},
					})
						.then((response2) => response2.json())
						.then((response2) => {
							if (response2?.data) {
								localStorage.setItem(
									'context',
									JSON.stringify({
										dataLogin: response.data,
										dataUser: response2.data,
										rememberMe: state.rememberMe,
									})
								);
								setContext({
									dataLogin: response.data,
									dataUser: response2.data,
									rememberMe: state.rememberMe,
								});
								setState({
									...state,
									showLoading: false,
								});
								if (
									['mentee', 'mentor'].includes(
										response.data.userRole
									)
								) {
									navigate('/');
								}
								if (response.data.userRole === 'admin') {
									navigate('/admin');
								}
							} else {
								event.target.reset();
								setState({
									...state,
									showLoading: false,
									toast: {
										type: 'error',
										message: response.description
											? response.description
											: 'Lütfen daha sonra tekrar deneyin.',
									},
								});
							}
						})
						.catch((error) => {
							setState({
								...state,
								toast: {
									type: 'error',
									message:
										error.message === 'Failed to fetch'
											? 'Sunucu ile bağlantı kurulamadı.'
											: error.message,
								},
								showLoading: false,
							});
						});
				} else {
					event.target.reset();
					setState({
						...state,
						showLoading: false,
						toast: {
							type: 'error',
							message: response.description
								? response.description
								: 'Lütfen daha sonra tekrar deneyin.',
						},
					});
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message:
							error.message === 'Failed to fetch'
								? 'Sunucu ile bağlantı kurulamadı.'
								: error.message,
					},
					showLoading: false,
				});
			});
	};

	React.useEffect(() => {
		if (location && location.search !== '') {
			const locationSplit = location.search.split('?code=');
			const code = locationSplit[locationSplit.length - 1];

			setState({ ...state, showLoading: true });
			fetch(`${constants.API_BASE_URL}/public/user/approve/mailStatus`, {
				method: 'POST',
				headers: constants.API_HEADERS_PUBLIC,
				body: code,
			})
				.then((response) => response.json())
				.then((response) => {
					if (response) {
						let respType = 'success';
						if (response.description) {
							respType = 'error';
						}

						// Loader Hide
						setState({
							...state,
							showLoading: false,
							toast: {
								type: respType,
								message: response.description
									? response.description
									: 'Mail adresiniz onaylanmıştır.',
							},
						});
						setInterval(() => {
							window.location.href = '#/giris';
						}, 1000);
					} else {
						setState({
							...state,
							showLoading: false,
						});
					}
				})
				.catch((error) => {
					setState({
						...state,
						toast: {
							type: 'error',
							message:
								error.message === 'Failed to fetch'
									? 'Sunucu ile bağlantı kurulamadı.'
									: error.message,
						},
						showLoading: false,
					});
				});
		}
	}, [location]);

	const GetHomeBanner = () => {
		fetch(`${constants.API_BASE_URL}/public/getLoginPageImage`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			// eslint-disable-next-line consistent-return
			.then((response) => {
				if (response?.data?.text) {
					setHomeBanner(response.data.text);
				}
			})
			.catch((error) => {
				setState({
					...state,
					toast: {
						type: 'error',
						message: error.message,
					},
					showLoading: false,
				});
			});
	};

	// useEffect
	React.useEffect(() => {
		GetHomeBanner();
	}, []);

	return (
		<>
			<main>
				<div className="py-md-8 py-4">
					<div className="container">
						<div className="row align-items-center justify-content-center g-4">
							<div className="col-xl-3 col-lg-4 col-sm-8 order-lg-0 order-1">
								<form
									onSubmit={(event) =>
										handleFormSubmit(event)
									}
								>
									<h1 className="mb-4 text-blue-light">
										Hoş geldiniz.
									</h1>
									<p className="h5 lh-base fw-normal text-gray-light mb-5">
										Lütfen kullanıcı adı ve parolanızı
										kullanarak giriş yapınız.
									</p>

									<div className="mb-4">
										<label className="form-label fw-medium">
											Kullanıcı Adı
										</label>
										<input
											type="text"
											name="username"
											className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
											required
										/>
									</div>
									<div className="mb-4">
										<label className="form-label fw-medium">
											Parola
										</label>
										<input
											type="password"
											name="password"
											className="form-control border border-2 border-gray-lighter py-2 px-3 lh-lg"
											required
										/>
									</div>
									<div className="d-flex justify-content-between mb-4">
										<div className="form-check">
											<input
												id="rememberMe"
												className="form-check-input"
												type="checkbox"
												checked={state.rememberMe}
												onChange={() => {
													setState({
														...state,
														rememberMe:
															!state.rememberMe,
													});
												}}
											/>
											<label
												className="form-check-label fs-7 text-gray-light"
												htmlFor="rememberMe"
												style={{
													cursor: 'pointer',
												}}
											>
												Beni hatırla
											</label>
										</div>
										<NavLink
											to="/sifremi-unuttum"
											className="text-decoration-none fs-7 text-blue-light"
										>
											Parolamı Unuttum
										</NavLink>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										<p className="mb-0 fs-7 me-4 text-gray-light">
											Hesabın yok mu?{' '}
											<NavLink
												to="/kayit"
												className="d-xxl-block d-inline text-blue-light text-decoration-none"
											>
												Yeni üye ol
											</NavLink>
										</p>
										<button
											type="submit"
											className="btn btn-orange text-white rounded-pill px-4 py-2 flex-shrink-0"
										>
											Giriş Yap{' '}
											<i className="fas fa-arrow-right ms-2" />
										</button>
									</div>
								</form>
							</div>
							<div className="col-xl-6 col-lg-5 offset-lg-1 offset-0 text-center order-lg-1 order-0">
								<img
									src={require('../../assets/images/logo-jarvis.png')}
									alt=""
									className="mb-5 col-xl-3 col-lg-5 col-sm-3 col-4"
								/>

								{homeBanner ? (
									<img
										src={`https://${homeBanner}`}
										alt=""
										className="w-100 d-md-block d-lg-block"
									/>
								) : (
									<div style={{ height: '385px' }} />
								)}
							</div>
						</div>
					</div>
				</div>

				<Toast
					showToast={state.toast}
					onClose={() =>
						setState({
							...state,
							toast: false,
						})
					}
					type={state.toast?.type}
					message={state.toast?.message}
				/>
				<Loading showLoading={state.showLoading} />
			</main>

			<FooterGeneric />
		</>
	);
};

export default Login;
